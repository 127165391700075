.page-preview-thumbnail {
  width: 74px;
  height: 54px;
  background-color: #EEE;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  margin-top: 7px;
}
.page-preview-thumbnail .zfam-zf-logo,
.page-preview-thumbnail .zfam-name-loading {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.5em;
  margin-top: -0.5em;
  font-size: 1.5em;
  color: #AAA;
}
.page-preview-thumbnail .thumbnail-missing-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  font-size: 0.75em;
  text-align: center;
  line-height: 1em;
  margin-top: 1em;
  color: #AAA;
}
.page-preview-thumbnail .thumbnail-loading-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  font-size: 0.75em;
  text-align: center;
}
.page-preview-thumbnail img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
}
