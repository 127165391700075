.circular-spinner {
  display: inline-block;
  width: 50%;
  height: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateX(-50%) translateX(-50%);
  -o-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}
.circular-spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid #4282D5;
  border-radius: 50%;
  animation: circular-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #4282D5 transparent transparent transparent;
}
.circular-spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.circular-spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.circular-spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes circular-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
