.nav-opener-container {
  position: fixed;
  background-color: white;
  right: 0;
  top: 0;
  left: 0;
  z-index: 200;
  height: 43px;
  cursor: pointer;
  margin: 0;
  padding: 0;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}
.nav-opener-container.nav-is-open .bm-menu {
  -webkit-box-shadow: -5px 0px 8px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -5px 0px 8px 0px rgba(0, 0, 0, 0.15);
  box-shadow: -5px 0px 8px 0px rgba(0, 0, 0, 0.15);
}
.nav-opener-container .nav-menu-title-wrapper {
  text-align: center;
  padding: 13px 40px 12px 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 8px;
  line-height: 20px;
}
.nav-opener-container .nav-menu-title {
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #585858;
}
.nav-opener-container .nav-opener-header-text {
  position: absolute;
  left: 35px;
  top: 0px;
  height: 41px;
  line-height: 41px;
  vertical-align: middle;
  box-sizing: border-box;
  padding-left: 8px;
  color: #585858;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
}
.nav-opener-container .nav-opener-icon {
  position: absolute;
  left: 20px;
  top: 15px;
  width: 15px;
}
.nav-opener-container .nav-opener-icon span {
  border-radius: 2px;
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #585858;
  opacity: 1;
  left: 0;
}
.nav-opener-container .bm-menu {
  background-color: #FFF;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.7);
}
.nav-opener-container .bm-item-list {
  padding: 0 0 16px 0;
}
.nav-opener-container .new-header-mobile-navigation-menu-list > .menu-item {
  font-size: 16px;
  line-height: 24px;
  display: block;
  padding: 0;
  margin: 0;
}
.nav-opener-container .new-header-mobile-navigation-menu-list > .menu-item.in-page-search-start-menu-item {
  text-align: right;
  padding-right: 10px;
  padding-bottom: 10px;
}
.nav-opener-container .new-header-mobile-navigation-menu-list > .menu-item.in-page-search-start-menu-item .zfe-icon-search {
  display: inline-block;
}
.nav-opener-container .new-header-mobile-navigation-menu-list > .menu-item.selected {
  background-color: #DDD;
}
.nav-opener-container .new-header-mobile-navigation-menu-list > .menu-item > a {
  padding: 8px 10px 10px 20px;
  color: #333;
  text-overflow: clip;
  white-space: normal;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.5);
  display: block;
  margin: 0;
  overflow: hidden;
  text-decoration: none;
}
.nav-opener-container .new-header-mobile-navigation-menu-list > .menu-item > a:active {
  background-color: #DDD;
}
.nav-opener-container .new-header-mobile-navigation-menu-list > .menu-item.is-subsection {
  padding-left: 22px;
}
.nav-opener-container .new-header-mobile-navigation-menu-list > .menu-item.is-subsection > a {
  color: #555;
}
.nav-opener-container .mobile-navigation-menu-list > .menu-item {
  font-size: 16px;
  line-height: 24px;
  display: block;
  padding: 0;
  margin: 0;
}
.nav-opener-container .mobile-navigation-menu-list > .menu-item.in-page-search-start-menu-item {
  text-align: right;
  padding-right: 10px;
  padding-bottom: 10px;
}
.nav-opener-container .mobile-navigation-menu-list > .menu-item.in-page-search-start-menu-item .zfe-icon-search {
  display: inline-block;
}
.nav-opener-container .mobile-navigation-menu-list > .menu-item.selected {
  background-color: #DDD;
}
.nav-opener-container .mobile-navigation-menu-list > .menu-item > a {
  padding: 8px 10px 10px 20px;
  color: #333;
  text-overflow: clip;
  white-space: normal;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.5);
  display: block;
  margin: 0;
  overflow: hidden;
  text-decoration: none;
}
.nav-opener-container .mobile-navigation-menu-list > .menu-item > a:active {
  background-color: #DDD;
}
.nav-opener-container .mobile-navigation-menu-list > .menu-item.is-subsection {
  padding-left: 22px;
}
.nav-opener-container .mobile-navigation-menu-list > .menu-item.is-subsection > a {
  color: #555;
}
.nav-opener-container .nav-opener-icon span:nth-child(1) {
  top: 0px;
}
.nav-opener-container .nav-opener-icon span:nth-child(2) {
  display: none;
  top: 5px;
}
.nav-opener-container .nav-opener-icon span:nth-child(2),
.nav-opener-container .nav-opener-icon span:nth-child(3) {
  top: 5px;
}
.nav-opener-container .nav-opener-icon span:nth-child(4) {
  top: 10px;
}
.nav-opener-container .nav-opener-icon.open {
  opacity: 0;
}
