.zf-radio-button-simple-label {
  position: relative;
  cursor: pointer;
}
.zf-radio-button-simple-label.disabled {
  opacity: 0.5;
}
.zf-radio-button-simple-label input[type=radio].zf-radio-button-simple {
  position: absolute;
  width: 0;
  height: 0;
  visibility: hidden;
}
.zf-radio-button-simple-label .zf-app-modern-icon {
  margin-right: 3px;
}
.zf-radio-button-simple-label .zfam-circle-o {
  color: #DDD;
}
.zf-radio-button-simple-label .zfam-dot-circle-o {
  color: #60a5fc;
}
