button.btn-basic,
a.btn-basic {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 4px 12px 4px 12px;
}
button.btn-basic.fullwidth,
a.btn-basic.fullwidth {
  width: 100%;
  margin: auto;
  display: block;
  padding: 8px;
}
button.btn-basic.small,
a.btn-basic.small {
  padding: 4px 6px 4px 6px;
}
button.btn-basic.btn-basic-primary,
a.btn-basic.btn-basic-primary {
  box-sizing: border-box;
  color: white;
  background-color: #4282D5;
  border: 1px solid #4282D5;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  letter-spacing: 0.25px;
  font-size: 0.85em;
  line-height: 2em;
  font-weight: bold;
  transition: color 150ms, background-color 150ms, border-color 150ms;
}
button.btn-basic.btn-basic-primary:hover,
a.btn-basic.btn-basic-primary:hover,
button.btn-basic.btn-basic-primary.hover,
a.btn-basic.btn-basic-primary.hover {
  text-decoration: none;
  background-color: #5CA3FF;
  border-color: #4282D5;
  color: white;
}
button.btn-basic.btn-basic-primary:active:focus,
a.btn-basic.btn-basic-primary:active:focus,
button.btn-basic.btn-basic-primary:active:hover,
a.btn-basic.btn-basic-primary:active:hover,
button.btn-basic.btn-basic-primary:active,
a.btn-basic.btn-basic-primary:active,
button.btn-basic.btn-basic-primary.active,
a.btn-basic.btn-basic-primary.active {
  background-color: #2F6EC0;
  border-color: #4282D5;
  color: white;
}
button.btn-basic.btn-basic-primary:focus,
a.btn-basic.btn-basic-primary:focus {
  border-color: #4282D5;
}
button.btn-basic.btn-basic-primary.disabled,
a.btn-basic.btn-basic-primary.disabled,
button.btn-basic.btn-basic-primary[disabled],
a.btn-basic.btn-basic-primary[disabled] {
  background-color: #4282D5;
  cursor: default;
  opacity: 0.4;
  outline: none;
}
button.btn-basic.btn-basic-primary.disabled:active,
a.btn-basic.btn-basic-primary.disabled:active,
button.btn-basic.btn-basic-primary[disabled]:active,
a.btn-basic.btn-basic-primary[disabled]:active,
button.btn-basic.btn-basic-primary.disabled:hover,
a.btn-basic.btn-basic-primary.disabled:hover,
button.btn-basic.btn-basic-primary[disabled]:hover,
a.btn-basic.btn-basic-primary[disabled]:hover,
button.btn-basic.btn-basic-primary.disabled:active:hover,
a.btn-basic.btn-basic-primary.disabled:active:hover,
button.btn-basic.btn-basic-primary[disabled]:active:hover,
a.btn-basic.btn-basic-primary[disabled]:active:hover {
  background-color: #4282D5;
  color: white;
}
button.btn-basic.btn-basic-primary-deemphasized,
a.btn-basic.btn-basic-primary-deemphasized {
  box-sizing: border-box;
  color: white;
  background-color: #627297;
  border: 1px solid #627297;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  letter-spacing: 0.25px;
  font-size: 0.85em;
  line-height: 2em;
  font-weight: bold;
  transition: color 150ms, background-color 150ms, border-color 150ms;
}
button.btn-basic.btn-basic-primary-deemphasized:hover,
a.btn-basic.btn-basic-primary-deemphasized:hover,
button.btn-basic.btn-basic-primary-deemphasized.hover,
a.btn-basic.btn-basic-primary-deemphasized.hover {
  text-decoration: none;
  background-color: #707fa2;
  border-color: #627297;
  color: white;
}
button.btn-basic.btn-basic-primary-deemphasized:active:focus,
a.btn-basic.btn-basic-primary-deemphasized:active:focus,
button.btn-basic.btn-basic-primary-deemphasized:active:hover,
a.btn-basic.btn-basic-primary-deemphasized:active:hover,
button.btn-basic.btn-basic-primary-deemphasized:active,
a.btn-basic.btn-basic-primary-deemphasized:active,
button.btn-basic.btn-basic-primary-deemphasized.active,
a.btn-basic.btn-basic-primary-deemphasized.active {
  background-color: #586688;
  border-color: #627297;
  color: white;
}
button.btn-basic.btn-basic-primary-deemphasized:focus,
a.btn-basic.btn-basic-primary-deemphasized:focus {
  border-color: #627297;
}
button.btn-basic.btn-basic-primary-deemphasized.disabled,
a.btn-basic.btn-basic-primary-deemphasized.disabled,
button.btn-basic.btn-basic-primary-deemphasized[disabled],
a.btn-basic.btn-basic-primary-deemphasized[disabled] {
  background-color: #627297;
  cursor: default;
  opacity: 0.4;
  outline: none;
}
button.btn-basic.btn-basic-primary-deemphasized.disabled:active,
a.btn-basic.btn-basic-primary-deemphasized.disabled:active,
button.btn-basic.btn-basic-primary-deemphasized[disabled]:active,
a.btn-basic.btn-basic-primary-deemphasized[disabled]:active,
button.btn-basic.btn-basic-primary-deemphasized.disabled:hover,
a.btn-basic.btn-basic-primary-deemphasized.disabled:hover,
button.btn-basic.btn-basic-primary-deemphasized[disabled]:hover,
a.btn-basic.btn-basic-primary-deemphasized[disabled]:hover,
button.btn-basic.btn-basic-primary-deemphasized.disabled:active:hover,
a.btn-basic.btn-basic-primary-deemphasized.disabled:active:hover,
button.btn-basic.btn-basic-primary-deemphasized[disabled]:active:hover,
a.btn-basic.btn-basic-primary-deemphasized[disabled]:active:hover {
  background-color: #627297;
  color: white;
}
button.btn-basic.btn-basic-dark,
a.btn-basic.btn-basic-dark {
  box-sizing: border-box;
  color: white;
  background-color: #3e4658;
  border: 1px solid #161d25;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  letter-spacing: 0.25px;
  font-size: 0.85em;
  line-height: 2em;
  font-weight: bold;
  transition: color 150ms, background-color 150ms, border-color 150ms;
}
button.btn-basic.btn-basic-dark:hover,
a.btn-basic.btn-basic-dark:hover,
button.btn-basic.btn-basic-dark.hover,
a.btn-basic.btn-basic-dark.hover {
  text-decoration: none;
  background-color: #485165;
  border-color: #161d25;
  color: white;
}
button.btn-basic.btn-basic-dark:active:focus,
a.btn-basic.btn-basic-dark:active:focus,
button.btn-basic.btn-basic-dark:active:hover,
a.btn-basic.btn-basic-dark:active:hover,
button.btn-basic.btn-basic-dark:active,
a.btn-basic.btn-basic-dark:active,
button.btn-basic.btn-basic-dark.active,
a.btn-basic.btn-basic-dark.active {
  background-color: #303746;
  border-color: #161d25;
  color: white;
}
button.btn-basic.btn-basic-dark:focus,
a.btn-basic.btn-basic-dark:focus {
  border-color: #161d25;
}
button.btn-basic.btn-basic-dark.disabled,
a.btn-basic.btn-basic-dark.disabled,
button.btn-basic.btn-basic-dark[disabled],
a.btn-basic.btn-basic-dark[disabled] {
  background-color: #3e4658;
  cursor: default;
  opacity: 0.4;
  outline: none;
}
button.btn-basic.btn-basic-dark.disabled:active,
a.btn-basic.btn-basic-dark.disabled:active,
button.btn-basic.btn-basic-dark[disabled]:active,
a.btn-basic.btn-basic-dark[disabled]:active,
button.btn-basic.btn-basic-dark.disabled:hover,
a.btn-basic.btn-basic-dark.disabled:hover,
button.btn-basic.btn-basic-dark[disabled]:hover,
a.btn-basic.btn-basic-dark[disabled]:hover,
button.btn-basic.btn-basic-dark.disabled:active:hover,
a.btn-basic.btn-basic-dark.disabled:active:hover,
button.btn-basic.btn-basic-dark[disabled]:active:hover,
a.btn-basic.btn-basic-dark[disabled]:active:hover {
  background-color: #3e4658;
  color: white;
}
button.btn-basic.btn-basic-danger,
a.btn-basic.btn-basic-danger {
  box-sizing: border-box;
  color: white;
  background-color: #FC5343;
  border: 1px solid #FC5343;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  letter-spacing: 0.25px;
  font-size: 0.85em;
  line-height: 2em;
  font-weight: bold;
  transition: color 150ms, background-color 150ms, border-color 150ms;
}
button.btn-basic.btn-basic-danger:hover,
a.btn-basic.btn-basic-danger:hover,
button.btn-basic.btn-basic-danger.hover,
a.btn-basic.btn-basic-danger.hover {
  text-decoration: none;
  background-color: #fc6a5c;
  border-color: #FC5343;
  color: white;
}
button.btn-basic.btn-basic-danger:active:focus,
a.btn-basic.btn-basic-danger:active:focus,
button.btn-basic.btn-basic-danger:active:hover,
a.btn-basic.btn-basic-danger:active:hover,
button.btn-basic.btn-basic-danger:active,
a.btn-basic.btn-basic-danger:active,
button.btn-basic.btn-basic-danger.active,
a.btn-basic.btn-basic-danger.active {
  background-color: #fc3c2a;
  border-color: #FC5343;
  color: white;
}
button.btn-basic.btn-basic-danger:focus,
a.btn-basic.btn-basic-danger:focus {
  border-color: #FC5343;
}
button.btn-basic.btn-basic-danger.disabled,
a.btn-basic.btn-basic-danger.disabled,
button.btn-basic.btn-basic-danger[disabled],
a.btn-basic.btn-basic-danger[disabled] {
  background-color: #FC5343;
  cursor: default;
  opacity: 0.4;
  outline: none;
}
button.btn-basic.btn-basic-danger.disabled:active,
a.btn-basic.btn-basic-danger.disabled:active,
button.btn-basic.btn-basic-danger[disabled]:active,
a.btn-basic.btn-basic-danger[disabled]:active,
button.btn-basic.btn-basic-danger.disabled:hover,
a.btn-basic.btn-basic-danger.disabled:hover,
button.btn-basic.btn-basic-danger[disabled]:hover,
a.btn-basic.btn-basic-danger[disabled]:hover,
button.btn-basic.btn-basic-danger.disabled:active:hover,
a.btn-basic.btn-basic-danger.disabled:active:hover,
button.btn-basic.btn-basic-danger[disabled]:active:hover,
a.btn-basic.btn-basic-danger[disabled]:active:hover {
  background-color: #FC5343;
  color: white;
}
button.btn-basic.btn-basic-danger-deemphasized,
a.btn-basic.btn-basic-danger-deemphasized {
  box-sizing: border-box;
  color: black;
  background-color: transparent;
  border: 1px solid #FC5343;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  letter-spacing: 0.25px;
  font-size: 0.85em;
  line-height: 2em;
  font-weight: bold;
  transition: color 150ms, background-color 150ms, border-color 150ms;
}
button.btn-basic.btn-basic-danger-deemphasized:hover,
a.btn-basic.btn-basic-danger-deemphasized:hover,
button.btn-basic.btn-basic-danger-deemphasized.hover,
a.btn-basic.btn-basic-danger-deemphasized.hover {
  text-decoration: none;
  background-color: #FFF4F3;
  border-color: #FC5343;
  color: black;
}
button.btn-basic.btn-basic-danger-deemphasized:active:focus,
a.btn-basic.btn-basic-danger-deemphasized:active:focus,
button.btn-basic.btn-basic-danger-deemphasized:active:hover,
a.btn-basic.btn-basic-danger-deemphasized:active:hover,
button.btn-basic.btn-basic-danger-deemphasized:active,
a.btn-basic.btn-basic-danger-deemphasized:active,
button.btn-basic.btn-basic-danger-deemphasized.active,
a.btn-basic.btn-basic-danger-deemphasized.active {
  background-color: #ffaea6;
  border-color: #FC5343;
  color: black;
}
button.btn-basic.btn-basic-danger-deemphasized:focus,
a.btn-basic.btn-basic-danger-deemphasized:focus {
  border-color: #FC5343;
}
button.btn-basic.btn-basic-danger-deemphasized.disabled,
a.btn-basic.btn-basic-danger-deemphasized.disabled,
button.btn-basic.btn-basic-danger-deemphasized[disabled],
a.btn-basic.btn-basic-danger-deemphasized[disabled] {
  background-color: transparent;
  cursor: default;
  opacity: 0.4;
  outline: none;
}
button.btn-basic.btn-basic-danger-deemphasized.disabled:active,
a.btn-basic.btn-basic-danger-deemphasized.disabled:active,
button.btn-basic.btn-basic-danger-deemphasized[disabled]:active,
a.btn-basic.btn-basic-danger-deemphasized[disabled]:active,
button.btn-basic.btn-basic-danger-deemphasized.disabled:hover,
a.btn-basic.btn-basic-danger-deemphasized.disabled:hover,
button.btn-basic.btn-basic-danger-deemphasized[disabled]:hover,
a.btn-basic.btn-basic-danger-deemphasized[disabled]:hover,
button.btn-basic.btn-basic-danger-deemphasized.disabled:active:hover,
a.btn-basic.btn-basic-danger-deemphasized.disabled:active:hover,
button.btn-basic.btn-basic-danger-deemphasized[disabled]:active:hover,
a.btn-basic.btn-basic-danger-deemphasized[disabled]:active:hover {
  background-color: transparent;
  color: black;
}
button.btn-basic.btn-basic-secondary,
a.btn-basic.btn-basic-secondary {
  box-sizing: border-box;
  color: #272727;
  background-color: #F4F5F7;
  border: 1px solid #E0E2E5;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  letter-spacing: 0.25px;
  font-size: 0.85em;
  line-height: 2em;
  font-weight: bold;
  transition: color 150ms, background-color 150ms, border-color 150ms;
}
button.btn-basic.btn-basic-secondary:hover,
a.btn-basic.btn-basic-secondary:hover,
button.btn-basic.btn-basic-secondary.hover,
a.btn-basic.btn-basic-secondary.hover {
  text-decoration: none;
  background-color: #F9FAFB;
  border-color: #E0E2E5;
  color: #4282D5;
}
button.btn-basic.btn-basic-secondary:active:focus,
a.btn-basic.btn-basic-secondary:active:focus,
button.btn-basic.btn-basic-secondary:active:hover,
a.btn-basic.btn-basic-secondary:active:hover,
button.btn-basic.btn-basic-secondary:active,
a.btn-basic.btn-basic-secondary:active,
button.btn-basic.btn-basic-secondary.active,
a.btn-basic.btn-basic-secondary.active {
  background-color: #E7E9ED;
  border-color: #E0E2E5;
  color: #272727;
}
button.btn-basic.btn-basic-secondary:focus,
a.btn-basic.btn-basic-secondary:focus {
  border-color: #E0E2E5;
}
button.btn-basic.btn-basic-secondary.disabled,
a.btn-basic.btn-basic-secondary.disabled,
button.btn-basic.btn-basic-secondary[disabled],
a.btn-basic.btn-basic-secondary[disabled] {
  background-color: #F4F5F7;
  cursor: default;
  opacity: 0.4;
  outline: none;
}
button.btn-basic.btn-basic-secondary.disabled:active,
a.btn-basic.btn-basic-secondary.disabled:active,
button.btn-basic.btn-basic-secondary[disabled]:active,
a.btn-basic.btn-basic-secondary[disabled]:active,
button.btn-basic.btn-basic-secondary.disabled:hover,
a.btn-basic.btn-basic-secondary.disabled:hover,
button.btn-basic.btn-basic-secondary[disabled]:hover,
a.btn-basic.btn-basic-secondary[disabled]:hover,
button.btn-basic.btn-basic-secondary.disabled:active:hover,
a.btn-basic.btn-basic-secondary.disabled:active:hover,
button.btn-basic.btn-basic-secondary[disabled]:active:hover,
a.btn-basic.btn-basic-secondary[disabled]:active:hover {
  background-color: #F4F5F7;
  color: #272727;
}
button.btn-basic.btn-basic-alternative,
a.btn-basic.btn-basic-alternative {
  box-sizing: border-box;
  color: white;
  background-color: #3A66A8;
  border: 1px solid #3A66A8;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  letter-spacing: 0.25px;
  font-size: 0.85em;
  line-height: 2em;
  font-weight: bold;
  transition: color 150ms, background-color 150ms, border-color 150ms;
}
button.btn-basic.btn-basic-alternative:hover,
a.btn-basic.btn-basic-alternative:hover,
button.btn-basic.btn-basic-alternative.hover,
a.btn-basic.btn-basic-alternative.hover {
  text-decoration: none;
  background-color: #527fc3;
  border-color: #3A66A8;
  color: white;
}
button.btn-basic.btn-basic-alternative:active:focus,
a.btn-basic.btn-basic-alternative:active:focus,
button.btn-basic.btn-basic-alternative:active:hover,
a.btn-basic.btn-basic-alternative:active:hover,
button.btn-basic.btn-basic-alternative:active,
a.btn-basic.btn-basic-alternative:active,
button.btn-basic.btn-basic-alternative.active,
a.btn-basic.btn-basic-alternative.active {
  background-color: #2d4f82;
  border-color: #3A66A8;
  color: white;
}
button.btn-basic.btn-basic-alternative:focus,
a.btn-basic.btn-basic-alternative:focus {
  border-color: #3A66A8;
}
button.btn-basic.btn-basic-alternative.disabled,
a.btn-basic.btn-basic-alternative.disabled,
button.btn-basic.btn-basic-alternative[disabled],
a.btn-basic.btn-basic-alternative[disabled] {
  background-color: #3A66A8;
  cursor: default;
  opacity: 0.4;
  outline: none;
}
button.btn-basic.btn-basic-alternative.disabled:active,
a.btn-basic.btn-basic-alternative.disabled:active,
button.btn-basic.btn-basic-alternative[disabled]:active,
a.btn-basic.btn-basic-alternative[disabled]:active,
button.btn-basic.btn-basic-alternative.disabled:hover,
a.btn-basic.btn-basic-alternative.disabled:hover,
button.btn-basic.btn-basic-alternative[disabled]:hover,
a.btn-basic.btn-basic-alternative[disabled]:hover,
button.btn-basic.btn-basic-alternative.disabled:active:hover,
a.btn-basic.btn-basic-alternative.disabled:active:hover,
button.btn-basic.btn-basic-alternative[disabled]:active:hover,
a.btn-basic.btn-basic-alternative[disabled]:active:hover {
  background-color: #3A66A8;
  color: white;
}
button.btn-basic.btn-basic-new-alt,
a.btn-basic.btn-basic-new-alt {
  box-sizing: border-box;
  color: white;
  background-color: #22CAA1;
  border: 1px solid #22CAA1;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  letter-spacing: 0.25px;
  font-size: 0.85em;
  line-height: 2em;
  font-weight: bold;
  transition: color 150ms, background-color 150ms, border-color 150ms;
}
button.btn-basic.btn-basic-new-alt:hover,
a.btn-basic.btn-basic-new-alt:hover,
button.btn-basic.btn-basic-new-alt.hover,
a.btn-basic.btn-basic-new-alt.hover {
  text-decoration: none;
  background-color: #26DDB1;
  border-color: #22CAA1;
  color: white;
}
button.btn-basic.btn-basic-new-alt:active:focus,
a.btn-basic.btn-basic-new-alt:active:focus,
button.btn-basic.btn-basic-new-alt:active:hover,
a.btn-basic.btn-basic-new-alt:active:hover,
button.btn-basic.btn-basic-new-alt:active,
a.btn-basic.btn-basic-new-alt:active,
button.btn-basic.btn-basic-new-alt.active,
a.btn-basic.btn-basic-new-alt.active {
  background-color: #13A783;
  border-color: #22CAA1;
  color: white;
}
button.btn-basic.btn-basic-new-alt:focus,
a.btn-basic.btn-basic-new-alt:focus {
  border-color: #22CAA1;
}
button.btn-basic.btn-basic-new-alt.disabled,
a.btn-basic.btn-basic-new-alt.disabled,
button.btn-basic.btn-basic-new-alt[disabled],
a.btn-basic.btn-basic-new-alt[disabled] {
  background-color: #22CAA1;
  cursor: default;
  opacity: 0.4;
  outline: none;
}
button.btn-basic.btn-basic-new-alt.disabled:active,
a.btn-basic.btn-basic-new-alt.disabled:active,
button.btn-basic.btn-basic-new-alt[disabled]:active,
a.btn-basic.btn-basic-new-alt[disabled]:active,
button.btn-basic.btn-basic-new-alt.disabled:hover,
a.btn-basic.btn-basic-new-alt.disabled:hover,
button.btn-basic.btn-basic-new-alt[disabled]:hover,
a.btn-basic.btn-basic-new-alt[disabled]:hover,
button.btn-basic.btn-basic-new-alt.disabled:active:hover,
a.btn-basic.btn-basic-new-alt.disabled:active:hover,
button.btn-basic.btn-basic-new-alt[disabled]:active:hover,
a.btn-basic.btn-basic-new-alt[disabled]:active:hover {
  background-color: #22CAA1;
  color: white;
}
button.btn-basic.btn-basic-primary-borderless,
a.btn-basic.btn-basic-primary-borderless {
  color: #4282D5;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  text-decoration-style: dotted;
}
button.btn-basic.btn-basic-danger-borderless,
a.btn-basic.btn-basic-danger-borderless {
  box-sizing: border-box;
  color: #FC5343;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  letter-spacing: 0.25px;
  font-size: 0.85em;
  line-height: 2em;
  font-weight: bold;
  transition: color 150ms, background-color 150ms, border-color 150ms;
}
button.btn-basic.btn-basic-danger-borderless:hover,
a.btn-basic.btn-basic-danger-borderless:hover,
button.btn-basic.btn-basic-danger-borderless.hover,
a.btn-basic.btn-basic-danger-borderless.hover {
  text-decoration: none;
  background-color: rgba(26, 26, 26, 0);
  border-color: transparent;
  color: #FC5343;
}
button.btn-basic.btn-basic-danger-borderless:active:focus,
a.btn-basic.btn-basic-danger-borderless:active:focus,
button.btn-basic.btn-basic-danger-borderless:active:hover,
a.btn-basic.btn-basic-danger-borderless:active:hover,
button.btn-basic.btn-basic-danger-borderless:active,
a.btn-basic.btn-basic-danger-borderless:active,
button.btn-basic.btn-basic-danger-borderless.active,
a.btn-basic.btn-basic-danger-borderless.active {
  background-color: rgba(0, 0, 0, 0);
  border-color: transparent;
  color: #FC5343;
}
button.btn-basic.btn-basic-danger-borderless:focus,
a.btn-basic.btn-basic-danger-borderless:focus {
  border-color: transparent;
}
button.btn-basic.btn-basic-danger-borderless.disabled,
a.btn-basic.btn-basic-danger-borderless.disabled,
button.btn-basic.btn-basic-danger-borderless[disabled],
a.btn-basic.btn-basic-danger-borderless[disabled] {
  background-color: transparent;
  cursor: default;
  opacity: 0.4;
  outline: none;
}
button.btn-basic.btn-basic-danger-borderless.disabled:active,
a.btn-basic.btn-basic-danger-borderless.disabled:active,
button.btn-basic.btn-basic-danger-borderless[disabled]:active,
a.btn-basic.btn-basic-danger-borderless[disabled]:active,
button.btn-basic.btn-basic-danger-borderless.disabled:hover,
a.btn-basic.btn-basic-danger-borderless.disabled:hover,
button.btn-basic.btn-basic-danger-borderless[disabled]:hover,
a.btn-basic.btn-basic-danger-borderless[disabled]:hover,
button.btn-basic.btn-basic-danger-borderless.disabled:active:hover,
a.btn-basic.btn-basic-danger-borderless.disabled:active:hover,
button.btn-basic.btn-basic-danger-borderless[disabled]:active:hover,
a.btn-basic.btn-basic-danger-borderless[disabled]:active:hover {
  background-color: transparent;
  color: #FC5343;
}
button.btn-basic.btn-basic-link,
a.btn-basic.btn-basic-link {
  border: none;
  background-color: transparent;
  width: auto;
  height: auto;
  color: #4282D5;
  padding: 0;
  margin: 0;
}
a.btn-basic {
  vertical-align: middle;
}
