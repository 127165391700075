a.basic-link {
  text-decoration: none;
  padding: 6px 14px 5px 13px;
  cursor: pointer;
  color: #4282D5;
}
a.basic-link:hover {
  text-decoration: underline;
  color: #5790da;
}
a.basic-link.dark {
  color: #EEE;
}
a.basic-link.dark:hover {
  color: white;
}
a.basic-link.disabled,
a.basic-link.disabled:hover {
  color: #888;
  cursor: not-allowed;
}
a.basic-link .zf-chevron-up-icon-box i:before,
a.basic-link .zf-chevron-down-icon-box i:before {
  font-size: 0.7em;
  margin-left: 10px;
  position: relative;
  top: -0.1em;
}
