.video-thumbnail-picker-view {
  display: none;
}
.video-thumbnail-picker-view .modal-header {
  background-color: #F1F1F1;
  padding: 4px 12px 4px 25px;
  border-radius: 6px 6px 0px 0px;
}
.video-thumbnail-picker-view .modal-header h2 {
  margin: 0px;
  font-size: 18px;
  text-align: left;
  font-family: sans-serif;
  color: #6F6F6F;
  font-weight: 500;
}
.video-thumbnail-picker-view .modal-body {
  background-color: #FFF;
  border-top: 1px solid #878787;
  padding: 20px;
}
.video-thumbnail-picker-view .modal-body .current-image-label {
  background: #EEE;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  color: #666;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 6px auto 0 auto;
  padding: 2px 0;
  text-align: center;
  text-transform: uppercase;
  width: 144px;
}
.video-thumbnail-picker-view .modal-body .choose-option-label {
  color: #6F6F6F;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  padding: 0 0 6px 2px;
}
.video-thumbnail-picker-view .modal-body .mainThumbnail {
  margin-bottom: 5px;
  text-align: center;
}
.video-thumbnail-picker-view .modal-body .mainThumbnail img,
.video-thumbnail-picker-view .modal-body .mainThumbnail .missing-thumbnail {
  display: inline-block;
  margin-bottom: 10px;
  width: 144px;
}
.video-thumbnail-picker-view .modal-body .mainThumbnail .missing-thumbnail {
  height: 115px;
  background-color: #ddd;
  padding: 28px;
  box-sizing: border-box;
  text-align: center;
  color: #666;
}
.video-thumbnail-picker-view .modal-body .thumbnail-options {
  margin-left: 20px;
}
.video-thumbnail-picker-view .modal-body .thumbnail-options .thumbnailChoice {
  width: 29%;
  margin: 0px 6px 6px 0px;
  border: 4px solid #FFF;
  float: left;
  position: relative;
  cursor: pointer;
}
.video-thumbnail-picker-view .modal-body .thumbnail-options .thumbnailChoice:hover {
  border: 4px solid #4ECEF6;
}
