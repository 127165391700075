.visually-hidden {
  position: absolute;
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
}
.expanded-asset {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2000;
}
.expanded-asset .video-js {
  width: 100% !important;
  height: 100% !important;
}
.expanded-asset .background-layer {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.95;
  background-color: #111;
  z-index: 100;
}
.expanded-asset .pdf-iframe,
.expanded-asset .ppt-iframe,
.expanded-asset .html-package-iframe {
  width: 100%;
  height: 100%;
  background-color: #FFF;
}
.expanded-asset .asset-display-box {
  position: absolute;
  top: 80px;
  right: 80px;
  left: 80px;
  bottom: 80px;
  z-index: 200;
}
.expanded-asset .close-modal-button {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  height: 20px;
  width: 20px;
  z-index: 200;
}
.expanded-asset .asset-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.expanded-asset .asset-wrapper.has-video {
  margin: 20px 50px;
}
.expanded-asset .asset-wrapper img.expanded-asset-image {
  position: absolute;
  display: block;
}
.expanded-asset .read-only-info-panel {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #F1F1F1;
  box-sizing: border-box;
  display: none;
  font-family: 'Open Sans', sans-serif;
  overflow: auto;
  width: 325px;
  z-index: 200;
}
.expanded-asset .read-only-asset-name {
  background-color: #FFF;
  font-size: 14px;
  font-weight: bold;
  padding: 15px 20px;
  text-align: left;
}
.expanded-asset .read-only-details {
  padding: 25px 20px;
}
.expanded-asset .read-only-details .asset-date-added,
.expanded-asset .read-only-details .asset-date-modified,
.expanded-asset .read-only-details .asset-uploaded-by,
.expanded-asset .read-only-details .asset-from-prompt {
  font-size: 12px;
  text-align: right;
}
.expanded-asset .read-only-details .asset-date-added .info-title,
.expanded-asset .read-only-details .asset-date-modified .info-title,
.expanded-asset .read-only-details .asset-uploaded-by .info-title,
.expanded-asset .read-only-details .asset-from-prompt .info-title {
  font-weight: bold;
  display: inline-block;
  float: left;
}
.expanded-asset .read-only-title,
.expanded-asset .read-only-description {
  font-size: 12px;
  line-height: 18px;
}
.expanded-asset .read-only-title .info-title,
.expanded-asset .read-only-description .info-title {
  font-weight: bold;
  display: block;
  padding-bottom: 3px;
}
.expanded-asset .read-only-description {
  margin-bottom: 20px;
}
.expanded-asset.has-info-panel .read-only-info-panel {
  display: block;
}
.expanded-asset.has-info-panel .close-modal-button {
  right: 340px;
}
.expanded-asset.has-info-panel .asset-display-box {
  right: 395px;
}
