.fullscreen-filter-background-cover {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 50000;
  background-color: rgba(0, 0, 0, 0.6);
}
.grid-section-filters .grid-section-filters-inner-wrapper .grid-section-filters-inner {
  position: relative;
  box-sizing: border-box;
}
.grid-section-filters .grid-section-filters-inner-wrapper .grid-section-filters-inner.active {
  z-index: 50000;
}
.grid-section-filters .grid-section-filters-inner-wrapper .grid-section-filters-inner .grid-section-filters-toggle {
  display: flex;
  margin-bottom: 10px;
  padding: 15px 30px;
  border-radius: 3px;
}
.grid-section-filters .grid-section-filters-inner-wrapper .grid-section-filters-inner .grid-section-filters-toggle .grid-section-filters-toggle-label {
  margin-right: auto;
}
.grid-section-filters .grid-section-filters-inner-wrapper .grid-section-filters-inner .grid-section-filters-toggle .grid-section-filters-toggle-trigger {
  cursor: pointer;
  margin-left: auto;
}
.grid-section-filters .grid-section-filters-inner-wrapper .grid-section-filters-inner .grid-section-filters-categories {
  position: absolute;
  width: 100%;
}
.grid-section-filters .grid-section-filters-inner-wrapper .grid-section-filters-inner .grid-section-filters-categories .grid-section-filters-category {
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 15px 30px;
}
.grid-section-filters .grid-section-filters-inner-wrapper .grid-section-filters-inner .grid-section-filters-categories .grid-section-filters-category .grid-section-filters-category-tags label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
}
.grid-section-filters .grid-section-filters-inner-wrapper .grid-section-filters-inner .grid-section-filters-categories .grid-section-filters-category .grid-section-filters-category-tags input {
  cursor: pointer;
}
.grid-section-filters .grid-section-filters-inner-wrapper .grid-section-filters-inner .grid-section-filters-categories .grid-section-filters-actions {
  display: flex;
  padding: 15px;
}
.grid-section-filters .grid-section-filters-inner-wrapper .grid-section-filters-inner .grid-section-filters-categories .grid-section-filters-actions .grid-section-filters-actions-inner {
  margin-left: auto;
}
.grid-section-filters .zfe-icon {
  line-height: inherit !important;
}
.grid-section-filters .zfe-icon:before {
  vertical-align: -10% !important;
}
