.scroll-indicator-container {
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translate(0, -50%);
  /* Centers the element */
  z-index: 31;
  /* Displays on top of the navigation bar on very small screens */
}
.scroll-indicator-container .scroll-indicator {
  margin: auto;
  padding: 10px 0px;
  /* 
      We have to do this weird hack to prevent opacity from extending to the children of scroll-indicator.
      This is necessary because we cannot use .less syntax for our theme variable CSS. 
    */
}
.scroll-indicator-container .scroll-indicator .scroll-indicator-background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 5%;
  border-radius: 6px;
}
.scroll-indicator-container .scroll-indicator-button-wrapper {
  position: relative;
  display: flex;
  width: 30px;
  height: 24px;
  cursor: pointer;
}
.scroll-indicator-container .scroll-indicator-button-wrapper:hover .scroll-indicator-button {
  width: 4px;
  height: 4px;
  /* 
          We have to do this weird hack to prevent opacity from extending to the children of scroll-indicator-button.
          This is necessary because we cannot use .less syntax for our theme variable CSS. 
        */
}
.scroll-indicator-container .scroll-indicator-button-wrapper:hover .scroll-indicator-button .scroll-indicator-button-background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 100%;
  border-radius: 50%;
}
.scroll-indicator-container .scroll-indicator-button-wrapper:hover .scroll-indicator-button.active {
  width: 6px;
  height: 6px;
  /* 
            We have to do this weird hack to prevent opacity from extending to the children of scroll-indicator-button.
            This is necessary because we cannot use .less syntax for our theme variable CSS. 
          */
}
.scroll-indicator-container .scroll-indicator-button-wrapper:hover .scroll-indicator-button.active .scroll-indicator-button-background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 100%;
  border-radius: 50%;
}
.scroll-indicator-container .scroll-indicator-button-wrapper:hover .scroll-indicator-button-tooltip {
  display: block;
}
.scroll-indicator-container .scroll-indicator-button-wrapper .scroll-indicator-button-tooltip {
  display: none;
  position: absolute;
  right: 26px;
  top: -6px;
  white-space: nowrap;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  border-radius: 3px;
  padding: 0px 12px;
}
.scroll-indicator-container .scroll-indicator-button-wrapper .scroll-indicator-button-tooltip .tooltip-arrow {
  position: absolute;
  right: -5px;
  top: 11px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left-width: 5px;
  border-left-style: solid;
}
.scroll-indicator-container .scroll-indicator-button-wrapper .scroll-indicator-button {
  position: relative;
  width: 4px;
  height: 4px;
  margin: auto;
  /* 
        We have to do this weird hack to prevent opacity from extending to the children of scroll-indicator-button.
        This is necessary because we cannot use .less syntax for our theme variable CSS. 
      */
}
.scroll-indicator-container .scroll-indicator-button-wrapper .scroll-indicator-button .scroll-indicator-button-background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 50%;
  border-radius: 50%;
}
.scroll-indicator-container .scroll-indicator-button-wrapper .scroll-indicator-button.active {
  width: 6px;
  height: 6px;
  /* 
          We have to do this weird hack to prevent opacity from extending to the children of scroll-indicator-button.
          This is necessary because we cannot use .less syntax for our theme variable CSS. 
        */
}
.scroll-indicator-container .scroll-indicator-button-wrapper .scroll-indicator-button.active .scroll-indicator-button-background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 100%;
  border-radius: 50%;
}
