.file-items.list {
  position: relative;
}
.file-items.list .media-library-asset {
  position: relative;
}
.file-items.list .media-library-asset:hover .view-or-select-overlay {
  display: inline-block;
}
.file-items.list .media-library-asset.selected .view-or-select-overlay .deselect-option {
  display: inline;
}
.file-items.list .media-library-asset.selected .view-or-select-overlay .select-option {
  display: none;
}
.file-items.list .media-library-asset .view-or-select-overlay {
  display: none;
  background-color: #666;
  cursor: pointer;
  opacity: 0.96;
  position: absolute;
  right: 0;
  left: 55%;
  top: 0;
  bottom: 0;
}
.file-items.list .media-library-asset .view-or-select-overlay .deselect-option {
  display: none;
}
.file-items.list .media-library-asset .view-or-select-overlay .view-asset {
  border-left: 3px solid #EEE;
}
.file-items.list .media-library-asset .view-or-select-overlay .view-asset,
.file-items.list .media-library-asset .view-or-select-overlay .select-asset {
  margin: 0;
  box-sizing: border-box;
  height: 100%;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  line-height: 40px;
  vertical-align: middle;
  color: #FFF;
  text-align: center;
  width: 50%;
  float: left;
}
.file-items.list .media-library-asset .view-or-select-overlay .view-asset:hover,
.file-items.list .media-library-asset .view-or-select-overlay .select-asset:hover {
  background-color: #333;
}
.file-items.list .media-library-asset .thumbnail-wrapper {
  position: relative;
}
.file-items.list .media-library-asset .playable-asset-icon {
  position: absolute;
  top: 35%;
  left: 35%;
  height: 24px;
  width: 24px;
  margin-left: -24px/2;
  margin-top: -24px/2;
  opacity: 0.7;
}
.file-items.list .media-library-asset .pdf-icon-wrapper {
  box-sizing: border-box;
}
.file-items.list .media-library.view_only .media-library-asset .view-asset {
  height: 100%;
  line-height: 100px;
}
.file-items.list .media-library.view_only .media-library-asset .select-asset {
  display: none;
}
