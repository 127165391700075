.create-link-modal .title-extra {
  color: #888;
  font-weight: 400;
}
.create-link-modal .title-extra .zf-icon-box {
  color: #aaa;
  margin-left: 10px;
}
.create-link-modal .subtitle-container {
  color: #111 !important;
  margin-top: 10px !important;
  font-size: 12px;
}
