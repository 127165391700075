.react-tagsinput {
  background-color: #FFF;
  border: 1px solid #ddd;
  border-radius: 2px;
  overflow: hidden;
  padding: 5px;
  cursor: text;
}
.react-tagsinput.dark {
  color: white;
  background-color: #1D2027;
  border-radius: 2px;
  border: 1px solid #111318;
  font-size: 12px;
}
.react-tagsinput.dark .react-tagsinput-tag {
  color: white;
  background-color: #3e4658;
  border: 1px solid #161d25;
}
.react-tagsinput.dark .react-tagsinput-tag a {
  color: white;
}
.react-tagsinput.dark.react-tagsinput--focused {
  border: 1px solid #4282D5;
}
.react-tagsinput.disabled {
  cursor: not-allowed;
}
.react-tagsinput--focused {
  border: 1px solid #60a5fc;
}
.react-tagsinput-tag {
  background-color: #E5F1FF;
  border-radius: 2px;
  border: none;
  color: #111;
  display: inline-block;
  font-size: 12px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 2px 5px;
}
.react-tagsinput-tag a::before {
  content: " ×";
}
.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}
.react-tagsinput-input input {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: #111;
  font-size: 12px !important;
  outline: none;
  padding: 5px;
  margin: 0 !important;
}
.react-tagsinput-input input:focus {
  box-shadow: none !important;
}
.react-tagsinput-input.disabled {
  cursor: not-allowed;
}
