.folder-chooser-modal .modal-body {
  border-top: 1px solid #AAA;
  height: 285px;
  overflow: auto;
}
.folder-chooser-modal .folder-choice {
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  color: #444;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  margin-bottom: 2px;
  padding: 7px 11px;
}
.folder-chooser-modal .folder-choice[data-folder-id="null"] .expand-icon-wrapper {
  display: none;
}
.folder-chooser-modal .folder-choice .expand-icon-wrapper {
  visibility: hidden;
}
.folder-chooser-modal .folder-choice.expandable .expand-icon-wrapper {
  visibility: visible;
}
.folder-chooser-modal .folder-choice.expanded .fa-plus-square-o {
  display: none;
}
.folder-chooser-modal .folder-choice.expanded .fa-minus-square-o {
  display: inline;
}
.folder-chooser-modal .folder-choice .fa-minus-square-o {
  display: none;
}
.folder-chooser-modal .folder-choice .fa-plus-square-o,
.folder-chooser-modal .folder-choice .fa-minus-square-o {
  color: #999;
  font-size: 14px;
  margin-right: 6px;
}
.folder-chooser-modal .folder-choice .fa-plus-square-o:hover,
.folder-chooser-modal .folder-choice .fa-minus-square-o:hover {
  color: #111;
}
.folder-chooser-modal .folder-choice .fa-folder {
  color: #AAA;
  font-size: 14px;
  margin-right: 4px;
}
.folder-chooser-modal .folder-choice:hover {
  background-color: #E3E9EC;
}
.folder-chooser-modal .folder-choice.selected {
  background-color: #93D6F4;
  color: #09587B;
}
.folder-chooser-modal .folder-choice.selected .fa-plus-square-o,
.folder-chooser-modal .folder-choice.selected .fa-minus-square-o {
  color: #FFF;
}
.folder-chooser-modal .folder-choice.selected .fa-plus-square-o:hover,
.folder-chooser-modal .folder-choice.selected .fa-minus-square-o:hover {
  color: #111;
}
.folder-chooser-modal .folder-choice.selected .fa-folder {
  color: #5DA6C7;
}
.folder-chooser-modal .folder-choice.selected .current-indicator {
  color: #FFF;
}
.folder-chooser-modal .folder-choice[indent="1"] {
  margin-left: 11px;
}
.folder-chooser-modal .folder-choice[indent="2"] {
  margin-left: 22px;
}
.folder-chooser-modal .folder-choice[indent="3"] {
  margin-left: 33px;
}
.folder-chooser-modal .folder-choice[indent="4"] {
  margin-left: 44px;
}
.folder-chooser-modal .folder-choice[indent="5"] {
  margin-left: 55px;
}
.folder-chooser-modal .folder-choice[indent="6"] {
  margin-left: 66px;
}
.folder-chooser-modal .folder-choice[indent="7"] {
  margin-left: 77px;
}
.folder-chooser-modal .current-indicator {
  color: #40B7DB;
  font-size: 12px;
  margin-left: 8px;
}
