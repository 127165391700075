textarea.basic-textarea {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #111;
  background-color: #FFF;
  border: 1px solid #ddd;
  box-shadow: none;
  display: inline-block;
  padding: 6px 10px 8px 10px;
  margin-bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
}
textarea.basic-textarea[disabled] {
  opacity: 0.9;
}
textarea.basic-textarea:focus {
  outline: none;
  border: 1px solid #60a5fc;
}
