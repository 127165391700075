.skip-link-for-element {
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}
.skip-link-for-element:focus {
  display: flex;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #111111;
  color: #fff;
  font-size: 30px;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  width: 100%;
  z-index: 2;
}
