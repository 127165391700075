.tile-form {
  position: relative;
}
.tile-form .form-wrapper {
  margin-top: 0.5em;
  line-height: 0;
}
.tile-form .form-field-label {
  line-height: 15px;
  font-size: 15px;
  display: block;
  margin: auto;
  text-align: left;
  font-family: 'Lato', sans-serif;
  color: #2E2E2E;
  margin-bottom: 12px;
  margin-top: 20px;
}
.tile-form .form-field-label:first-child {
  margin-top: 0px;
}
.tile-form .form-field-container {
  text-align: left;
  margin: auto;
}
.tile-form .form-field-option-label {
  line-height: 15px;
  font-size: 15px;
  color: #2E2E2E;
}
.tile-form .form-field-checkbox-container {
  font-size: 15px;
}
.tile-form .form-field-radio-container {
  font-size: 15px;
}
.tile-form .form-field-required-indicator-text {
  font-size: 10px;
  color: #D0021B;
}
.tile-form .form-field-required-indicator {
  font-size: 15px;
  line-height: 15px;
  color: #D0021B;
}
.tile-form input[type="text"],
.tile-form textarea {
  background-color: #FFF;
  box-sizing: border-box;
  border: 1px solid #AAA;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.6em;
  margin: 0 0 0.3em 0;
  max-width: 100%;
  padding: 0.4em 0.7em 0.4em 0.7em;
}
.tile-form input[type="text"].error,
.tile-form textarea.error {
  border: 1px solid #D0021B;
}
.tile-form textarea {
  height: 100px;
  resize: none;
}
.tile-form .form-anti-click-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background: transparent;
}
.tile-wrapper.type-form[data-sizex="1"] input[type="text"],
.tile-wrapper.type-form[data-sizex="1"] textarea,
.tile-wrapper.type-form[data-sizex="1"] .form-field-label,
.tile-wrapper.type-form[data-sizex="1"] .form-field-container {
  width: 100%;
}
.tile-wrapper.type-form[data-sizex="2"] input[type="text"],
.tile-wrapper.type-form[data-sizex="2"] textarea,
.tile-wrapper.type-form[data-sizex="2"] .form-field-label,
.tile-wrapper.type-form[data-sizex="2"] .form-field-container {
  width: 85%;
}
.tile-wrapper.type-form[data-sizex="3"] input[type="text"],
.tile-wrapper.type-form[data-sizex="3"] textarea,
.tile-wrapper.type-form[data-sizex="3"] .form-field-label,
.tile-wrapper.type-form[data-sizex="3"] .form-field-container {
  width: 75%;
}
.tile-wrapper.type-form[data-sizex="4"] input[type="text"],
.tile-wrapper.type-form[data-sizex="4"] textarea,
.tile-wrapper.type-form[data-sizex="4"] .form-field-label,
.tile-wrapper.type-form[data-sizex="4"] .form-field-container {
  width: 65%;
}
.tile-wrapper.type-form[data-sizex="5"] input[type="text"],
.tile-wrapper.type-form[data-sizex="5"] textarea,
.tile-wrapper.type-form[data-sizex="5"] .form-field-label,
.tile-wrapper.type-form[data-sizex="5"] .form-field-container {
  width: 55%;
}
