.navigation-links {
  position: relative;
  overflow: visible;
  z-index: 30;
}
.navigation-links.prevent-dropdowns .nav-link-super-wrapper:hover .nav-link-subsection-dropdown {
  display: none;
}
.nav-link-wrapper {
  display: inline-block;
  text-decoration: none;
}
.nav-link-super-wrapper {
  position: relative;
  display: inline-block;
  overflow: visible;
}
.nav-link-super-wrapper a {
  cursor: pointer;
}
.nav-link-super-wrapper .nav-link-subsection-dropdown-wrapper {
  display: none;
  position: absolute;
  top: 99%;
  left: 50%;
  z-index: 500;
  box-sizing: border-box;
}
.nav-link-super-wrapper:hover .nav-link-subsection-dropdown-wrapper {
  display: block;
}
.nav-link-super-wrapper .nav-link-subsection-dropdown-spacer {
  display: block;
  width: 100%;
}
.submenu-links-container {
  position: relative;
  box-sizing: border-box;
}
.submenu-nav-link-wrapper {
  display: inline-block;
}
.submenu-nav-link-wrapper a {
  cursor: pointer;
}
.submenu-nav-link {
  display: inline-block;
}
