.media-library-action-bar {
  background-color: #FFF;
  border-bottom: 1px solid #DDD;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.08);
}
.media-library-action-bar .folder-action-buttons {
  float: right;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  padding: 18px 40px 18px 0;
}
.media-library-action-bar .folder-action-buttons .folder-action-button {
  float: right;
  margin-left: 26px;
  cursor: pointer;
}
.media-library-action-bar .folder-action-buttons .add-media-button,
.media-library-action-bar .folder-action-buttons .add-folder-button {
  color: #02B6E5;
  padding: 7px 0;
}
.media-library-action-bar .folder-action-buttons .add-media-button .fa,
.media-library-action-bar .folder-action-buttons .add-folder-button .fa {
  font-size: 14px;
  padding-top: 3px;
  padding-right: 7px;
  vertical-align: top;
}
.media-library-action-bar .folder-action-buttons .add-media-button:hover,
.media-library-action-bar .folder-action-buttons .add-folder-button:hover {
  color: #00648B;
}
.media-library-action-bar .folder-action-buttons .organize-lib-button {
  display: none;
}
.media-library-action-bar .search-box-wrapper {
  position: relative;
  padding: 17px 0px 0px 30px;
  box-sizing: border-box;
  width: 550px;
}
@media (max-width: 1120px) {
  .media-library-action-bar .search-box-wrapper {
    width: 450px;
  }
}
@media (max-width: 1020px) {
  .media-library-action-bar .search-box-wrapper {
    width: 380px;
  }
}
.media-library-action-bar .search-box-wrapper input[name="search"] {
  background-color: #FFF;
  border: 1px solid #CCC;
  border-right: 0;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #111;
  font-size: 15px;
  height: 36px;
  padding: 8px 20px 9px 15px;
  width: 357px;
}
.media-library-action-bar .search-box-wrapper input[name="search"]:focus {
  border: 1px solid #60a5fc;
}
@media (max-width: 1120px) {
  .media-library-action-bar .search-box-wrapper input[name="search"] {
    width: 257px;
  }
}
@media (max-width: 1020px) {
  .media-library-action-bar .search-box-wrapper input[name="search"] {
    width: 187px;
  }
}
.media-library-action-bar .search-box-wrapper .clear-search-button {
  position: absolute;
  top: 17px;
  height: 36px;
  line-height: 34px;
  vertical-align: middle;
  right: 153px;
  padding-right: 12px;
  width: 30px;
  cursor: pointer;
  text-align: right;
  font-size: 14px;
  color: #CCC;
}
.media-library-action-bar .search-box-wrapper .clear-search-button:hover {
  color: #00BFF1;
}
.media-library-action-bar .filter-search-wrapper {
  box-sizing: border-box;
  border: 1px solid #CCC;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  display: inline-block;
  height: 36px;
  margin-left: 0;
  vertical-align: top;
}
.media-library-action-bar .filter-button {
  box-sizing: border-box;
  color: #555;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  height: 100%;
  padding: 6px 9px 6px 11px;
  text-align: center;
}
.media-library-action-bar .filter-button .fa {
  color: #555;
  font-size: 14px;
  line-height: 14px;
  margin-left: 6px;
  vertical-align: top;
}
.media-library-action-bar .filter-button:hover,
.media-library-action-bar .filter-button.active {
  color: #111;
}
.media-library-action-bar .filter-button:hover .fa,
.media-library-action-bar .filter-button.active .fa {
  color: #111;
}
.media-library-action-bar .filter-button.active {
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
}
.media-library-action-bar .active-asset-type-searches {
  display: block;
  box-sizing: border-box;
  padding: 0 0 0 30px;
}
.media-library-action-bar .active-asset-type-searches .active-asset-type-search-item {
  background-color: #FFC018;
  border-radius: 2px;
  color: #825b1e;
  cursor: pointer;
  display: inline-block;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin-right: 1px;
  padding: 4px 11px 4px 14px;
  text-align: center;
  text-transform: uppercase;
}
.media-library-action-bar .active-asset-type-searches .active-asset-type-search-item .remove-item {
  display: block;
  float: right;
  margin-left: 12px;
  font-size: 1.2em;
}
.media-library-action-bar .active-asset-type-searches .active-asset-type-search-item:hover {
  background-color: #ffce4b;
}
.media-library-action-bar .search-filter-dropdown {
  position: absolute;
  background-color: #FFF;
  border: none;
  border-radius: 2px;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
  display: none;
  margin: 4px 0 0;
  text-align: center;
  padding: 7px 0 9px 0;
}
.media-library-action-bar .search-filter-dropdown .filter-option,
.media-library-action-bar .search-filter-dropdown .sort-option {
  box-sizing: border-box;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  line-height: 18px;
  padding: 6px 0px;
  text-align: center;
  width: 100%;
}
.media-library-action-bar .search-filter-dropdown .filter-option:hover,
.media-library-action-bar .search-filter-dropdown .sort-option:hover {
  color: #FDAD00;
}
.media-library-action-bar .search-filter-dropdown .filter-option.is-disabled,
.media-library-action-bar .search-filter-dropdown .sort-option.is-disabled {
  cursor: default;
  color: #CCC;
}
.media-library-action-bar .search-filter-dropdown .filter-option.is-active,
.media-library-action-bar .search-filter-dropdown .sort-option.is-active {
  color: #FDAD00;
}
.media-library-action-bar .search-filter-dropdown {
  border: 1px solid #CCC;
  border-top: 0;
  left: 397px;
  top: 48px;
  width: 119px;
}
@media (max-width: 1120px) {
  .media-library-action-bar .search-filter-dropdown {
    left: 297px;
  }
}
@media (max-width: 1020px) {
  .media-library-action-bar .search-filter-dropdown {
    left: 227px;
  }
}
.media-library-action-bar .extra-active-current-filters-dropdown {
  margin-top: 1px;
}
.media-library-action-bar .organize-items-dropdown {
  top: 70px;
  width: 140px;
}
