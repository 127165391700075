.modal-link-sharer {
  width: 500px;
  margin-top: 175px;
}
.modal-link-sharer .separator {
  background: #ddd;
  height: 1px;
  margin: 30px 0;
  width: 100%;
}
.modal-link-sharer .title-extra {
  color: #888;
  font-weight: 400;
}
.modal-link-sharer .title-extra .zf-icon-box {
  color: #aaa;
  margin-left: 10px;
}
.modal-link-sharer .modal-section-title {
  margin-bottom: 8px;
  text-transform: uppercase;
  font-size: 12px;
  color: #111;
}
.modal-link-sharer .link-sharer .copy-text-input-container input[type="text"] {
  width: 362px;
}
.modal-link-sharer .link-sharer .copy-text-input-container .btn-basic-primary {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: 0;
}
.modal-link-sharer .link-sharer.public .copy-field {
  float: left;
  width: 80%;
}
.modal-link-sharer .link-sharer.public .copy-field .copy-text-input-container input[type="text"] {
  width: 260px;
}
.modal-link-sharer .link-sharer.public .social-sharing-actions {
  float: right;
  margin-top: 5px;
  width: 20%;
  font-size: 24px;
  color: #888;
}
.modal-link-sharer .link-sharer.public .share-facebook-icon:hover,
.modal-link-sharer .link-sharer.public .share-twitter-icon:hover,
.modal-link-sharer .link-sharer.public .share-linkedin-icon:hover {
  color: #4282D5;
}
.modal-link-sharer .subtitle-container {
  color: #111 !important;
  margin-top: 10px !important;
  font-size: 12px;
}
.modal-link-sharer .share-via-email-error {
  color: #FC5343;
  font-size: 12px;
  margin-bottom: 5px;
}
.modal-link-sharer .share-via-email-new-emails {
  color: #888;
  font-size: 12px;
  margin: 5px 0;
}
.modal-link-sharer .share-via-email .share-via-email-note {
  margin-top: 5px;
}
.modal-link-sharer .share-via-email .share-via-email-btn {
  margin-top: 5px;
  width: 100%;
  padding: 12px;
}
.modal-link-sharer .new-link-container {
  margin-top: 10px;
}
.modal-link-sharer .new-link-container span {
  color: #4282D5;
  cursor: pointer;
  font-size: 12px;
  margin-right: 20px;
}
