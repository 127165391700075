.file-items.grid .media-library-asset {
  position: relative;
}
.file-items.grid .media-library-asset:hover .view-or-select-overlay {
  display: block;
}
.file-items.grid .media-library-asset.selected .view-or-select-overlay .deselect-option {
  display: inline;
}
.file-items.grid .media-library-asset.selected .view-or-select-overlay .select-option {
  display: none;
}
.file-items.grid .media-library-asset .view-or-select-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 34px;
  display: none;
  background-color: #666;
  cursor: pointer;
  opacity: 0.96;
}
.file-items.grid .media-library-asset .view-or-select-overlay .deselect-option {
  display: none;
}
.file-items.grid .media-library-asset .view-or-select-overlay .view-asset,
.file-items.grid .media-library-asset .view-or-select-overlay .select-asset {
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 50%;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 50px;
  vertical-align: middle;
  color: #FFF;
}
.file-items.grid .media-library-asset .view-or-select-overlay .view-asset:hover,
.file-items.grid .media-library-asset .view-or-select-overlay .select-asset:hover {
  background-color: #4ECEF6;
}
.file-items.grid .media-library-asset .thumbnail-wrapper {
  position: relative;
}
.file-items.grid .media-library-asset .playable-asset-icon {
  position: absolute;
  top: 40%;
  left: 45%;
  height: 24px;
  width: 24px;
  margin-left: -24px/2;
  margin-top: -24px/2;
  opacity: 0.7;
}
.file-items.grid .media-library-asset .pdf-icon-wrapper {
  width: 115px;
  margin: 0 auto;
  height: 92px;
  padding: 16px 0px;
  box-sizing: border-box;
  background-color: #222;
}
.file-items.grid .media-library-asset .pdf-icon-wrapper .pdf-icon {
  display: block;
  margin: 0 auto;
  padding-top: 4px;
  width: 40px;
}
.file-items.grid .media-library-asset .pdf-icon-wrapper .ppt-icon {
  padding-top: 10px;
  width: 70px;
}
.file-items.grid .media-library.view_only .media-library-asset .view-asset {
  height: 100%;
  line-height: 100px;
}
.file-items.grid .media-library.view_only .media-library-asset .select-asset {
  display: none;
}
