html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
body {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: auto;
  word-wrap: break-word;
}
body.visitor-mode .app-container {
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
body.screenshot-mode .app-container {
  position: relative !important;
}
p {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
a img {
  border: none;
}
.video-js {
  height: 100% !important;
  width: 100% !important;
}
.footer {
  padding: 2em 0;
  text-align: center;
}
.powered-by-zf {
  margin: 0 auto;
  width: 182px;
  height: 22px;
}
.powered-by-zf img {
  width: 100%;
}
.is-mobile-layout .overlay.type-pdf .overlay-asset-loading,
.is-mobile-layout .overlay.type-ppt .overlay-asset-loading {
  display: none;
}
.is-mobile-layout .section-type-text .text-wrapper,
.is-mobile-layout .tile-text {
  overflow: auto;
}
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
.begin-variable-zone-marker {
  width: 0px;
  height: 0px;
}
.vjs-poster {
  background-color: #000;
}
.outer-tiles-wrapper {
  margin: 0 auto;
  position: relative;
  width: 84%;
  height: 100%;
  max-width: 1200px;
}
.is-desktop-layout .subpage-is-fullpage {
  max-height: 100%;
  overflow: auto;
}
.is-desktop-layout .subpage-is-fullpage .section-type-text .text-wrapper {
  max-width: 975px;
  min-width: 450px;
  width: 75%;
}
.subpage-is-fullpage .section-type-playlist .outer-tiles-wrapper,
.subpage-is-fullpage .section-type-slideshow .outer-tiles-wrapper {
  max-width: 100%;
  width: 100%;
}
.playlist-mobile-viewer .outer-tiles-wrapper,
.slideshow-mobile-viewer .outer-tiles-wrapper {
  width: 100%;
}
