.input-container {
  vertical-align: top;
  margin-bottom: 15px;
}
.input-container input[type=text],
.input-container input[type=number],
.input-container .dummy-input {
  border-radius: 2px;
}
.input-container .field-name-container {
  margin: 0px 0 1px 0;
}
.input-container .field-name-container .zf-tooltip-icon-box {
  display: inline-block;
  margin-left: 12px;
  color: #aaa;
}
.input-container .field-name {
  font-weight: 400;
}
.input-container .error-message-container {
  font-size: 0.8em;
  line-height: 1.6em;
  color: red;
}
.input-container .success-message-container {
  font-size: 0.8em;
  line-height: 1.6em;
  color: #24CD80;
}
.input-container .description-container {
  font-size: 0.9em;
  line-height: 1.6em;
  color: #888;
  margin-top: 5px;
}
.input-container.halfsie {
  width: calc(50% - 10px);
  float: left;
  display: inline-block;
}
.input-container.halfsie + .halfsie {
  float: right;
}
.input-container.error input,
.input-container.error select {
  border-color: red;
}
.input-container.success input,
.input-container.success select {
  border-color: #24CD80;
}
.input-container.dark input[type=text],
.input-container.dark input[type=number],
.input-container.dark .dummy-input {
  color: white;
  background-color: #1D2027;
  border-radius: 2px;
  border: 1px solid #111318;
  width: 100%;
  font-size: 12px;
}
.input-container.dark textarea {
  background-color: #1D2027;
  border-radius: 2px;
  border: 1px solid #111318;
  color: white;
  width: 100%;
  max-width: 100%;
  font-size: 12px;
  line-height: 17px;
}
.input-container.dark .field-name {
  text-transform: uppercase;
  color: #A3A5A8;
  font-weight: 400;
  font-size: 9px;
  letter-spacing: 1px;
}
