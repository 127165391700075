.rc-tooltip.zf-tipsy {
  opacity: 1;
  z-index: 50000;
  pointer-events: none;
  /* Prevents the tooltip from flickering when it is being displayed under the cursor*/
}
.rc-tooltip.zf-tipsy .rc-tooltip-arrow {
  border-top-color: #FFFFFF;
  z-index: 1;
}
.rc-tooltip.zf-tipsy .rc-tooltip-inner {
  background-color: #FFFFFF;
  color: #1E232E;
  border: 1px solid #CCCCCC;
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.24);
  font-family: "Lato", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.35px;
  padding: 15px 20px;
  max-width: 280px;
  min-height: 0;
  word-break: break-word;
  border-radius: 2px;
  opacity: 0.98;
}
