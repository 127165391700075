#interstitial-mount-point {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #0C162F;
  color: white;
  font-family: 'Lato', Sans-Serif;
  font-size: 13px;
  text-align: center;
  overflow: auto;
}
.interstitial-base {
  margin-top: 100px;
  display: inline-block;
  max-width: 960px;
  width: 80%;
  height: 400px;
  text-align: left;
  box-sizing: border-box;
}
.interstitial-base .continue-button {
  padding: 16px 18px 16px 17px;
  color: white;
  background-color: #3976CF;
  font-family: 'Lato', Sans-Serif;
  border-radius: 3px;
  border: 2px solid transparent;
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  -webkit-font-smoothing: auto;
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 94%;
  transition: all 0.2s;
}
.interstitial-base .continue-button:hover {
  text-decoration: none;
  background-color: #2E73E2;
}
.interstitial-base .continue-button:active {
  background-color: #2f6ac0;
  outline: none;
}
.interstitial-base .continue-button.disabled,
.interstitial-base .continue-button[disabled] {
  cursor: not-allowed;
  background-color: #0c162f;
  border: 2px solid #323248;
}
.interstitial-base .continue-button.btn-basic-secondary {
  background-color: transparent;
  border: 1px solid #AAA;
  color: #333;
}
.interstitial-base .continue-button.btn-basic-secondary:hover {
  border: 1px solid #888;
  color: #222;
}
.interstitial-base .continue-button.btn-basic-secondary:active {
  background-color: #EEE;
  transition: color 150ms, background-color 150ms;
}
.interstitial-base input[type=text] {
  transition: all 0.2s;
  font-family: 'Lato', Sans-Serif;
  margin-top: 10px;
  padding: 12px;
  background-color: #323248;
  border: none;
  letter-spacing: 0.5px;
  border-radius: 3px;
  width: 90%;
  box-sizing: border-box;
  color: #ECF6FF;
  font-size: 1em;
}
.interstitial-base .col1 {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 40px;
  margin-right: 70px;
}
.interstitial-base .col2 {
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
}
.interstitial-base .input-container {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
}
.interstitial-base .account-logo {
  max-width: 100%;
  margin-bottom: 10px;
  height: 70px;
}
.interstitial-base .large-heading {
  font-size: 3em;
  line-height: 1.4em;
  color: #ECF6FF;
  letter-spacing: 0.8px;
}
.interstitial-base .medium-heading {
  font-size: 1.2em;
  line-height: 1.5em;
  letter-spacing: 0.8px;
  color: #ECF6FF;
  font-weight: 400;
  width: 44%;
  margin-bottom: 28px;
}
.interstitial-base.consent-interstitial .medium-heading {
  width: 90%;
}
.interstitial-base .consent-message {
  font-weight: 400;
  color: #DCE6EF;
  font-size: 0.92em;
}
.interstitial-base .consent-checkbox-wrapper {
  color: #DCE6EF;
  font-weight: 400;
  margin-top: 18px;
  margin-bottom: 28px;
  font-size: 0.92em;
}
.interstitial-base .consent-checkbox-wrapper .consent-checkbox {
  cursor: pointer;
}
.interstitial-base .consent-checkbox-wrapper .consent-checkbox-label {
  cursor: pointer;
}
.interstitial-base .consent-checkbox-wrapper a {
  color: #60a5fc;
  text-decoration: none;
}
.interstitial-base .consent-checkbox-wrapper a:hover {
  color: #ECF6FF;
}
.interstitial-base .consent-checkbox-wrapper a:visited {
  color: #60a5fc;
}
.interstitial-base .continue-anonymously-button {
  cursor: pointer;
  margin: 28px 0;
  text-align: center;
  width: 94%;
  color: #397DE8;
  background-color: transparent;
  border: none;
}
.interstitial-base .continue-anonymously-button:hover {
  color: #387BF1;
}
.interstitial-base label.text-label {
  color: #DCE6EF;
  display: block;
  margin: 0 0 20px;
  letter-spacing: 0.5px;
}
.interstitial-base .error-message {
  color: #FF4B34;
  font-weight: 400;
  padding: 5px;
}
.interstitial-base textarea:focus,
.interstitial-base input:focus,
.interstitial-base input[type]:focus {
  border-color: none;
  box-shadow: none;
  background-color: #2A2A3D;
  outline: 2px solid #4282D5;
}
@media screen and (min-height: 680px) {
  .interstitial-base .inner {
    margin-top: 20vh;
  }
}
@media screen and (min-width: 769px) {
  .interstitial-base .col1 {
    width: 280px;
  }
  .interstitial-base .col2 {
    min-width: 489px;
    width: 50%;
  }
  .interstitial-base .input-container {
    width: 48%;
    min-width: 240px;
    max-width: 320px;
  }
  .interstitial-base .continue-button-container {
    width: 100%;
  }
}
@media (max-width: 1054px) {
  .interstitial-base .inner {
    width: 50%;
  }
  .interstitial-base .col1 {
    width: auto;
  }
}
@media screen and (min-width: 581px) and (max-width: 768px) {
  .interstitial-base .inner {
    margin-top: 100px;
  }
  .interstitial-base .large-heading {
    font-size: 2em;
  }
  .interstitial-base .medium-heading {
    width: 80%;
  }
  .interstitial-base .input-container {
    width: 88%;
    display: block;
    min-width: 200px;
  }
  .interstitial-base input[type=text] {
    width: 100%;
  }
  .interstitial-base .continue-button-container {
    width: 84%;
    width: 94%;
  }
}
@media all and (max-width: 580px) {
  .interstitial-base {
    font-size: 17px;
  }
  .interstitial-base .inner {
    margin-top: 35px;
    width: 80%;
    height: 500px;
  }
  .interstitial-base .medium-heading {
    width: 80%;
  }
  .interstitial-base .large-heading {
    font-size: 1.5em;
    width: 280px;
  }
  .interstitial-base .col1 {
    margin-right: 0;
  }
  .interstitial-base .input-container {
    width: 100%;
    display: block;
  }
  .interstitial-base .continue-button {
    width: 100%;
  }
  .interstitial-base input[type=text] {
    width: 100%;
  }
}
