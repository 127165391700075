.zf-skip-link {
  font-size: 18px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.27);
  position: absolute;
  margin: 20px;
  transform: translateY(-200%);
  transition: transform 0.2s;
  z-index: 51;
}
.zf-skip-link:focus {
  transform: translateY(0%);
}
