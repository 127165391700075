.media-library-folder-zone {
  overflow: hidden;
}
.media-library-folder-zone .files-not-yet-loaded-message,
.media-library-folder-zone .empty-folder-message {
  clear: both;
  color: #AAA;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
}
.media-library-folder-zone .empty-folder-message {
  color: #999;
  display: none;
}
.media-library-folder-zone .multiple-drag-items {
  background: #40C6EA;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  color: #FFF;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 18px;
  opacity: 0.85;
  padding: 15px 17px;
  text-align: center;
}
.media-library-folder-zone .file-items.grid .media-library-item {
  float: left;
  box-sizing: border-box;
  position: relative;
  margin: 6px 5px;
  text-align: center;
  cursor: pointer;
  width: 127px;
  height: 138px;
  outline: none !important;
}
.media-library-folder-zone .file-items.grid .media-library-item .thumbnail-wrapper {
  border: 6px solid #F9F9F9;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  margin: 0 auto;
}
.media-library-folder-zone .file-items.grid .media-library-item .item-filename {
  border-radius: 12px;
  box-sizing: border-box;
  color: #000;
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  margin: 6px 0;
  overflow: hidden;
  padding: 1px 8px;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
  width: 92%;
}
.media-library-folder-zone .file-items.grid .media-library-item.selected .thumbnail-wrapper {
  border: 6px solid #D5DDDF;
}
.media-library-folder-zone .file-items.grid .media-library-item.selected .item-filename {
  background-color: #00BFF1;
  color: #FFF;
}
.media-library-folder-zone .folder-type-uploads.selected .item-filename,
.media-library-folder-zone .folder-type-prompts.selected .item-filename {
  background-color: #B355DA;
  color: #FFF;
}
.media-library-folder-zone .media-library-folder.selected .thumbnail-wrapper {
  background-color: #E0E8EA;
  border: 6px solid #E0E8EA;
}
.media-library-folder-zone .file-items-wrapper {
  background-color: #FBFBFB;
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 30px;
  z-index: 100;
  overflow: auto;
  padding: 6px 5px 30px 30px;
  color: #333;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
}
.media-library-folder-zone .file-items-wrapper .thumbnail-col {
  width: 5%;
}
.media-library-folder-zone .file-items-wrapper .filename-col {
  width: 50%;
}
.media-library-folder-zone .file-items-wrapper .type-col {
  width: 10%;
}
.media-library-folder-zone .file-items-wrapper .uploaded-by-col {
  width: 15%;
}
.media-library-folder-zone .file-items-wrapper .last-modified-col {
  width: 15%;
}
.media-library-folder-zone .file-items-wrapper .file-items-list-header {
  background-color: #F9F9F9;
  display: table;
  margin-bottom: 10px;
  width: 97%;
}
.media-library-folder-zone .file-items-wrapper .file-items-list-header .file-items-list-header-row {
  display: table-row;
}
.media-library-folder-zone .file-items-wrapper .file-items-list-header .file-items-list-header-row .header-item {
  display: table-cell;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  cursor: pointer;
}
.media-library-folder-zone .file-items-wrapper .file-items.list {
  background: #fff;
  border: 1px solid #ddd;
  border-collapse: collapse;
  border-radius: 4px;
  display: table;
  width: 97%;
}
.media-library-folder-zone .file-items-wrapper .file-items.list .media-library-item {
  cursor: pointer;
  font-size: 12px;
  outline: none !important;
  border-top: 1px solid #eee;
  height: 40px;
}
.media-library-folder-zone .file-items-wrapper .file-items.list .media-library-item:first-child {
  border-top: none;
}
.media-library-folder-zone .file-items-wrapper .file-items.list .media-library-item:hover {
  background: #eee;
}
.media-library-folder-zone .file-items-wrapper .file-items.list .media-library-item .item-cell {
  display: table-cell;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  max-width: 0;
}
.media-library-folder-zone .file-items-wrapper .file-items.list .media-library-item .thumbnail-wrapper {
  display: table-cell;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  vertical-align: middle;
  min-width: 60px;
}
.media-library-folder-zone .file-items-wrapper .file-items.list .media-library-item .thumbnail-wrapper img {
  height: auto;
  width: 50%;
  margin: 0 auto;
  display: block;
}
.media-library-folder-zone .file-items-wrapper .file-items.list .media-library-item .thumbnail-wrapper img.pdf-icon {
  width: 35%;
  background: #000;
  padding: 5px;
}
.media-library-folder-zone .file-items-wrapper .file-items.list .media-library-item .item-filename {
  font-weight: 700;
}
.media-library-folder-zone .file-items-wrapper .file-items.list .media-library-item.selected {
  background: #4ECEF6;
}
.media-library-folder-zone .file-items-wrapper .file-items.list .folder-type-uploads.selected .item-filename,
.media-library-folder-zone .file-items-wrapper .file-items.list .folder-type-prompts.selected .item-filename {
  background-color: inherit;
  color: inherit;
}
.media-library-folder-zone .file-items-wrapper .file-items.list .media-library-folder.selected .thumbnail-wrapper {
  background-color: inherit;
  border: none;
}
.media-library-folder-zone .media-library-upload-progress {
  background: #E8E8E8;
  color: #444;
  font-size: 13px;
  text-align: center;
  line-height: 40px;
}
.media-library-folder-zone .breadcrumbs-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 28px 55px 22px 33px;
  z-index: 110;
}
.media-library-folder-zone.no-breadcrumbs .file-items-wrapper {
  top: 0;
}
.media-library.view_only .media-library-folder-zone .file-items-wrapper {
  top: 35px;
}
.media-library.view_only .media-library-folder-zone .breadcrumbs-wrapper {
  display: none;
}
