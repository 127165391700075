.zoomforth-recorder {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 25000;
  font-family: 'Open Sans', sans-serif;
}
.zoomforth-recorder.zfr-is-full-window {
  position: fixed;
}
.zoomforth-recorder .zfr-background {
  position: absolute;
  opacity: 0.98;
  background-color: #0C162F;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
}
.zoomforth-recorder .zfr-player {
  position: absolute;
  top: 5%;
  left: 0;
  right: 0;
  bottom: 120px;
  z-index: 150;
}
.zoomforth-recorder .zfr-player video {
  width: 100%;
  height: 100%;
}
.zoomforth-recorder .zfr-ui-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 300;
}
.zoomforth-recorder .zfr-ui-bottom-bar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 120px;
  z-index: 100;
}
.zoomforth-recorder .zfr-recording-timer,
.zoomforth-recorder .zfr-flattery {
  color: #CCC;
  display: block;
  float: left;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-top: 42px;
  margin-left: 40px;
  vertical-align: top;
}
.zoomforth-recorder .zfr-flattery {
  float: right;
  margin-left: 0;
  margin-right: 40px;
}
.zoomforth-recorder .zfr-main-button {
  background-color: #AAA;
  border-radius: 3px;
  box-sizing: border-box;
  color: #FFF;
  cursor: pointer;
  display: block;
  font-size: 16px;
  height: 58px;
  margin: 0 auto;
  margin-top: 25px;
  padding: 18px;
  text-align: center;
  vertical-align: middle;
  width: 200px;
}
.zoomforth-recorder .zfr-main-button.zfr-record-button {
  background-color: #36cc29;
}
.zoomforth-recorder .zfr-main-button.zfr-record-button .fa {
  color: green;
  font-size: 10px;
  margin-right: 8px;
  padding-top: 6px;
  vertical-align: top;
}
.zoomforth-recorder .zfr-main-button.zfr-record-button:hover {
  background-color: #20B514;
}
.zoomforth-recorder .zfr-main-button.zfr-stop-recording {
  background-color: #FF0000;
}
.zoomforth-recorder .zfr-main-button.zfr-stop-recording:hover {
  background-color: #CA0000;
}
.zoomforth-recorder .zfr-main-button.zfr-skip-playback:hover {
  background-color: #999;
}
.zoomforth-recorder .zfr-is-recording-indicator {
  position: absolute;
  top: 50px;
  left: 40px;
  background-color: #FF0000;
  width: 12px;
  height: 12px;
  border-radius: 6px;
}
.zoomforth-recorder .zfr-countdown {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -80px;
  margin-left: -75px;
  box-sizing: border-box;
  color: #FFF;
  font-family: 'Lato', sans-serif;
  font-size: 100px;
  font-weight: 200;
  height: 150px;
  text-align: center;
  text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
  width: 150px;
}
.zoomforth-recorder .zfr-recording-complete-options {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -150px;
  margin-left: -160px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  box-sizing: border-box;
  height: 220px;
  padding: 25px;
  text-align: center;
  width: 320px;
}
.zoomforth-recorder .zfr-preview-recording,
.zoomforth-recorder .zfr-save-recording {
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 5px;
  padding: 17px;
  text-align: center;
  width: 100%;
}
.zoomforth-recorder .zfr-preview-recording .fa,
.zoomforth-recorder .zfr-save-recording .fa {
  margin-right: 8px;
  padding-top: 2px;
  vertical-align: top;
}
.zoomforth-recorder .zfr-preview-recording {
  background-color: #888;
  color: #FFF;
}
.zoomforth-recorder .zfr-preview-recording:hover {
  background-color: #777;
}
.zoomforth-recorder .zfr-save-recording {
  background-color: #36cc29;
  color: #FFF;
}
.zoomforth-recorder .zfr-save-recording:hover {
  background-color: #20B514;
}
.zoomforth-recorder .zfr-record-again {
  color: #FF0000;
  cursor: pointer;
  font-size: 13px;
  margin-top: 25px;
}
.zoomforth-recorder .zfr-record-again .fa {
  font-size: 14px;
  margin-right: 6px;
}
.zoomforth-recorder .zfr-record-again:hover {
  color: #CA0000;
}
.zoomforth-recorder .zfr-action-exit {
  position: absolute;
  top: 36px;
  right: 36px;
  background: #0D172F;
  cursor: pointer;
  font-size: 24px;
  padding: 8px 10px;
  width: 36px;
}
.zoomforth-recorder .zfr-action-exit:hover {
  opacity: 0.5;
}
.zoomforth-recorder .zfr-error-state {
  position: absolute;
  top: 40%;
  left: 30%;
  right: 30%;
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 15px;
  padding: 20px;
  color: #BB0000;
}
.zoomforth-recorder .zfr-reload-after-error-button {
  display: inline-block;
  padding: 10px;
  background-color: #300;
  color: #FFFFFF;
}
.zoomforth-recorder .zfr-recording-tips {
  position: absolute;
  left: 50%;
  top: 25%;
  z-index: 500;
  background-color: #0C1325;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  margin-left: -300px;
  opacity: 0.98;
  padding: 50px 32px;
  width: 630px;
}
.zoomforth-recorder .zfr-tips-title {
  color: #FFF;
  font-size: 17px;
  margin-top: 5px;
  margin-bottom: 25px;
  text-align: center;
}
.zoomforth-recorder .zfr-setup-recorder {
  box-sizing: border-box;
  background: #36cc29;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  color: #FFF;
  display: block;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  margin: 0 auto;
  margin-top: 35px;
  padding: 17px 10px;
  text-align: center;
  width: 300px;
  cursor: pointer;
}
.zoomforth-recorder .zfr-setup-recorder:hover {
  background-color: #20B514;
}
.zoomforth-recorder .zfr-tip {
  color: #DDD;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
}
.zoomforth-recorder .zfr-tip-number {
  font-weight: bold;
  margin-right: 6px;
  color: #36cc29;
}
