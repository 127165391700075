/*
  Any CSS changes that are likely to cause problems with theme backwards compatibility
  should be defined here, for clear reference.
  We need that reference for building the compatibility styles in the theme generator
*/
.section-type-text .text-wrapper {
  font-size: 1.5em;
}
.column-count-3 .tile-wrapper {
  font-size: 100%;
}
.column-count-5 .tile-wrapper {
  font-size: 100%;
}
