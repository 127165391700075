.zf-combobox .zf-combobox-input-wrapper {
  display: flex;
  justify-content: flex-start;
}
.zf-combobox .zf-combobox-input-wrapper .zf-combobox-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.zf-combobox .zf-combobox-input-wrapper input {
  width: 100%;
}
.zf-combobox [data-reach-combobox-list] {
  max-height: 400px;
  overflow-y: scroll;
}
