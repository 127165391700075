.zf-listbox {
  position: relative;
}
.zf-listbox .zf-listbox-label {
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
}
.zf-listbox .placeholder-option {
  display: none;
}
.zf-listbox [data-reach-listbox-button] {
  background-color: #FFFFFF;
  border: 1px solid #E2E4E6;
  border-radius: 2px;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
}
.zf-listbox [data-reach-listbox-arrow] {
  font-size: 1em;
  color: #9D9D9D;
}
.zf-listbox [data-reach-listbox-popover] {
  position: absolute;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: scroll;
  background-color: #FFFFFF;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 0;
  z-index: 1;
}
.zf-listbox [data-reach-listbox-popover] [data-reach-listbox-option] {
  color: #000000;
  padding: 16px 40px;
}
.zf-listbox [data-reach-listbox-popover] [data-reach-listbox-option][data-current-nav],
.zf-listbox [data-reach-listbox-popover] [data-reach-listbox-option][data-current-selected] {
  color: #FFFFFF;
  background-color: #5CA3FF;
  font-weight: normal;
}
.zf-listbox [data-reach-listbox-popover]:focus-within {
  box-shadow: none;
  outline: none;
  border: 0.5px solid #5CA3FF;
}
