[id^="replace-asset-modal"] {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
[id^="replace-asset-modal"] a {
  color: #000;
  text-decoration: underline;
}
[id^="replace-asset-modal"] .close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 38px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 16px;
  cursor: pointer;
}
[id^="replace-asset-modal"] .header {
  background-color: #E7E7E7;
  margin: 0px;
  padding: 24px;
  padding-top: 30px;
  padding-bottom: 32px;
}
[id^="replace-asset-modal"] .header h2 {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 16px;
}
[id^="replace-asset-modal"] .header p {
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}
[id^="replace-asset-modal"] .header.success {
  color: #044A39;
  background-color: #B5F1E3;
}
[id^="replace-asset-modal"] .body {
  flex-grow: 1;
  margin: 0px;
  overflow: auto;
}
[id^="replace-asset-modal"] .body .select-all {
  display: flex;
  align-items: center;
  background-color: #F4F4F4;
  padding: 0px 24px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}
[id^="replace-asset-modal"] .body .select-all label {
  font-size: 12px;
  margin: 0px;
  cursor: pointer;
}
[id^="replace-asset-modal"] .body .select-all .zf-plain-checkbox {
  font-size: 14px;
  margin-right: 24px;
  margin-top: -1px;
  padding: 0px;
}
[id^="replace-asset-modal"] .actions {
  display: flex;
  justify-content: flex-end;
  margin: 0px;
  padding: 20px;
  height: 38px;
  line-height: 38px;
  box-shadow: 0 -1px 7px 0 rgba(0, 0, 0, 0.2644);
}
[id^="replace-asset-modal"] .actions.replace-view {
  height: 30px;
  line-height: 30px;
}
[id^="replace-asset-modal"] .actions .cancel-button {
  height: 38px;
  text-decoration: none;
  line-height: 42px;
}
[id^="replace-asset-modal"] .actions .warning-text {
  height: 38px;
  color: #FF0102;
  flex-grow: 1;
  text-align: right;
  margin-right: 20px;
  line-height: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2px;
}
[id^="replace-asset-modal"] .actions .warning-text p {
  margin: 0px;
  margin-right: 4px;
}
[id^="replace-asset-modal"] .actions .action-button button.done-button {
  height: 30px;
  line-height: 30px;
  position: relative;
  padding: 0px;
  padding-left: 24px;
  padding-right: 12px;
}
[id^="replace-asset-modal"] .actions .action-button button.done-button i {
  position: absolute;
  left: 9px;
  top: -2px;
}
[id^="replace-asset-modal"] .actions .action-button button {
  height: 38px;
}
[id^="replace-asset-modal"] .actions .loading-indicator {
  position: relative;
  margin-left: 10px;
  margin-right: 30px;
  color: #4282D5;
  font-size: 12px;
}
[id^="replace-asset-modal"] .actions .loading-indicator .circular-spinner {
  position: absolute;
  top: 50%;
  left: -10px;
  right: auto;
  bottom: auto;
}
