#upload-drop-zone {
  z-index: 12000;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: none;
}
#upload-drop-zone .drop-zone-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
}
#upload-drop-zone .drop-zone-background-layer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #4ECEF6;
  opacity: 0.95;
  z-index: 10;
}
#upload-drop-zone .drop-zone {
  color: #FFF;
  font-family: "Open Sans", sans-serif;
  font-size: 1.5em;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 1.25em;
  margin: 0 auto;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  text-rendering: optimizeLegibility;
  z-index: 50;
}
#upload-drop-zone .drop-zone .drag-upload-icon {
  display: block;
  margin: 0 auto 0.8em auto;
}
.media-library {
  z-index: 100;
}
.media-library.view_only .media-library-folder-zone {
  top: 0;
}
.media-library .media-library-action-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  z-index: 200;
  transition: height 0.2s ease;
}
.media-library.has-active-media-type-search .media-library-action-bar {
  height: 100px;
}
.media-library .disabled-media-types-banner {
  background-color: #e5eeff;
  color: #17354c;
  line-height: 25px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 4px 15px 2px 40px;
  height: 25px;
  top: 70px;
  z-index: 11;
  border-bottom: 1px solid #b7c4dc;
}
.media-library .disabled-media-types-banner i {
  margin-left: 5px;
}
.media-library .media-library-folder-zone {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
  top: 70px;
  overflow: auto;
  transition: top 1s ease 0;
}
.media-library.disabled-media-types-banner-visible .media-library-folder-zone {
  top: 95px;
}
.media-library.has-active-media-type-search .media-library-folder-zone {
  top: 100px;
}
.media-library.disabled-media-types-banner-visible.has-active-media-type-search .media-library-folder-zone {
  top: 125px;
}
.media-library .media-library-info-panel {
  position: absolute;
  right: 0;
  top: 70px;
  width: 360px;
  bottom: 0;
  z-index: 150;
}
.media-library.info-panel-shown .media-library-folder-zone {
  right: 360px;
}
.enabled-media-warning-header {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
}
