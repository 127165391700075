.shareable-social-icon {
  display: inline-block;
  cursor: pointer;
  margin-left: 3px;
}
.shareable-social-icon .fa,
.shareable-social-icon .zf-icon {
  font-size: 25px;
  vertical-align: top;
  padding-right: 5px;
}
.shareable-social-icon .fa {
  margin-top: -3px;
}
.shareable-social-icon.share-facebook-icon .fa,
.shareable-social-icon.share-facebook-icon .zf-icon {
  color: #3B5998;
}
.shareable-social-icon.share-twitter-icon .fa,
.shareable-social-icon.share-twitter-icon .zf-icon {
  color: #55ACEE;
}
.shareable-social-icon.share-linkedin-icon .fa,
.shareable-social-icon.share-linkedin-icon .zf-icon {
  color: #0077B5;
}
.shareable-social-icon.share-tumblr-icon .fa,
.shareable-social-icon.share-tumblr-icon .zf-icon {
  color: #35465D;
}
