.publish-notification .notif-body {
  display: table;
}
.publish-notification .notif-body .share-content {
  display: table-cell;
  vertical-align: middle;
}
.publish-notification .notif-body .share-content .success-container {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-weight: 700;
  display: block;
  font-size: 20px;
}
.publish-notification .notif-body .share-content .title-container {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  display: block;
  font-size: 14px;
  margin-top: 5px;
}
.publish-notification .notif-body .share-content .subtitle-container {
  display: block;
  color: #111;
  margin-top: 5px;
  font-size: 13px;
}
.publish-notification .notif-body .share-content .subtitle-container a {
  color: #111;
}
.publish-notification .notif-body .share-content .subtitle-container a i {
  padding-right: 2px;
}
.publish-notification .notif-body .share-content .subtitle-container .zf-preview-icon-box {
  display: inline-block;
  color: #888;
}
.publish-notification .notif-body .share-content .subtitle-container .page-accessibility-warning-deactivated {
  font-size: 0.85em;
  color: black;
  width: 70%;
  line-height: 1em;
}
