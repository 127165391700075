.subpage-is-fullpage .section-type-pdf {
  height: 100%;
}
.subpage-is-fullpage .section-type-pdf .section-iframe-wrapper {
  height: 100%;
}
.subpage-is-multisection .section-type-pdf .section-iframe-wrapper {
  display: block;
  height: 500px;
  margin: 0 auto;
  position: relative;
}
.pdf-section-no-embed-wrapper {
  position: relative;
  height: 100%;
}
.pdf-section-no-embed-wrapper .tile-wrapper {
  height: 100%;
  position: relative;
  display: block;
  margin-bottom: 2px;
}
.pdf-section-no-embed-wrapper .tile-wrapper .tile-padding-wrapper {
  position: relative;
  border-radius: 0;
}
.pdf-section-no-embed-wrapper .tile-wrapper .tile {
  position: relative;
  overflow: hidden;
  margin: auto;
  height: 100%;
}
.pdf-section-no-embed-wrapper .tile-wrapper.type-pdf[pdf_icon="true"] .tile-image-wrapper {
  height: 80px;
  width: 60px;
  margin-left: -60px/2;
}
.pdf-section-no-embed-wrapper .tile-wrapper.type-pdf[pdf_icon="true"] .tile {
  min-height: 160px;
}
