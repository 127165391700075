.base-modal.simple-modal-with-header {
  padding: 0;
}
.base-modal.simple-modal-with-header .simple-modal-header {
  position: relative;
  padding: 20px 36px;
  border-bottom: 1px solid #eaeaea;
}
.base-modal.simple-modal-with-header .simple-modal-header .title-container {
  font-family: "Lato";
  font-weight: 600;
  display: block;
  font-size: 16px;
}
.base-modal.simple-modal-with-header .simple-modal-header .subtitle-container {
  display: inline-block;
  color: #888;
  margin-top: 2px;
}
.base-modal.simple-modal-with-header .simple-modal-header .subtitle-container .zf-preview-icon-box {
  display: inline-block;
  margin-left: 12px;
  color: #888;
}
.base-modal.simple-modal-with-header .simple-modal-header .close-container {
  position: absolute;
  right: 26px;
  top: 20px;
  cursor: pointer;
}
.base-modal.simple-modal-with-header .simple-modal-header .close-container i {
  font-size: 24px;
  color: #aaa;
}
.base-modal.simple-modal-with-header .simple-modal-header .close-container:hover i {
  color: #111;
}
.base-modal.simple-modal-with-header .simple-modal-with-header-body {
  background-color: #E9EBEE;
  padding: 40px;
}
.base-modal.simple-modal-with-header .buttons-row-container {
  text-align: right;
  margin-top: 50px;
}
.base-modal.simple-modal-with-header .input-container:not(.halfsie) + .input-container:not(.halfsie) {
  margin-top: 25px;
}
.base-modal.simple-modal-with-header .input-container .description-container {
  color: #444;
}
