.notify-error-data-maybe-lost.base-modal {
  width: 600px;
}
.notify-error-data-maybe-lost.base-modal h3 {
  color: #DE363B;
}
.notify-error-data-maybe-lost.base-modal .explain-400 {
  color: #DE363B;
  font-family: monospace;
}
