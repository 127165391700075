.image-cropper-modal {
  width: 80%;
  height: 650px;
}
.image-cropper-modal .image-cropper-container {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.image-cropper-modal .image-cropper-container .sidebar-buttons {
  display: flex;
  justify-content: flex-end;
}
.image-cropper-modal .image-cropper-container .sidebar-buttons button {
  width: 70px;
}
.image-cropper-modal .image-cropper-container .sidebar-buttons button:first-child {
  margin-right: 10px;
}
.image-cropper-modal .image-cropper-container .cropper {
  height: 100%;
  flex-grow: 1;
}
.image-cropper-modal .image-cropper-container .cropper .loader-wrapper {
  position: relative;
  height: 100%;
  font-size: 24px;
}
.image-cropper-modal .image-cropper-container .cropper .loader-wrapper .loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-cropper-modal .image-cropper-container .cropper .hidden {
  visibility: hidden;
  display: block;
}
.image-cropper-modal .image-cropper-container .sidebar {
  width: 260px;
  padding-left: 20px;
  /* Same as modal padding */
  display: flex;
  flex-direction: column;
}
.image-cropper-modal .image-cropper-container .sidebar .preview-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #CCC;
  margin-bottom: 20px;
  height: 200px;
}
.image-cropper-modal .image-cropper-container .sidebar .preview-wrapper .preview {
  overflow: hidden;
  width: 100%;
  height: 200px;
}
.image-cropper-modal .image-cropper-container .sidebar .controls {
  flex-grow: 1;
  display: block;
}
.image-cropper-modal .image-cropper-container .sidebar .controls .row {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 8px 0;
  margin-bottom: 10px;
}
.image-cropper-modal .image-cropper-container .sidebar .controls .row.header-row {
  font-weight: 700;
  padding: 0;
  margin-bottom: 0;
}
.image-cropper-modal .image-cropper-container .sidebar .controls .row .col {
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  width: 90px;
}
.image-cropper-modal .image-cropper-container .sidebar .controls .row .col:first-child {
  margin-right: 75px;
}
.image-cropper-modal .image-cropper-container .sidebar .controls .row .control-label {
  font-size: 14px;
  color: #282829;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  min-width: 10px;
  margin-right: 10px;
}
.image-cropper-modal .image-cropper-container .sidebar .controls .row .control-unit {
  margin-left: 10px;
  color: #777;
  font-family: "Lato", Helvetica, Arial, sans-serif;
}
.image-cropper-modal .image-cropper-container .sidebar .controls .row .rc-slider {
  margin-right: 10px;
  width: 175px;
}
.image-cropper-modal .image-cropper-container .sidebar .controls .row .rc-slider .rc-slider-rail {
  height: 3px;
}
.image-cropper-modal .image-cropper-container .sidebar .controls .row .zf-checkbox {
  font-size: 14px;
}
.image-cropper-modal .image-cropper-container .sidebar .controls .row .zf-checkbox .zfam-square {
  color: #FFF;
}
.image-cropper-modal .image-cropper-container .sidebar .controls .row .zf-checkbox .zfam-check-square {
  color: #4282D5;
}
.image-cropper-modal .image-cropper-container .sidebar .controls .row .number-input {
  width: 50px;
  height: 30px;
  font-size: 11px;
  text-align: center;
  display: flex;
  border-radius: 2px;
  border: 1px solid #CCC;
}
