#velt-toolbar {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #3e4658;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: 'Lato', sans-serif;
}
#velt-toolbar .message {
  color: #D9BFD9;
}
#velt-toolbar .message .email {
  color: white;
}
#velt-toolbar .velt-components-wrapper {
  display: flex;
  align-items: center;
}
#velt-toolbar .velt-components-wrapper > * {
  margin: 0 10px;
  height: 32px;
}
/*************************************************************************************************/
/*** VELT HACKS **********************************************************************************/
/*************************************************************************************************/
#velt-toolbar.velt-z-index-hack {
  z-index: 3000000000;
}
.cdk-overlay-container .cdk-overlay-pane .recorder-panel {
  bottom: 70px;
}
.cdk-overlay-container .cdk-overlay-pane.snippyly-overlay-panel.snippyly-huddle-menu-panel-overlay-panel {
  bottom: 100px !important;
}
body.snippyly-add-comment-mode .tile-wrapper:hover .tile-action-hover-container {
  display: none;
}
