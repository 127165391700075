.rc-tooltip.presentation-tipsy {
  opacity: 1;
}
.rc-tooltip.presentation-tipsy .rc-tooltip-inner {
  background-color: #394050;
  box-shadow: 0px 10px 13px 5px rgba(0, 0, 0, 0.51);
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.35px;
  padding: 10px 15px;
  max-width: 280px;
  min-height: 0;
  word-break: break-word;
  border-radius: 2px;
  opacity: 0.98;
}
.rc-tooltip.presentation-tipsy .rc-tooltip-arrow {
  display: none;
}
.rc-tooltip.presentation-tipsy .presentation-progress-tooltip {
  text-align: center;
  font-weight: 600;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.rc-tooltip.presentation-tipsy .presentation-progress-tooltip img {
  height: auto;
  width: 100px;
}
.presentation-wrapper {
  background-color: #CCC;
  color: #FFF;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  height: 100%;
  width: 100%;
}
.presentation-wrapper .still-processing {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateX(-50%) translateX(-50%);
  -o-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  color: #000;
  font-size: 16px;
  text-align: center;
  width: 400px;
}
.presentation-wrapper .still-processing .processing-description {
  margin-bottom: 10px;
}
.presentation-wrapper .still-processing .download-link {
  cursor: pointer;
  color: #60a5fc;
}
.presentation-wrapper .still-processing img {
  display: block;
  margin: 30px auto;
}
.presentation-wrapper .slick-slider,
.presentation-wrapper .slick-list {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.presentation-wrapper .slick-track {
  position: absolute;
  top: 40px;
  bottom: 40px;
}
.presentation-wrapper .slick-slide {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.presentation-wrapper .presentation-top-bar {
  background-color: #282C35;
  border-bottom: 1px solid #1D2630;
  position: absolute;
  height: 40px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.presentation-wrapper .presentation-top-bar .presentation-left {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 25px;
  width: 70%;
  text-align: left;
}
.presentation-wrapper .presentation-top-bar .presentation-left .presentation-name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.presentation-wrapper .presentation-top-bar .presentation-right {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 25px;
  cursor: pointer;
}
.presentation-wrapper .presentation-bottom-bar {
  background-color: #282C35;
  border-top: 1px solid #1D2630;
  position: absolute;
  height: 40px;
  bottom: 0;
  left: 0;
  right: 0;
}
.presentation-wrapper .presentation-bottom-bar .presentation-left {
  position: absolute;
  left: 25px;
  height: 100%;
  width: 126px;
}
.presentation-wrapper .presentation-bottom-bar .presentation-left .next-btn,
.presentation-wrapper .presentation-bottom-bar .presentation-left .prev-btn {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 24px;
  line-height: 40px !important;
}
.presentation-wrapper .presentation-bottom-bar .presentation-left .next-btn.disabled,
.presentation-wrapper .presentation-bottom-bar .presentation-left .prev-btn.disabled {
  opacity: 0.4;
  cursor: default;
}
.presentation-wrapper .presentation-bottom-bar .presentation-left .next-btn {
  right: 0;
}
.presentation-wrapper .presentation-bottom-bar .presentation-left .prev-btn {
  left: 0;
}
.presentation-wrapper .presentation-bottom-bar .presentation-left .slide-counter {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateX(-50%) translateX(-50%);
  -o-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  text-align: center;
  font-weight: 700;
}
.presentation-wrapper .presentation-bottom-bar .presentation-right {
  position: absolute;
  left: 160px;
  right: 25px;
  height: 100%;
}
.presentation-wrapper .presentation-bottom-bar .presentation-right .progress-bar {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 50px;
  background-color: #394050;
  border-radius: 4px;
  height: 14px;
}
.presentation-wrapper .presentation-bottom-bar .presentation-right .progress-bar .progress-step {
  display: inline-block;
  cursor: pointer;
  margin: 0;
  padding: 0;
  height: 14px;
  box-sizing: border-box;
  z-index: 1;
  border-right: 2px solid #282C35;
}
.presentation-wrapper .presentation-bottom-bar .presentation-right .progress-bar .progress-step:hover {
  background-color: #444c5f;
}
.presentation-wrapper .presentation-bottom-bar .presentation-right .progress-bar .progress-step.active {
  background-color: #4282D5;
  border-right: 2px solid #2F6EC0;
}
.presentation-wrapper .presentation-bottom-bar .presentation-right .progress-bar .progress-step.active:hover {
  background-color: #5CA3FF;
  border-right: 2px solid #5CA3FF;
}
.presentation-wrapper .presentation-bottom-bar .presentation-right .progress-bar .progress-step.active.is-current {
  border-right: 2px solid #4282D5;
}
.presentation-wrapper .presentation-bottom-bar .presentation-right .progress-bar .progress-step.is-first {
  border-radius: 4px 0 0 4px;
}
.presentation-wrapper .presentation-bottom-bar .presentation-right .progress-bar .progress-step.is-last {
  border-radius: 0 4px 4px 0;
}
.presentation-wrapper .presentation-bottom-bar .presentation-right .fullscreen-btn {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  cursor: pointer;
}
.accessible-presentation {
  position: relative;
}
.accessible-presentation button {
  background: transparent;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
}
.accessible-presentation.is-fullscreen-enabled {
  height: 100% !important;
}
.accessible-presentation .accessible-presentation-nav {
  background-color: #282C35;
  position: absolute;
  height: 40px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 25px;
  justify-content: space-between;
}
.accessible-presentation .accessible-presentation-top {
  border-bottom: 1px solid #1D2630;
  top: 0;
}
.accessible-presentation .accessible-presentation-top h2 {
  font-size: 13px;
  font-weight: normal;
}
.accessible-presentation .accessible-presentation-bottom {
  border-top: 1px solid #1D2630;
  bottom: 0;
}
.accessible-presentation .accessible-presentation-bottom .accessible-presentation-controls {
  display: flex;
  font-weight: 700;
  align-items: center;
  justify-content: space-between;
  min-width: 120px;
  text-align: left;
}
.accessible-presentation .accessible-presentation-bottom .accessible-presentation-controls .next-btn,
.accessible-presentation .accessible-presentation-bottom .accessible-presentation-controls .prev-btn {
  font-size: 24px;
}
.accessible-presentation .accessible-presentation-bottom .accessible-presentation-controls .next-btn.disabled,
.accessible-presentation .accessible-presentation-bottom .accessible-presentation-controls .prev-btn.disabled {
  opacity: 0.4;
  cursor: default;
}
.accessible-presentation .accessible-presentation-bottom .accessible-presentation-fullscreen-wrapper {
  text-align: right;
  min-width: 30px;
}
.accessible-presentation .accessible-presentation-bottom .accessible-presentation-progress-bar {
  display: flex;
  width: 70%;
}
.accessible-presentation .accessible-presentation-bottom .accessible-presentation-progress-bar .accessible-progress-step {
  background-color: #394050;
  display: inline-block;
  cursor: pointer;
  margin: 0 2px 0 0;
  padding: 0;
  height: 14px;
  box-sizing: border-box;
  z-index: 1;
}
.accessible-presentation .accessible-presentation-bottom .accessible-presentation-progress-bar .accessible-progress-step:first-child {
  border-radius: 4px 0 0 4px;
}
.accessible-presentation .accessible-presentation-bottom .accessible-presentation-progress-bar .accessible-progress-step:last-child {
  border-radius: 0 4px 4px 0;
  margin-right: 0;
}
.accessible-presentation .accessible-presentation-bottom .accessible-presentation-progress-bar .accessible-progress-step:hover {
  background-color: #444c5f;
}
.accessible-presentation .accessible-presentation-bottom .accessible-presentation-progress-bar .accessible-progress-step.active {
  background-color: #4282D5;
}
.accessible-presentation .accessible-presentation-bottom .accessible-presentation-progress-bar .accessible-progress-step.active:hover {
  background-color: #5CA3FF;
}
.accessible-presentation .accessible-presentation-viewport {
  position: absolute;
  top: 40px;
  bottom: 40px;
  left: 0;
  right: 0;
}
