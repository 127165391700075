.copy-and-duplicate-button-set {
  margin-top: 10px;
}
.copy-and-duplicate-button-set button {
  width: 50%;
  height: 34px;
}
.copy-and-duplicate-button-set i {
  font-size: 1em;
  opacity: 0.3;
}
.copy-and-duplicate-button-set i:hover {
  opacity: 0.3;
}
