.in-page-search-common-styles {
  width: 30px;
  height: 30px;
  background: transparent;
  font-size: 1.2em;
  border: none;
  cursor: pointer;
}
.in-page-search-opener {
  display: block;
  vertical-align: middle;
  opacity: 0.8;
  background: white;
  color: black;
  border-radius: 15px;
  line-height: 0px;
  box-shadow: 0;
  padding: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  z-index: 51;
  position: fixed;
  top: 30px;
  right: 30px;
}
.in-page-search-opener:hover {
  opacity: 1;
}
.in-page-search-opener .in-page-search-icon {
  font-size: 22px;
}
