.style-preset-hint-box {
  height: 1.6em;
  line-height: 1.7;
  border-radius: 2px;
  font-size: 9px;
  padding: 1px 2px;
  display: inline-block;
  position: relative;
}
.style-preset-hint-box .elem-example {
  padding: 0 1px;
}
