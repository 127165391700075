@keyframes zfam-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.zf-app-modern-icon {
  line-height: inherit !important;
}
.zf-app-modern-icon:before {
  vertical-align: -10% !important;
}
.zf-app-modern-icon.zfam-compass-slash {
  font-size: 11px;
}
.zf-app-modern-icon.zfam-spin {
  display: inline-block;
  animation: zfam-spin 2s infinite linear;
}
.zf-app-modern-icon.zfam-pulse {
  animation: zfam-spin 1s infinite steps(8);
}
.zf-app-modern-icon.zfam-dot-circle-o-dark {
  color: #1E232F !important;
}
