input[type="text"].basic-text-input,
input[type="password"].basic-text-input,
input.basic-text-input {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #111;
  background-color: #FFF;
  border: 1px solid #ddd;
  box-shadow: none;
  display: inline-block;
  padding: 6px 10px 8px 10px;
  margin-bottom: 0px;
  box-sizing: border-box;
  height: 36px;
  width: 100%;
  border-radius: 2px;
}
input[type="text"].basic-text-input:focus,
input[type="password"].basic-text-input:focus,
input.basic-text-input:focus {
  outline: none;
  border: 1px solid #60a5fc;
}
input[type="text"].basic-text-input.dark,
input[type="password"].basic-text-input.dark,
input.basic-text-input.dark {
  color: white;
  background-color: #1D2027;
  border: 1px solid #111318;
}
