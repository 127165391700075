#media-library-info-panel-asset {
  background-color: #FFF;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  overflow: auto;
  border-left: 1px solid #AAA;
}
#media-library-info-panel-asset .item-path {
  margin: -5px 20px;
  padding: 0px;
  text-align: left;
}
#media-library-info-panel-asset .input-container {
  margin-bottom: 10px;
}
#media-library-info-panel-asset .input-container button {
  margin-top: 5px;
  margin-right: 10px;
}
#media-library-info-panel-asset .input-container button:last-child {
  margin-right: 0;
}
#media-library-info-panel-asset .form-elements-container {
  line-height: 1.2;
}
#media-library-info-panel-asset .asset-label {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #666;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 3px;
  padding: 0;
}
#media-library-info-panel-asset .asset-value {
  font-size: 12px;
  display: block;
  word-wrap: anywhere;
  cursor: pointer;
  padding: 0px;
}
#media-library-info-panel-asset .placeholder {
  font-style: italic;
}
#media-library-info-panel-asset .asset-header {
  border-bottom: 1px solid #EEE;
  margin: 20px;
  max-width: 360px;
}
#media-library-info-panel-asset .asset-header .asset-description ul,
#media-library-info-panel-asset .asset-header .asset-description li {
  list-style: inherit;
}
#media-library-info-panel-asset .asset-header .asset-alt-text .asset-label {
  border-bottom: 1px dashed #6C6C6C;
}
#media-library-info-panel-asset .asset-header .asset-defaults-toggle {
  display: flex;
  margin-bottom: 15px;
}
#media-library-info-panel-asset .asset-header .asset-defaults-toggle .zf-plain-checkbox {
  margin: 0px;
  font-size: 14px;
  line-height: 14px;
  margin-top: -5px;
}
#media-library-info-panel-asset .asset-header .asset-defaults-toggle label {
  cursor: pointer;
  line-height: 12px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 2px;
  border-bottom: 1px dashed #6C6C6C;
}
#media-library-info-panel-asset .asset-header .asset-defaults-toggle .zf-tooltip-container {
  vertical-align: middle;
}
#media-library-info-panel-asset .asset-header .extra-actions {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: Lato;
  margin-bottom: 32px;
}
#media-library-info-panel-asset .asset-header .extra-actions .extra-label {
  cursor: pointer;
  font-size: 12px;
  line-height: 12px;
}
#media-library-info-panel-asset .asset-header .extra-actions .extra-label i {
  margin-right: 4px;
}
#media-library-info-panel-asset .asset-header .extra-actions .video-subtitles {
  display: flex;
  align-items: center;
}
#media-library-info-panel-asset .asset-header .extra-actions .video-subtitles:hover {
  color: #333333;
}
#media-library-info-panel-asset .asset-header .extra-actions .video-subtitles label {
  color: #333333;
  margin-bottom: 0;
  margin-right: 5px;
}
#media-library-info-panel-asset .asset-header .extra-actions .video-subtitles label:hover {
  color: #0572C5;
}
#media-library-info-panel-asset .asset-header .extra-actions > * {
  margin-right: 40px;
}
#media-library-info-panel-asset .asset-header .extra-actions > *:last-child {
  margin-right: 0px;
}
#media-library-info-panel-asset .action-buttons {
  margin: 20px;
  height: 35px;
  display: flex;
}
#media-library-info-panel-asset .action-buttons button,
#media-library-info-panel-asset .action-buttons a {
  font-size: 12px;
  vertical-align: middle;
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
  border-radius: 0;
  border: 1px solid #E1E3E6;
  border-left: none;
  border-radius: 0px;
}
#media-library-info-panel-asset .action-buttons button:first-child,
#media-library-info-panel-asset .action-buttons a:first-child {
  border-left: 1px solid #E1E3E6;
  border-radius: 2px 0 0 2px;
}
#media-library-info-panel-asset .action-buttons button:last-child,
#media-library-info-panel-asset .action-buttons a:last-child {
  border-radius: 0 2px 2px 0;
}
#media-library-info-panel-asset .action-buttons button.danger,
#media-library-info-panel-asset .action-buttons a.danger {
  color: #DB0000;
}
#media-library-info-panel-asset .asset-details .asset-label {
  margin-bottom: 4px;
  font-size: 10px;
}
#media-library-info-panel-asset .asset-details .asset-value {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 14px;
}
#media-library-info-panel-asset .asset-details .asset-value.loading-indicator {
  position: relative;
}
#media-library-info-panel-asset .asset-details .asset-value.loading-indicator .circular-spinner {
  position: absolute;
  top: 6px;
  left: 6px;
}
#media-library-info-panel-asset .asset-details {
  margin: 20px;
  display: flex;
}
#media-library-info-panel-asset .asset-details .asset-details-col {
  display: flex;
  flex-direction: column;
}
#media-library-info-panel-asset .asset-details .asset-details-col.left {
  width: 60%;
}
#media-library-info-panel-asset .asset-details .asset-details-col.right {
  width: 40%;
}
#media-library-info-panel-asset .asset-details #site-count a {
  color: black;
  text-decoration: underline;
}
