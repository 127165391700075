.btn-set-basic button {
  outline: none;
}
.btn-set-basic button.btn-basic:focus,
.btn-set-basic a.btn-basic:focus,
.btn-set-basic button.btn-basic.middle:focus,
.btn-set-basic a.btn-basic.middle:focus,
.btn-set-basic button.btn-basic.rightmost:focus,
.btn-set-basic a.btn-basic.rightmost:focus {
  border: 1px solid #5CA3FF;
}
.btn-set-basic button.btn-basic.leftmost,
.btn-set-basic a.btn-basic.leftmost {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 0;
}
.btn-set-basic button.btn-basic.middle,
.btn-set-basic a.btn-basic.middle {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-left: none;
}
.btn-set-basic button.btn-basic.rightmost,
.btn-set-basic a.btn-basic.rightmost {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
  border-left: none;
}
.btn-set-basic-by-type button {
  outline: none;
}
.btn-set-basic-by-type > button.btn-basic:focus,
.btn-set-basic-by-type > a.btn-basic:focus {
  border: 1px solid #5CA3FF;
}
.btn-set-basic-by-type > button.btn-basic:not(:first-child):not(:last-child),
.btn-set-basic-by-type > a.btn-basic:not(:first-child):not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-left-width: 0.5px;
  border-right-width: 0.5px;
}
.btn-set-basic-by-type > button.btn-basic:first-child,
.btn-set-basic-by-type > a.btn-basic:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 0;
  border-right-width: 0.5px;
}
.btn-set-basic-by-type > button.btn-basic:last-child,
.btn-set-basic-by-type > a.btn-basic:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
  border-left-width: 0.5px;
}
