.carousel-root .carousel .slider-wrapper {
  margin: 0;
  padding: 5px;
}
.carousel-root .carousel .slider {
  height: inherit;
}
.carousel-root {
  background-color: #111111;
}
.carousel-root.slideMasterHeight .carousel-slider {
  display: flex;
}
.carousel-root.slideMasterHeight .carousel-slider .slideshow-arrow {
  border: none;
  width: 40px;
  height: 40px;
  padding: 0;
  position: absolute;
  z-index: 2;
  top: calc((100% -  80px)/2);
  line-height: 40px;
  border-radius: 50%;
}
.carousel-root.slideMasterHeight .carousel-slider .slideshow-arrow .zf-app-modern-icon {
  font-size: 16px;
}
.carousel-root.slideMasterHeight .carousel-slider .slideshow-arrow .zf-app-modern-icon:before {
  vertical-align: top !important;
}
.carousel-root.slideMasterHeight .carousel-slider .slideshow-arrow .slidenav-arrow {
  font-size: 0;
  color: transparent;
}
.carousel-root.slideMasterHeight .carousel-slider .slideshow-arrow:hover {
  cursor: pointer;
  box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 0.5);
}
.carousel-root.slideMasterHeight .carousel-slider .slideshow-arrow.arrow-hidden {
  background: white;
  width: 100px;
  height: 100%;
  height: calc(100% -  66px);
  top: 0;
  border-radius: unset;
  transition: opacity 0.5s linear;
  opacity: 0;
}
.carousel-root.slideMasterHeight .carousel-slider .slideshow-arrow.arrow-hidden:hover {
  transition: opacity 0.5s linear;
  opacity: 0.75;
}
.carousel-root.slideMasterHeight .carousel-slider .control-arrow {
  background-color: white;
  width: 60px;
  position: absolute;
}
.carousel-root.slideMasterHeight .carousel-slider .control-arrow:hover {
  background-color: white;
  opacity: 0.8;
}
.carousel-root.slideMasterHeight .carousel-slider .slideshow-arrow-previous {
  left: 20px;
}
.carousel-root.slideMasterHeight .carousel-slider .slideshow-arrow-previous.arrow-hidden {
  left: 0;
}
.carousel-root.slideMasterHeight .carousel-slider .slideshow-arrow-next {
  right: 20px;
}
.carousel-root.slideMasterHeight .carousel-slider .slideshow-arrow-next.arrow-hidden {
  right: 0;
}
.carousel-root.slideMasterHeight .carousel-slider .carousel {
  position: absolute;
  bottom: 0;
}
.carousel-root .carousel .thumbs-wrapper {
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 66px;
}
.carousel-root .carousel .thumbs-wrapper .thumbs {
  display: flex;
}
.carousel-root .carousel .thumbs-wrapper .thumb {
  height: 56px;
  display: flex;
  cursor: pointer;
  min-width: 80px;
  border: 3px solid transparent;
  position: relative;
  align-items: center;
  justify-content: center;
}
.carousel-root .carousel .thumbs-wrapper .thumb.selected,
.carousel-root .carousel .thumbs-wrapper .thumb:hover {
  opacity: 1;
  border: 3px solid #4282D5;
}
.carousel-root .carousel .thumbs-wrapper .thumb.selected:focus-visible,
.carousel-root .carousel .thumbs-wrapper .thumb:hover:focus-visible {
  outline: 3px solid #4282D5;
  border: 3px solid #4282D5;
}
.carousel-root .carousel .thumbs-wrapper .thumb.selected:before,
.carousel-root .carousel .thumbs-wrapper .thumb:hover:before {
  content: '';
  background: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
}
.carousel-root .carousel .thumbs-wrapper .thumb:before {
  content: '';
  background: black;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.7;
}
.carousel-root .carousel .thumbs-wrapper .thumb .thumbnail-video-icon {
  position: absolute;
  z-index: 1;
  font-size: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.carousel-root .carousel .thumbs-wrapper .thumb .slide-spinner-container {
  background-color: transparent;
  height: 55px;
  position: absolute;
  width: 55px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.carousel-root .carousel .custom-slider {
  height: inherit;
}
.carousel-root .carousel .slide {
  height: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
}
.carousel-root .carousel .slide.selected {
  opacity: 1;
}
.carousel-root .carousel .slide .fullscreen-expand-button {
  position: absolute;
  cursor: pointer;
  font-size: 24px;
  top: 0px;
  left: 0px;
  opacity: 0.5;
}
.carousel-root .carousel .slide .fullscreen-expand-button:hover {
  opacity: 1;
}
.carousel-root .carousel .slide .slide-caption {
  height: 25%;
  display: flex;
  flex-direction: column;
  bottom: 66px;
  padding: 16px;
  width: 100%;
  z-index: 1;
}
.carousel-root .carousel .slide .slide-caption.bottom-placement {
  left: 0;
  right: 0;
  position: absolute;
  bottom: 0;
}
.carousel-root .carousel .slide .slide-caption.slide-spotlight-center {
  margin: 0 10px;
}
.carousel-root .carousel .slide .slide-caption.left-placement {
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 25%;
  min-width: 25%;
}
.carousel-root .carousel .slide .slide-caption.right-placement {
  right: 0;
  top: 0;
  height: 100%;
  width: 25%;
  min-width: 25%;
}
.carousel-root .carousel .slide .slide-caption.bottom-placement-full-height {
  left: 0;
  right: 0;
  bottom: 0;
}
.carousel-root .carousel .slide .slide-caption.left-placement-full-height {
  left: 0;
  right: 0;
  top: 0;
  height: auto;
  width: 33%;
  bottom: 0;
}
.carousel-root .carousel .slide .slide-caption.right-placement-full-height {
  right: 0;
  top: 0;
  height: auto;
  width: 33%;
  bottom: 0;
}
.carousel-root .carousel .slide .slide-caption.no-navigation {
  bottom: 0;
}
.carousel-root .carousel .slide .slide-caption .caption-contents-wrapper h1 {
  margin-left: unset;
}
.carousel-root .carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
}
.carousel-root .carousel .slide .spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel-root .carousel .slide .spinner-wrapper .slide-spinner-container {
  background-color: transparent;
  height: 200px;
  position: absolute;
  width: 200px;
}
.carousel-root .carousel .slide .spinner-wrapper .loading-message {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateX(-50%) translateX(-50%);
  -o-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  font-size: 16px;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  text-anchor: middle;
  color: #4282D5;
}
.carousel-root .carousel .control-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  margin: 0;
  min-height: 66px;
}
.carousel-root .carousel .control-dots .dot.selected {
  width: 15px;
  height: 15px;
}
.carousel-autoplay-controls {
  padding: 5px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.carousel-autoplay-controls .playpause-btn {
  color: #FFFFFF;
  font-size: 11px;
  letter-spacing: 0.03px;
  background-color: transparent;
  border: none;
}
.slide-wrapper-outline {
  display: flex;
  width: 100%;
  min-height: 100%;
}
.slide-wrapper-outline .slide-container {
  display: flex;
  flex-direction: row;
  order: 1;
  justify-content: center;
  align-items: center;
  position: relative;
}
.slide-wrapper-outline.slide-wrapper-outline-left .slide-caption {
  order: 0;
}
.slide-wrapper-outline.slide-wrapper-outline-left.is-full-screen-clicked-enabled {
  cursor: pointer;
}
.slide-wrapper-outline.slide-wrapper-outline-left.isMobileView iframe {
  object-fit: cover;
  margin: 0;
  width: 100%;
  height: 100%;
}
.slide-wrapper-outline.slide-wrapper-outline-right .slide-caption {
  order: 2;
}
.hidden-content {
  position: absolute;
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
}
.navigation-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 60px;
  margin-bottom: 5px;
}
