.grid-mobile-viewer .tile-wrapper {
  position: relative;
  display: block;
  margin-bottom: 2px;
}
.grid-mobile-viewer .tile-wrapper .tile-padding-wrapper {
  position: relative;
  border-radius: 0;
}
.grid-mobile-viewer .tile-wrapper .tile {
  position: relative;
  overflow: hidden;
  margin: auto;
}
.grid-mobile-viewer .tile-wrapper.type-pdf[pdf_icon="true"] .tile-image-wrapper,
.grid-mobile-viewer .tile-wrapper.type-other[pdf_icon="true"] .tile-image-wrapper {
  height: 80px;
  width: 60px;
  margin-left: -60px/2;
}
.grid-mobile-viewer .tile-wrapper.type-ppt[pdf_icon="true"] .tile-image-wrapper {
  height: 80px;
  width: 130px;
  margin-left: -130px/2;
}
.grid-mobile-viewer .tile-wrapper.type-pdf[pdf_icon="true"] .tile,
.grid-mobile-viewer .tile-wrapper.type-ppt[pdf_icon="true"] .tile,
.grid-mobile-viewer .tile-wrapper.type-other[pdf_icon="true"] .tile {
  min-height: 160px;
}
.grid-mobile-viewer .tile-wrapper.type-text .tile {
  overflow: hidden;
  min-height: 160px;
}
