.header-wrapper-base {
  padding: 0 12px;
  height: max-content;
  display: flex;
  align-items: center;
  margin: auto;
  position: relative;
  flex-direction: column;
  width: 84%;
  max-width: 1200px;
}
.header-wrapper-base.full-bleed {
  width: 100%;
  max-width: unset;
  padding: 0 24px;
  box-sizing: border-box;
}
.header-wrapper-base .header-separator {
  border-bottom: 1px solid #d7dcea;
  width: 100%;
  margin: 10px 0;
  height: 10px;
}
.header-wrapper-base .top-row {
  width: 100%;
  display: flex;
  align-items: center;
}
.header-wrapper-base .top-row .add-ons-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 12px;
}
.header-wrapper-base .two-line-header {
  height: 60px;
}
.header-wrapper-base .two-line-header .add-ons-wrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.header-wrapper-base .bottom-row {
  width: 100%;
  display: flex;
  position: relative;
}
.header-wrapper-base .bottom-row .force-right {
  position: absolute;
  right: 0;
}
.header-wrapper-base .header-title-component {
  margin-left: 12px;
}
.header-wrapper-base .header-title-component {
  margin: 0 12px;
}
.header-wrapper-base .add-ons {
  display: flex;
}
.header-wrapper-base .logo-right .header-thumbnail {
  margin-right: 0;
}
.header-wrapper-base .header-links-wrapper {
  width: 100%;
  display: flex;
}
.header-wrapper-base .header-links-wrapper .dasher-navigation-links {
  margin: 0;
  width: 100%;
  padding: 0;
}
.header-wrapper-base .header-links-wrapper .dasher-navigation-links .navigation-links-container {
  width: 100%;
  border: none;
  padding: 8px 0px;
}
.header-wrapper-base .header-links-wrapper .dasher-navigation-links .navigation-links-container > div:first-child {
  margin-left: 8px;
}
.header-wrapper-base .navigation-links-left .dasher-navigation-links {
  text-align: start;
}
.header-wrapper-base .navigation-links-left .dasher-navigation-links .submenu-links-container {
  padding: 12px 0;
  padding-left: 8px;
  text-align: left;
}
.header-wrapper-base .navigation-links-center .dasher-navigation-links {
  text-align: center;
}
.header-wrapper-base .navigation-links-center .dasher-navigation-links .submenu-links-container {
  padding: 12px 0;
  text-align: center;
}
.header-wrapper-base .navigation-links-right .dasher-navigation-links {
  margin-right: 8px;
}
.header-wrapper-base .navigation-links-right .dasher-navigation-links .navigation-links-container {
  text-align: right;
}
.header-wrapper-base .navigation-links-right .dasher-navigation-links .submenu-links-container {
  padding: 12px 0;
  text-align: right;
}
.header-wrapper-base .two-line-navigation-links-right {
  text-align: end;
}
.header-wrapper-base .two-line-navigation-links-right .dasher-navigation-links .submenu-links-container {
  padding: 12px;
}
.header-wrapper-base .two-line-navigation-links-right-with-search {
  text-align: end;
  width: 99%;
}
.header-wrapper-base .two-line-navigation-links-right-with-search .submenu-links-container {
  padding: 12px;
}
.header-wrapper-base .add-ons-wrapper {
  margin-left: auto;
  margin-top: 8px;
  position: absolute;
  right: 0;
}
.header-wrapper-base .add-ons-wrapper.add-ons-pull-left {
  position: absolute;
  left: 0;
}
.header-wrapper-base .width-override {
  position: absolute;
  left: 0;
  width: fit-content;
  margin-left: unset;
}
.header-wrapper-base .width-override-icon {
  margin-left: auto;
}
.fixed-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: inherit;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
}
.fixed-navigation .search-icon-container {
  margin-top: 8px;
}
.fixed-navigation.navigation-left-position .dasher-navigation-links {
  text-align: start;
}
.fixed-navigation.navigation-left-position .dasher-navigation-links .navigation-links-container {
  padding-left: 12px;
}
.fixed-navigation.navigation-left-position .dasher-navigation-links .submenu-links-container {
  padding-left: 12px;
}
.fixed-navigation.navigation-center-position .dasher-navigation-links {
  text-align: center;
}
.fixed-navigation.navigation-center-position .dasher-navigation-links .submenu-links-container {
  padding-left: 24px;
  padding-right: 24px;
}
.fixed-navigation.navigation-right-position .dasher-navigation-links {
  text-align: end;
}
.fixed-navigation.navigation-right-position .dasher-navigation-links .submenu-links-container {
  padding-right: 14px;
}
.fixed-navigation.navigation-right-position-with-search .dasher-navigation-links .navigation-links-container {
  padding-right: 14px;
}
.fixed-navigation.navigation-right-position-with-search .dasher-navigation-links .submenu-links-container {
  padding-right: 26px;
}
.fixed-navigation .dasher-navigation-links {
  width: 100%;
}
.fixed-navigation .dasher-navigation-links .navigation-links-container {
  padding: 8px 0;
  border: none;
}
.fixed-navigation .search-icon-container {
  position: absolute;
  right: 8px;
}
