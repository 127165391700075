.needs-refresh-modal-background {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100000000;
  background-color: rgba(0, 0, 0, 0.6);
}
.needs-refresh-modal {
  position: absolute;
  width: 400px;
  left: 50%;
  top: 40%;
  margin-left: -200px;
  margin-top: -150px;
  z-index: 10000000;
  font-family: sans-serif;
  font-weight: 400;
  font-size: 15px;
  box-sizing: border-box;
  text-align: center;
  background-color: white;
  border-radius: 2px;
  padding: 30px;
}
.needs-refresh-modal .refresh-button {
  margin: 25px;
  border-radius: 2px;
  padding: 10px;
  cursor: pointer;
}
.needs-refresh-modal .fyi-text {
  color: #999;
  font-style: italic;
}
