.modern-mobile-header-wrapper > div:nth-child(2) div .bm-burger-button {
  display: none;
}
.modern-mobile-header-wrapper > div:nth-child(2) .bm-menu-wrap .bm-item-list {
  padding: 0;
}
.modern-mobile-header-wrapper > div:nth-child(2) .bm-menu-wrap .bm-item-list .mobile-navigation-view .new-header-mobile-navigation-menu-list > ul {
  padding-left: 16px;
}
.modern-mobile-header-wrapper > div:nth-child(2) .bm-menu-wrap .bm-item-list .menu-item {
  font-size: 16px;
  line-height: 24px;
  display: block;
  padding: 0;
  padding-left: 0px;
  margin: 0;
}
.modern-mobile-header-wrapper > div:nth-child(2) .bm-menu-wrap .bm-item-list .menu-item.is-subsection > a {
  color: #555;
}
.modern-mobile-header-wrapper > div:nth-child(2) .bm-menu-wrap .bm-item-list .menu-item > a {
  padding: 8px 10px 10px 20px;
  color: #333;
  text-overflow: clip;
  white-space: normal;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.5);
  display: block;
  margin: 0;
  overflow: hidden;
  text-decoration: none;
}
.modern-mobile-header-wrapper .bm-cross-button {
  pointer-events: none;
  display: none;
}
.modern-mobile-header-wrapper .modern-mobile-header {
  display: flex;
  position: relative;
  align-items: center;
  padding: 0.5em 0.5em;
  justify-content: space-between;
  position: fixed;
  background-color: inherit;
  right: 0;
  top: 0;
  left: 0;
  z-index: 200;
}
.modern-mobile-header-wrapper .modern-mobile-header .modern-header-thumbnail {
  margin-right: 0;
}
.modern-mobile-header-wrapper .modern-mobile-header .nav-hamburger-open-icon {
  display: inline-block;
  padding: 10px;
}
.modern-mobile-header-wrapper .modern-mobile-header .nav-hamburger-open-icon .bar {
  margin: 3px;
  width: 25px;
  height: 3px;
  background: #000;
  border-radius: 1.5px;
}
.modern-mobile-header-wrapper .modern-mobile-header > .mobile-menu-opener {
  line-height: 0;
  border: none;
  padding: 0;
  background-color: transparent;
}
.modern-mobile-header-wrapper .modern-mobile-header.fixed-modern-header-spacer {
  position: relative;
  visibility: hidden;
}
.modern-mobile-header-wrapper .bm-burger-bars {
  display: none !important;
}
.modern-mobile-header-wrapper .bm-menu-wrap {
  background-color: white;
  top: 0;
}
.modern-mobile-header-wrapper .bm-menu-wrap .new-header-mobile-navigation-menu-list {
  padding: 0;
}
.modern-mobile-header-wrapper .bm-menu-wrap .new-header-mobile-navigation-menu-list > .menu-item {
  font-size: 16px;
  line-height: 24px;
  display: block;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.modern-mobile-header-wrapper .bm-menu-wrap .new-header-mobile-navigation-menu-list > .menu-item.in-page-search-start-menu-item {
  text-align: right;
  padding-right: 10px;
  padding-bottom: 10px;
}
.modern-mobile-header-wrapper .bm-menu-wrap .new-header-mobile-navigation-menu-list > .menu-item.in-page-search-start-menu-item .zfe-icon-search {
  display: inline-block;
}
.modern-mobile-header-wrapper .bm-menu-wrap .new-header-mobile-navigation-menu-list > .menu-item.selected {
  background-color: #ddd;
}
.modern-mobile-header-wrapper .bm-menu-wrap .new-header-mobile-navigation-menu-list > .menu-item > a {
  padding: 8px 10px 10px 20px;
  color: #23376b;
  text-overflow: clip;
  white-space: normal;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.5);
  display: block;
  margin: 0;
  overflow: hidden;
  text-decoration: none;
}
.modern-mobile-header-wrapper .bm-menu-wrap .new-header-mobile-navigation-menu-list > .menu-item > a:active {
  background-color: #ddd;
}
.modern-mobile-header-wrapper .bm-menu-wrap .new-header-mobile-navigation-menu-list > .menu-item.is-subsection {
  padding-left: 22px;
}
.modern-mobile-header-wrapper .bm-menu-wrap .new-header-mobile-navigation-menu-list > .menu-item.is-subsection > a {
  color: #555;
}
.modern-mobile-header-wrapper .bm-menu-wrap .bm-menu .bm-item-list {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
}
.modern-mobile-header-wrapper .bm-menu-wrap .bm-menu .bm-item-list .mobile-search-btn-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  align-items: center;
}
.modern-mobile-header-wrapper .bm-menu-wrap .bm-menu .bm-item-list .mobile-search-btn-wrapper .nav-hamburger-close-icon::before,
.modern-mobile-header-wrapper .bm-menu-wrap .bm-menu .bm-item-list .mobile-search-btn-wrapper .nav-hamburger-close-icon::after {
  content: '';
  display: block;
  margin-top: -3px;
  width: 20px;
  height: 3px;
  background: #000;
  border-radius: 1px;
}
.modern-mobile-header-wrapper .bm-menu-wrap .bm-menu .bm-item-list .mobile-search-btn-wrapper .nav-hamburger-close-icon::before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.modern-mobile-header-wrapper .bm-menu-wrap .bm-menu .bm-item-list .mobile-search-btn-wrapper .nav-hamburger-close-icon::after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.modern-mobile-header-wrapper .bm-menu-wrap .bm-menu .bm-item-list .mobile-search-btn-wrapper > .mobile-menu-closer {
  background-color: transparent;
  border: none;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modern-mobile-header-wrapper .bm-menu-wrap .bm-menu .bm-item-list .modern-header-social-links {
  justify-content: end;
}
.modern-mobile-header-wrapper .bm-menu-wrap .bm-menu .bm-item {
  display: none !important;
}
.modern-mobile-header-wrapper .bm-menu-wrap .bm-menu .bm-item.in-page-search-start-menu-item__container,
.modern-mobile-header-wrapper .bm-menu-wrap .bm-menu .bm-item.mobile-navigation-view {
  display: block !important;
  position: relative;
}
.modern-mobile-header-wrapper .bm-menu-wrap .bm-menu .bm-item.mobile-navigation-view {
  width: 100%;
}
