.subpage-is-fullpage .section-type-slideshow {
  height: 100%;
}
.subpage-is-fullpage .slideshow-wrapper .galleria-container {
  height: 100%;
}
.section-type-slideshow {
  position: relative;
}
.section-type-slideshow .slick-slider,
.section-type-slideshow .slick-list,
.section-type-slideshow .slick-track {
  height: 100%;
}
.section-type-slideshow .slick-slide {
  position: relative;
}
.section-type-slideshow .slick-slide img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateX(-50%) translateX(-50%);
  -o-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  max-width: 100%;
  max-height: 100%;
}
.slideshow-wrapper {
  overflow: hidden;
  margin: 0 auto;
  max-width: 100%;
  height: 100%;
}
.slideshow-wrapper .galleria-container {
  position: relative;
  height: 500px;
}
.slideshow-wrapper .galleria-stage {
  position: absolute;
  padding: 10px 10px 0px 10px;
  top: 0;
  bottom: 80px;
  left: 0;
  right: 0;
  overflow: hidden;
}
.slideshow-wrapper .galleria-info {
  display: none;
  bottom: 80px;
  left: 0px;
  right: 0px;
  z-index: 2;
  position: absolute;
  overflow: auto;
}
.slideshow-wrapper .galleria-info .galleria-info-text {
  height: 100%;
}
.slideshow-wrapper.has-caption .galleria-info {
  display: block;
}
.slideshow-wrapper.video-playing .galleria-info {
  display: none;
}
.subpage-is-fullpage .slideshow-wrapper {
  height: 100%;
  width: 100% !important;
  min-height: 300px;
}
.subpage-is-fullpage .slideshow-wrapper .galleria-container {
  height: 100%;
}
@media (max-aspect-ratio: 9/10) {
  .slideshow-wrapper.has-caption .galleria-stage {
    max-height: 100%;
    height: auto !important;
    bottom: 35% !important;
  }
  .slideshow-wrapper.has-caption .galleria-info {
    top: 65% !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
  }
}
.galleria-image-wrapper {
  height: 100%;
  position: relative;
}
.galleria-image-wrapper img.image-content,
.galleria-image-wrapper .video-js {
  background-color: transparent;
  left: 50%;
  top: 50%;
  position: relative;
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.galleria-image-wrapper img.image-content.processing,
.galleria-image-wrapper .video-js.processing {
  visibility: hidden;
}
.galleria-image-wrapper .vjs-poster {
  background-color: transparent;
}
.slideshow-item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: none;
}
.galleria-thumbnails-container {
  margin: 0 auto;
  height: 80px;
  bottom: 0;
  box-sizing: border-box;
  position: absolute;
  left: 10px;
  right: 10px;
  padding: 20px;
  z-index: 2;
}
.galleria-thumbnails-list {
  overflow: hidden;
  position: relative;
  padding: 0;
}
.galleria-thumbnails-list .galleria-thumbnails {
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}
.galleria-thumbnails-list .galleria-thumbnails .galleria-image {
  position: relative;
  visibility: visible;
  display: inline-block;
}
.galleria-thumbnails-list .galleria-thumbnails .galleria-image div.thumbnail,
.galleria-thumbnails-list .galleria-thumbnails .galleria-image img.thumbnail {
  height: 40px;
  width: 50px;
  margin-right: 4px;
  cursor: pointer;
  display: block;
  opacity: 0.5;
  line-height: 40px;
  text-align: center;
  -ms-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}
.galleria-thumbnails-list .galleria-thumbnails .galleria-image.active img.thumbnail {
  opacity: 1;
}
.galleria-thumbnails-list .galleria-thumbnails .galleria-image:hover img.thumbnail {
  opacity: 0.9;
}
.galleria-thumbnails-list .galleria-thumbnails .galleria-image div.thumbnail i {
  display: inline-block;
}
.galleria-thumbnails-list .galleria-thumbnails .galleria-image img.video-icon {
  cursor: pointer;
  display: block !important;
  position: absolute;
  height: 1em;
  width: 1em;
  top: 50%;
  margin-top: -0.5em;
  left: 50%;
  margin-left: -0.5em;
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
  z-index: 20;
}
.galleria-arrow {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #FFFFFF;
  font-size: 40px;
  cursor: pointer;
  z-index: 3;
}
.galleria-image-nav-left {
  left: 20px;
}
.galleria-image-nav-right {
  right: 20px;
}
.galleria-thumb-nav-left,
.galleria-thumb-nav-right {
  background-image: url(https://d3jozdooylvm2p.cloudfront.net/images/third_party/galleria_themes/classic-map.png);
  background-repeat: no-repeat;
}
.galleria-thumb-nav-left,
.galleria-thumb-nav-right {
  cursor: pointer;
  background-position: -495px 5px;
  position: absolute;
  left: 0;
  top: 20px;
  height: 60px;
  width: 23px;
  z-index: 3;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.galleria-thumb-nav-right {
  background-position: -578px 5px;
  border-right: none;
  right: 0;
  left: auto;
}
.galleria-thumbnails-container .disabled {
  opacity: 0.2;
  filter: alpha(opacity=20);
  cursor: default;
}
.galleria-thumb-nav-left:hover,
.galleria-thumb-nav-right:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.galleria-thumb-nav-left:active,
.galleria-thumb-nav-right:active {
  opacity: 1;
  filter: alpha(opacity=100);
}
.galleria-thumbnails-container .disabled:hover {
  opacity: 0.2;
  filter: alpha(opacity=20);
  background-color: transparent;
}
