.copy-text-input-container {
  white-space: nowrap;
}
.copy-text-input-container input[type="text"] {
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #333;
  background-color: #FFF;
  border: 1px solid #ddd;
  box-shadow: none;
  display: inline-block;
  padding: 8px 10px;
  margin-bottom: 0px;
  box-sizing: border-box;
  height: 36px;
  border-radius: 2px 0 0 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 300px;
}
.copy-text-input-container input[type="text"]:focus {
  outline: none;
  border: 1px solid #60a5fc;
}
.copy-text-input-container button.copy-to-clipboard {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 36px;
  margin-left: 0;
  font-size: 14px;
}
