.media-library-modal {
  max-width: 1020px;
  width: 90%;
  height: 80%;
  position: relative;
}
.media-library-modal .close-modal-wrapper {
  position: absolute;
  top: 6px;
  right: 12px;
  z-index: 500;
}
.media-library-modal .close-modal-wrapper button.close {
  font-size: 21px;
}
.media-library-modal.modal {
  height: 82% !important;
}
@media (min-width: 1200px) {
  .media-library-modal.modal {
    width: 1020px !important;
    margin-left: -510px !important;
  }
}
.media-library-modal .select-assets-button {
  float: right;
}
.media-library-modal .selected-count {
  color: #00648B;
  display: inline-block;
  float: right;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin-right: 25px;
  margin-top: 7px;
}
.media-library-modal .media-library-action-bar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.media-library-modal .media-library-folder-zone .file-items {
  bottom: 0;
}
.media-library-modal .media-library-selector-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.media-library-modal .media-library-selector-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #DDD;
  box-sizing: border-box;
  height: 70px;
  padding: 16px 40px;
  z-index: 10;
  background-color: white;
}
.media-library-modal.is-selecting-multiple .media-library-selector-wrapper {
  bottom: 40px;
}
