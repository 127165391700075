/* OVERLAY */
.overlay {
  bottom: 0;
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}
.overlay-box-wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1007;
}
.overlay-background {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0.9;
  filter: alpha(opacity=90);
  z-index: 1005;
}
.overlay-asset-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  width: 75%;
  z-index: 1010;
}
.overlay-asset-wrapper.full-width-override {
  width: 100%;
}
.overlay.type-pdf .overlay-asset-wrapper,
.overlay.type-ppt .overlay-asset-wrapper,
.overlay.no-caption .overlay-asset-wrapper {
  width: 100%;
  bottom: 0;
  top: 0;
  left: 0;
}
.pdf-overlay-notice,
.other-overlay-notice,
.ppt-overlay-notice {
  color: #FFF;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5em;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: -webkit-flex;
  -webkit-flex-align: center;
  -webkit-align-items: center;
}
.pdf-overlay-notice .downloadable-asset-title,
.other-overlay-notice .downloadable-asset-title,
.ppt-overlay-notice .downloadable-asset-title {
  max-width: 100%;
  word-wrap: break-word;
}
.pdf-overlay-notice .downloadable-asset-wrapper,
.other-overlay-notice .downloadable-asset-wrapper,
.ppt-overlay-notice .downloadable-asset-wrapper {
  cursor: pointer;
  width: 100%;
}
.pdf-overlay-notice > div img,
.other-overlay-notice > div img,
.ppt-overlay-notice > div img {
  display: block;
  margin: auto;
  width: 60px;
  height: 80px;
}
.pdf-overlay-notice > div img.ppt-image,
.other-overlay-notice > div img.ppt-image,
.ppt-overlay-notice > div img.ppt-image {
  width: 120px;
}
.pdf-overlay-notice > div .fyi-text,
.other-overlay-notice > div .fyi-text,
.ppt-overlay-notice > div .fyi-text {
  font-size: 0.5em;
  margin-top: 1em;
}
.pdf-iframe,
.html-package-iframe,
.custom-embed-iframe,
.ppt-iframe {
  border: 0;
  height: 100%;
  margin: 0;
  width: 100%;
}
.close-overlay-box {
  float: right;
  height: 40px;
  width: 40px;
}
.close-overlay-icon {
  cursor: pointer;
  font-size: 25px;
  margin-top: 20px;
  color: white;
  line-height: 60px;
}
.overlay-asset-viewport {
  z-index: 1010;
  bottom: 10%;
  left: 10%;
  margin: auto;
  overflow: hidden;
  position: absolute;
  right: 10%;
  text-align: center;
  top: 10%;
}
.overlay-asset-viewport.show-overflow {
  background: none;
}
.overlay-asset-viewport.ppt-loading {
  background-color: #FFF;
}
.overlay-asset-viewport.ppt-loading + .overlay-asset-loading {
  z-index: 1100;
}
.overlay-asset-loading {
  background-color: #FFF;
  border-radius: 3em;
  height: 2em;
  left: 50%;
  margin-left: -1em;
  margin-top: -1em;
  opacity: 0.65;
  padding: 0.7em;
  position: absolute;
  top: 50%;
  width: 2em;
  z-index: 1000;
}
.overlay-asset-loading.hide {
  display: none;
}
.overlay-asset-loading img {
  width: 100%;
}
.overlay-asset-viewport.show-overflow {
  overflow: auto;
}
.overlay-asset {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0px;
  margin-top: 0px;
}
.overlay-caption-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 25%;
  z-index: 1010;
  overflow: auto;
}
.overlay.type-pdf .overlay-caption-wrapper,
.overlay.type-ppt .overlay-caption-wrapper,
.overlay.no-caption .overlay-caption-wrapper {
  display: none;
}
.overlay-asset-title {
  border-bottom: 1px solid #ededed;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.65em;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 1.4em;
  padding: 10%;
  text-transform: uppercase;
}
.overlay-caption-content {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.75em;
  line-height: 1.5em;
  padding: 10%;
}
.ppt-iframe-header {
  background: #000;
  color: #FFF;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 35px;
  font-size: 14px;
  padding: 0 20px;
  line-height: 35px;
}
.ppt-iframe-header .ppt-iframe-header-download-link {
  color: #EEE;
  font-weight: 700;
  display: inline-block;
  float: right;
  text-decoration: none;
}
.ppt-iframe-header .ppt-iframe-header-download-link:hover {
  color: #FFF;
}
/* MOBILE OVERLAY */
.mobile-overlay .overlay-asset-viewport {
  top: 60px;
}
/* MEDIA QUERIES */
@media all and (min-width: 1320px) {
  .overlay-caption-wrapper {
    width: 330px;
  }
  .overlay-asset-wrapper {
    right: 330px;
    width: auto;
  }
}
body.bottom-captions .outer-tiles-wrapper {
  width: 100%;
}
body.bottom-captions .overlay-caption-wrapper {
  border-left: none;
  left: 0;
  height: 40%;
  top: auto;
  position: absolute;
  width: 100%;
}
body.bottom-captions .overlay .overlay-asset-wrapper {
  bottom: 40%;
  position: absolute;
  width: 100%;
}
body.bottom-captions .overlay.no-caption .overlay-asset-wrapper,
body.bottom-captions .overlay.type-pdf .overlay-asset-wrapper,
body.bottom-captions .overlay.type-ppt .overlay-asset-wrapper {
  bottom: 0;
}
body.bottom-captions .overlay .overlay-asset-title {
  border-bottom: none;
  padding-top: 2em;
}
body.bottom-captions .overlay .overlay-caption-content {
  padding-top: 0.5em;
  margin-bottom: 2em;
  line-height: 1.4em;
}
