.modern-modal-with-header {
  font-family: "Lato", Helvetica, Arial, sans-serif;
  text-align: left;
  width: 500px;
}
.modern-modal-with-header.is-expanded {
  width: 700px;
}
.modern-modal-with-header .simple-modal-header {
  background-color: #FFFFFF;
  height: 64px;
  display: flex;
  align-items: center;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: none !important;
}
.modern-modal-with-header .simple-modal-header .title-container {
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.modern-modal-with-header .simple-modal-with-header-body {
  padding-top: 20px !important;
  padding-bottom: 0 !important;
}
.modern-modal-with-header .simple-modal-with-header-body h1 {
  font-size: 18px;
  padding: 0;
  margin-bottom: 10px;
  line-height: 1;
  text-align: center;
}
.modern-modal-with-header .simple-modal-with-header-body h2 {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.01em;
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 14px;
}
.modern-modal-with-header .simple-modal-with-header-body p {
  font-size: 12px;
  margin-bottom: 20px;
}
.modern-modal-with-header .simple-modal-with-header-body .modern-modal-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 11px;
}
.modern-modal-with-header .simple-modal-with-header-body .modern-modal-actions .right-btn-action {
  margin: 0;
  padding: 7px 24px;
  font-size: 12px;
  border-radius: 2px;
  font-weight: 600;
  line-height: 12px;
  width: auto;
}
.modern-modal-with-header .simple-modal-with-header-body .modern-modal-actions .left-btn-action {
  color: #262626;
  font-weight: 600;
  font-size: 12px;
  text-decoration: underline;
  padding: 0;
}
.modern-modal-with-header .simple-modal-with-header-body .modern-modal-actions .left-btn-action.disabled {
  color: rgba(38, 38, 38, 0.3);
}
.modern-modal-with-header .simple-modal-with-header-body .modern-modal-actions .step-indicator {
  display: flex;
  align-items: center;
}
.modern-modal-with-header .simple-modal-with-header-body .modern-modal-actions .step-indicator:last-child {
  margin-right: 0;
}
.modern-modal-with-header .simple-modal-with-header-body .modern-modal-actions .step-indicator .step-indicator {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: #C4C4C4;
}
.modern-modal-with-header .simple-modal-with-header-body .modern-modal-actions .step-indicator .step-indicator.is-step-highlighted {
  background-color: #4D82D5;
}
.modern-modal-with-header .simple-modal-with-header-body .modern-modal-actions .step-indicator .step-indicator.is-current-step {
  border-radius: 12px;
  height: 8px;
  width: 21px;
}
.modern-modal-with-header.has-subtitle .simple-modal-header {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: normal;
  padding: 20px !important;
}
.modern-modal-with-header.has-subtitle .simple-modal-header .title-container {
  margin-bottom: 4px;
}
.modern-modal-with-header.has-subtitle .simple-modal-header .subtitle-container {
  max-width: 260px;
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #3D3E43;
}
.modern-modal-with-header.has-subtitle .simple-modal-with-header-body {
  padding: 20px 20px 0 20px;
}
