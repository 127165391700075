.zf-react-toggle {
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
.zf-react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.zf-react-toggle--disabled {
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  cursor: not-allowed;
}
.zf-react-toggle-track {
  width: 40px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  border: 1px solid #ddd;
  background-color: #F2EEEE;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.zf-react-toggle:hover .zf-react-toggle-track {
  background-color: #DDDDDD;
}
.zf-react-toggle--checked .zf-react-toggle-track {
  background-color: #60a5fc;
  border: 1px solid #60a5fc;
}
.zf-react-toggle.zf-react-toggle--checked:hover .zf-react-toggle-track {
  background-color: #4797fc;
}
.zf-react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.zf-react-toggle--checked .zf-react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.zf-react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.zf-react-toggle--checked .zf-react-toggle-track-x {
  opacity: 0;
}
.zf-react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 25px;
  height: 25px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.08);
  border: 1px solid #ddd;
  border-radius: 50%;
  background-color: #fff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.zf-react-toggle--checked .zf-react-toggle-thumb {
  left: 17px;
  border-color: #60a5fc;
}
