.zf-plain-checkbox,
label.zf-checkbox {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 12px;
  margin-bottom: 10px;
  opacity: 0.9;
}
.zf-plain-checkbox.disabled,
label.zf-checkbox.disabled {
  cursor: default;
  opacity: 0.3;
}
.zf-plain-checkbox input[type="checkbox"],
label.zf-checkbox input[type="checkbox"] {
  position: absolute;
  width: 0;
  height: 0;
  visibility: hidden;
}
.zf-plain-checkbox .zf-app-modern-icon,
label.zf-checkbox .zf-app-modern-icon {
  margin-right: 7px;
}
.zf-plain-checkbox .zfam-square,
label.zf-checkbox .zfam-square {
  color: #DDD;
}
.zf-plain-checkbox .zfam-check-square,
label.zf-checkbox .zfam-check-square,
.zf-plain-checkbox .zfam-check-square-partial,
label.zf-checkbox .zfam-check-square-partial {
  color: #60a5fc;
}
.zf-plain-checkbox .zf-tooltip-container,
label.zf-checkbox .zf-tooltip-container {
  margin-left: 5px;
}
.zf-plain-checkbox {
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  border: none;
}
.zf-plain-checkbox .zf-app-modern-icon {
  margin-right: 0px;
}
