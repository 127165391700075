.page-and-template-status {
  display: inline-block;
}
.page-and-template-status .status-color {
  display: inline-block;
  vertical-align: top;
  margin-right: 1px;
  font-size: 18px;
  position: relative;
  color: #FC5343;
}
.page-and-template-status.is-active .status-color {
  color: #008211;
}
.page-and-template-status .status-message {
  display: inline;
  color: #757575;
  font-size: 11px;
}
.page-and-template-status .status-message .requires-attention-icon {
  color: #AA6702;
  margin-left: 5px;
}
.page-and-template-status .has-primary-status-icon .requires-attention-icon {
  margin-left: 5px;
}
.page-and-template-status .redirected-icon {
  color: #79b3fc;
  font-size: inherit;
  font-size: 11.5px;
  top: 0px;
  margin-right: 2px;
}
.page-and-template-status .archived-icon {
  color: #FC5343;
  font-size: inherit;
  font-size: 11.5px;
  vertical-align: middle;
  margin-right: 2px;
  top: 0px;
}
.page-and-template-status a.unpub-link {
  color: inherit;
}
.page-and-template-status a.unpub-link:hover {
  text-decoration: underline;
}
