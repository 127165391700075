.sites {
  margin-top: 20px;
}
.sites .site {
  cursor: pointer;
  padding: 0px 24px;
  margin-bottom: 24px;
  display: flex;
  height: 54px;
}
.sites .site i {
  width: 30px;
  height: 100%;
}
.sites .site > * {
  margin-right: 24px;
}
.sites .site > *:last-child {
  margin-right: 0px;
}
.sites .site .site-selected {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sites .site .site-selected .zf-plain-checkbox {
  font-size: 14px;
}
.sites .site .site-details {
  flex-grow: 1;
  display: flex;
}
.sites .site .site-details > * {
  margin-right: 24px;
}
.sites .site .site-details > *:last-child {
  margin-right: 0px;
}
.sites .site .site-details .site-image .page-preview-thumbnail {
  margin: 0px;
  box-shadow: none;
}
.sites .site .site-details .site-image .page-preview-thumbnail i.zfam-spin {
  display: none;
}
.sites .site .site-details .site-details-content {
  flex-grow: 1;
}
.sites .site .site-details .site-details-content .site-name {
  line-height: 14px;
}
.sites .site .site-details .site-details-content .site-uses {
  line-height: 12px;
  font-size: 10px;
  color: #666666;
  margin-top: 8px;
}
.sites .site .site-details .site-details-content .site-status {
  margin-top: 4px;
  line-height: 14px;
  height: 14px;
}
