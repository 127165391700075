.free-floating-playlist-item {
  border-bottom: 1px solid #000;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
}
.free-floating-playlist-item.selected .video-title-section {
  border-left: 3px solid #26D6FF;
}
.free-floating-playlist-item.selected .video-caption {
  display: block;
  padding: 1.5em 1.6em 1.6em 1.6em;
}
.free-floating-playlist-item.selected .video-caption p {
  font-size: 1em;
  padding: 0px;
}
.free-floating-playlist-item.selected .now-playing {
  display: inline;
}
.free-floating-playlist-item.loading i.video-loading-icon {
  color: #FFFFFF;
  display: inline-block;
  float: left;
  margin-right: 0.8em;
  box-sizing: border-box;
  margin-bottom: 0.4em;
}
.free-floating-playlist-item .now-playing {
  color: #26D6FF;
  font-family: "Lato", Arial, sans-serif;
  font-size: 0.7em;
  font-weight: 600;
  display: none;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.free-floating-playlist-item .clear {
  clear: both;
}
.free-floating-playlist-item .video-title-section {
  background-color: #383838;
  padding: 0.6em 1em 0.6em 1em;
}
.free-floating-playlist-item img.video-thumbnail {
  float: left;
  width: 16%;
  min-width: 55px;
  margin-right: 0.8em;
  box-sizing: border-box;
  margin-bottom: 0.4em;
}
.free-floating-playlist-item .video-title {
  color: #DDD;
  font-family: 'Arial', 'Helvetica', sans-serif;
  font-size: 0.85em;
}
.free-floating-playlist-item .video-caption {
  display: inline;
  color: #999;
  display: none;
  font-family: 'Arial', 'Helvetica', sans-serif;
  font-size: 0.8em;
  background-color: #222;
}
.video-player-wrapper {
  background-color: #000000;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 72%;
}
.section-type-playlist {
  position: relative;
  margin: 0 auto;
}
.section-type-playlist.playlist-mobile-viewer .video-player-wrapper {
  bottom: 35%;
  width: 100%;
}
.section-type-playlist.playlist-mobile-viewer .playlist-info-wrapper {
  top: 65%;
  width: 100%;
}
.section-type-playlist .playlist-wrapper {
  position: relative;
  margin: 0 auto;
  height: 500px;
}
.section-type-playlist .playlist-info-wrapper {
  background-color: #383838;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 28%;
  min-width: 170px;
  overflow: auto;
}
.section-type-playlist .playlist-item {
  border-bottom: 1px solid #000;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
}
.section-type-playlist .playlist-item.selected .video-title-section {
  border-left: 3px solid #26D6FF;
}
.section-type-playlist .playlist-item.selected .video-caption {
  display: block;
  padding: 1.5em 1.6em 1.6em 1.6em;
}
.section-type-playlist .playlist-item.selected .video-caption p {
  font-size: 1em;
  padding: 0px;
}
.section-type-playlist .playlist-item.selected .now-playing {
  display: inline;
}
.section-type-playlist .playlist-item.loading i.video-loading-icon {
  color: #FFFFFF;
  display: inline-block;
  float: left;
  margin-right: 0.8em;
  box-sizing: border-box;
  margin-bottom: 0.4em;
}
.section-type-playlist .playlist-item .now-playing {
  color: #26D6FF;
  font-family: "Lato", Arial, sans-serif;
  font-size: 0.7em;
  font-weight: 600;
  display: none;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.section-type-playlist .playlist-item .clear {
  clear: both;
}
.section-type-playlist .playlist-item .video-title-section {
  background-color: #383838;
  padding: 0.6em 1em 0.6em 1em;
}
.section-type-playlist .playlist-item img.video-thumbnail {
  float: left;
  width: 16%;
  min-width: 55px;
  margin-right: 0.8em;
  box-sizing: border-box;
  margin-bottom: 0.4em;
}
.section-type-playlist .playlist-item .video-title {
  color: #DDD;
  font-family: 'Arial', 'Helvetica', sans-serif;
  font-size: 0.85em;
}
.section-type-playlist .playlist-item .video-caption {
  display: inline;
  color: #999;
  display: none;
  font-family: 'Arial', 'Helvetica', sans-serif;
  font-size: 0.8em;
  background-color: #222;
}
.section-type-playlist .video-player {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.subpage-is-fullpage .section-type-playlist {
  height: 100%;
}
.subpage-is-fullpage .section-type-playlist .playlist-wrapper {
  min-height: 300px;
}
.subpage-is-fullpage .playlist-wrapper {
  width: 100%;
  max-width: 100%;
  height: 100%;
}
.subpage-is-fullpage .playlist-wrapper > div {
  height: 100%;
}
.subpage-is-fullpage .playlist-wrapper .playlist-wrapper-inner {
  width: 100% !important;
  height: 100%;
}
