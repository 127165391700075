.breadcrumbs-wrapper {
  background-color: #F9F9F9;
}
.breadcrumbs-wrapper .media-library-breadcrumbs .folder-breadcrumb {
  color: #00BFF1;
  float: left;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 12px;
  vertical-align: middle;
  cursor: pointer;
}
.breadcrumbs-wrapper .media-library-breadcrumbs .folder-breadcrumb.current-folder {
  color: #444;
  cursor: default;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}
.breadcrumbs-wrapper .media-library-breadcrumbs .folder-breadcrumb.current-folder:hover {
  text-decoration: none;
}
.breadcrumbs-wrapper .media-library-breadcrumbs .folder-breadcrumb:hover {
  text-decoration: underline;
}
.breadcrumbs-wrapper .media-library-breadcrumbs .folder-breadcrumb:first-child {
  border-left: none;
}
.breadcrumbs-wrapper .media-library-breadcrumbs .carrot-crumb {
  color: #00BFF1;
  float: left;
  font-size: 10px;
  padding-top: 7px;
}
.breadcrumbs-wrapper .sort-by-wrapper {
  float: right;
  position: relative;
}
.breadcrumbs-wrapper .sort-by-button {
  color: #555;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  margin-top: 2px;
}
.breadcrumbs-wrapper .sort-by-button .sort-selected {
  font-weight: bold;
  margin-left: 2px;
  margin-right: 6px;
}
.breadcrumbs-wrapper .sort-by-button .fa {
  color: #999;
  font-size: 14px;
  line-height: 14px;
  padding-top: 1px;
  vertical-align: top;
}
.breadcrumbs-wrapper .sort-by-button:hover .sort-selected {
  color: #333;
}
.breadcrumbs-wrapper .sort-by-button:hover .fa {
  color: #666;
}
.breadcrumbs-wrapper .sort-by-dropdown {
  position: absolute;
  top: 100%;
  left: 8px;
  background-color: #FFF;
  border: none;
  border-radius: 2px;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
  display: none;
  margin: 4px 0 0;
  text-align: center;
  padding: 7px 0 9px 0;
}
.breadcrumbs-wrapper .sort-by-dropdown .filter-option,
.breadcrumbs-wrapper .sort-by-dropdown .sort-option {
  box-sizing: border-box;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  line-height: 18px;
  padding: 6px 0px;
  text-align: center;
  width: 160px;
}
.breadcrumbs-wrapper .sort-by-dropdown .filter-option:hover,
.breadcrumbs-wrapper .sort-by-dropdown .sort-option:hover,
.breadcrumbs-wrapper .sort-by-dropdown .filter-option[toggled="true"],
.breadcrumbs-wrapper .sort-by-dropdown .sort-option[toggled="true"] {
  color: #FDAD00;
}
.breadcrumbs-wrapper .asset-display-toggle {
  color: #CCC;
  cursor: pointer;
  float: right;
  margin-left: 10px;
  position: relative;
}
.breadcrumbs-wrapper .asset-display-toggle .toggle-btn {
  display: inline-block;
  font-size: 18px;
  margin-left: 10px;
}
.breadcrumbs-wrapper .asset-display-toggle .toggle-btn.active,
.breadcrumbs-wrapper .asset-display-toggle .toggle-btn:hover {
  color: #000;
}
.breadcrumbs-wrapper .breadcrumbs-dropdown-wrapper {
  color: #0092CB;
  float: left;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 12px;
  vertical-align: middle;
  cursor: pointer;
}
.breadcrumbs-wrapper .breadcrumbs-dropdown-wrapper:hover {
  text-decoration: underline;
}
.breadcrumbs-wrapper .breadcrumbs-dropdown {
  position: absolute;
  top: 50px;
  left: 5px;
  background-color: #FFF;
  border: none;
  border-radius: 2px;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
  color: #333;
  display: none;
  margin: 4px 0 0;
  text-align: center;
  padding: 7px 0 9px 0;
}
.breadcrumbs-wrapper .breadcrumbs-dropdown .breadcrumbs-option {
  box-sizing: border-box;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  line-height: 18px;
  padding: 6px 0px;
  text-align: center;
  width: 160px;
}
.breadcrumbs-wrapper .breadcrumbs-dropdown .breadcrumbs-option:hover {
  color: #FDAD00;
}
