.menu-item-editor .cancel-link {
  text-align: center;
  margin-top: 10px;
  display: inline-block;
  width: 90%;
  color: white;
  font-size: 0.85em;
}
.menu-item-editor label {
  margin-bottom: 15px;
  font-size: 12px;
  cursor: pointer;
  display: table;
}
.menu-item-editor .input-container:last-of-type {
  margin-bottom: 8px;
}
.menu-item-editor .deleter-container {
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #111318;
  text-align: center;
  color: red;
}
.menu-item-editor .deleter-container a.basic-link {
  color: #ff1919;
  font-size: 12px;
}
.menu-item-editor .deleter-container i {
  text-decoration: none;
}
