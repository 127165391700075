.error-boundary {
  background-color: #f7f9fa;
  border: 1px solid #888;
  color: #DE363B;
  padding: 15px;
}
.error-boundary h2 {
  font-size: 15px;
}
.error-boundary h3 {
  font-size: 13px;
  font-weight: bold;
}
.error-boundary p {
  font-size: 12px;
}
.error-boundary textarea {
  color: #DE363B;
  font-size: 10px;
  font-family: Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New;
  background-color: #f7f9fa;
  resize: none;
  width: 100%;
  line-height: 1.3;
  box-sizing: border-box;
}
