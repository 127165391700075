.zf-carousel-viewport {
  overflow: hidden;
  width: 100%;
}
.zf-carousel-viewport .zf-carousel-slides {
  height: 100%;
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  transition: transform 500ms ease 0s;
}
.zf-carousel-viewport .zf-carousel-slides::-webkit-scrollbar {
  display: none;
}
.zf-carousel-viewport .zf-carousel-slide {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  scroll-snap-align: start;
  transform: scale(1);
  transform-origin: center center;
  transition: transform 0.5s;
  width: 100%;
}
.zf-carousel-viewport .zf-carousel-slide.is-hidden {
  visibility: hidden;
}
.zf-carousel-viewport .zf-carousel-slide img {
  height: 100%;
  width: auto;
  max-width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .zf-carousel-slides {
    scroll-behavior: auto;
    transition: none;
  }
}
