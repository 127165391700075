.consent-modal {
  text-align: left;
  box-sizing: content-box;
  background-color: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  color: #000000;
  padding: 24px !important;
  font-size: 12px;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  max-height: 400px;
  overflow-y: scroll;
  width: 360px;
  z-index: 10;
}
.consent-modal .simple-modal-header {
  padding: 0 !important;
  border-bottom: none !important;
}
.consent-modal .simple-modal-header .title-container {
  font-size: 24px !important;
  font-weight: normal !important;
  color: #000000;
  font-family: "Lato", Helvetica, Arial, sans-serif !important;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 10px;
}
.consent-modal .simple-modal-header .close-container {
  cursor: pointer;
  color: #000000;
  font-size: 16px;
  position: absolute;
  top: 0px !important;
  right: 0px !important;
}
.consent-modal .simple-modal-with-header-body {
  background-color: #FFFFFF !important;
  padding: 0 !important;
}
.consent-modal h1,
.consent-modal h2,
.consent-modal h3 {
  color: #000000;
  font-family: "Lato", Helvetica, Arial, sans-serif;
}
.consent-modal h1 {
  font-weight: normal;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 10px;
}
.consent-modal h2 {
  font-size: 15px;
  margin-top: 18px;
}
.consent-modal h3 {
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 5px;
}
.consent-modal .consent-copy p {
  line-height: 1.5;
}
.consent-modal .consent-copy p + p {
  margin-top: 10px;
}
.consent-modal .consent-copy a {
  color: #4282D5;
}
.consent-modal .consent-cookies-list .consent-cookie-list-option {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 18px;
}
.consent-modal .consent-cookies-list .consent-cookie-list-option .consent-cookie-type {
  width: 70%;
}
.consent-modal .consent-cookies-list .consent-cookie-list-option .consent-cookie-type p {
  line-height: 1.5;
  margin-bottom: 5px;
}
.consent-modal .consent-cookies-list .consent-cookie-list-option .consent-cookie-type .btn-basic {
  display: inline-block;
  padding: 0;
  background: none;
  border: none;
  color: #4282D5;
  text-decoration: underline;
}
.consent-modal .consent-cookies-list .consent-cookie-list-option .consent-cookie-toggle {
  text-align: right;
  width: 30%;
}
.consent-modal .consent-cookies-list .consent-cookie-list-option-details {
  margin-top: 10px;
}
.consent-modal .consent-cookies-list .consent-cookie-list-option-details .consent-cookie-details {
  padding-bottom: 10px;
}
.consent-modal .consent-cookies-list .consent-cookie-list-option-details .consent-cookie-details + .consent-cookie-details {
  border-top: 1px solid #EBEBEB;
  padding-top: 10px;
}
.consent-modal .consent-cookies-list .consent-cookie-list-option-details .consent-cookie-details .consent-cookie-details-row {
  display: flex;
  justify-content: space-between;
  padding: 2px 0;
}
.consent-modal .consent-cookies-list .consent-cookie-list-option-details .consent-cookie-details .consent-cookie-details-row .consent-cookie-details-type {
  font-weight: 700;
  width: 100px;
}
.consent-modal .consent-cookies-list .consent-cookie-list-option-details .consent-cookie-details .consent-cookie-details-row .consent-cookie-details-value {
  flex-basis: 270px;
}
.consent-modal .consent-options {
  margin-top: 20px;
}
.consent-modal .consent-options button {
  display: block;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 11px;
  font-weight: 600;
  padding: 9px 0;
  text-align: center;
  width: 100%;
}
.consent-modal .consent-options button.accept-btn {
  border-radius: 3px;
  background-color: #3A76D0;
}
.consent-modal .consent-options button.manage-choices-link {
  text-decoration: underline;
  background-color: transparent;
  border: none;
  color: #3A76D0;
}
.consent-modal .consent-options-inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 18px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.4);
}
.consent-modal .consent-options-inline a.privacy-policy-link {
  color: #4282D5;
  width: 100px;
}
.consent-modal .consent-options-inline .btn-basic {
  width: 120px;
  text-align: center;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 600;
  padding: 5px 10px;
  font-family: "Lato", Helvetica, Arial, sans-serif;
}
.consent-modal .consent-options-inline .btn-basic-secondary {
  background-color: #FFFFFF;
  color: #4282D5;
  border: 1px solid #4282D5;
}
.consent-modal .consent-options-inline .btn-basic-secondary:hover {
  background-color: #F4F5F7;
  border: 1px solid #4282D5;
  color: #4282D5;
}
.consent-modal-trigger {
  background-color: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  color: #000000;
  padding: 8px 10px;
  font-size: 12px;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  cursor: pointer;
  z-index: 10000;
}
.consent-modal-trigger .zfe-icon-gear {
  line-height: normal;
}
#page-body-root .consent-modal,
.interstitial-base .consent-modal,
#page-body-root .consent-modal-trigger,
.interstitial-base .consent-modal-trigger {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.viewer-consent-wrapper .consent-preferences-modal,
.viewer-consent-wrapper .initial-consent-modal {
  position: fixed;
  bottom: 20px;
  left: 20px;
}
.viewer-consent-wrapper .consent-modal-trigger {
  display: inline-block;
  margin: 10px;
}
