.section-video-background {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: -100;
}
.section-video-background-controls {
  z-index: 29;
}
