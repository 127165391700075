.zfTransitions-Opacity-normal-enter {
  opacity: 0.01;
}
.zfTransitions-Opacity-normal-enter.zfTransitions-Opacity-normal-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.zfTransitions-Opacity-normal-leave {
  opacity: 1;
}
.zfTransitions-Opacity-normal-leave.zfTransitions-Opacity-normal-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
.zfTransitions-Opacity-normal-appear {
  opacity: 0.01;
}
.zfTransitions-Opacity-normal-appear.zfTransitions-Opacity-normal-appear-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.zfTransitions-Opacity-fast-enter {
  opacity: 0.01;
}
.zfTransitions-Opacity-fast-enter.zfTransitions-Opacity-fast-enter-active {
  opacity: 1;
  transition: opacity 150ms ease-in;
}
.zfTransitions-Opacity-fast-leave {
  opacity: 1;
}
.zfTransitions-Opacity-fast-leave.zfTransitions-Opacity-fast-leave-active {
  opacity: 0.01;
  transition: opacity 150ms ease-in;
}
.zfTransitions-Opacity-fast-appear {
  opacity: 0.01;
}
.zfTransitions-Opacity-fast-appear.zfTransitions-Opacity-fast-appear-active {
  opacity: 1;
  transition: opacity 150ms ease-in;
}
.zfTransitions-ScaleYTransformFast-enter {
  transform: scaleY(0.01);
}
.zfTransitions-ScaleYTransformFast-enter.zfTransitions-ScaleYTransformFast-enter-active {
  transform: scaleY(1);
  transition: transform 300ms ease-in;
}
.zfTransitions-ScaleYTransformFast-leave {
  transform: scaleY(1);
}
.zfTransitions-ScaleYTransformFast-leave.zfTransitions-ScaleYTransformFast-leave-active {
  transform: scaleY(0.01);
  transition: transform 300ms ease-in;
}
.zfTransitions-ScaleYTransformFast-appear {
  transform: scaleY(0.01);
}
.zfTransitions-ScaleYTransformFast-appear.zfTransitions-ScaleYTransformFast-appear-active {
  transform: scaleY(1);
  transition: transform 300ms ease-in;
}
.zfTransitions-SmallHeight-enter {
  max-height: 0;
}
.zfTransitions-SmallHeight-enter.zfTransitions-SmallHeight-enter-active {
  max-height: 100px;
  transition: max-height 300ms ease-in;
}
.zfTransitions-SmallHeight-leave {
  max-height: 100px;
}
.zfTransitions-SmallHeight-leave.zfTransitions-SmallHeight-leave-active {
  max-height: 0;
  transition: max-height 300ms ease-in;
}
.zfTransitions-SmallHeight-appear {
  max-height: 0;
}
.zfTransitions-SmallHeight-appear.zfTransitions-SmallHeight-appear-active {
  max-height: 100px;
  transition: max-height 300ms ease-in;
}
