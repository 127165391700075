/* INDIVIDUAL TILES */
.tile-wrapper {
  border: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 0px;
  z-index: 1;
}
.tile-wrapper[action_type="overlay"],
.tile-wrapper[action_type="pagecontentlink"],
.tile-wrapper[action_type="video_inline"] {
  cursor: pointer;
}
.editor-actions-bar {
  display: none;
}
.choose-anchor-bar-container {
  display: none;
}
.tile-padding-wrapper {
  border-radius: 2px;
  position: absolute;
}
.tile-wrapper .tile {
  overflow: hidden;
  position: absolute;
  z-index: 3;
}
.tile-wrapper {
  /* Equal Aspect Ratio */
  /* Taller aspect ratio */
  /* Wider aspect ratio */
}
.tile-wrapper > .add-content-prompt {
  float: right;
  background-color: red;
  padding: 7px;
  font-size: 15px;
  color: white;
}
.tile-wrapper .tile-link {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.tile-wrapper .tile-link .link-meta-container {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 25%;
  padding: 1em;
}
.tile-wrapper .tile-link .link-image-container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 75%;
}
.tile-wrapper[data-tile-aspect-reduced] .tile-link.no-image .link-image-container {
  display: none;
}
.tile-wrapper[data-tile-aspect-reduced] .tile-link.no-image .link-meta-container {
  left: 0;
  top: 17px;
}
.tile-wrapper[data-tile-aspect-reduced="1x1"] .link-image-container {
  right: 0;
  bottom: 50%;
}
.tile-wrapper[data-tile-aspect-reduced="1x1"] .link-meta-container {
  left: 0;
  top: 50%;
  padding: 0.7em 1em 1em 1em;
}
.tile-wrapper[data-tile-aspect-reduced="1x2"] .link-image-container {
  right: 0;
  bottom: 50%;
}
.tile-wrapper[data-tile-aspect-reduced="1x2"] .link-meta-container {
  left: 0;
  top: 50%;
}
.tile-wrapper[data-tile-aspect-reduced="1x3"] .link-image-container {
  right: 0;
  bottom: 60%;
}
.tile-wrapper[data-tile-aspect-reduced="1x3"] .link-meta-container {
  left: 0;
  top: 40%;
}
.tile-wrapper[data-tile-aspect-reduced="1x4"] .link-image-container,
.tile-wrapper[data-tile-aspect-reduced="1x5"] .link-image-container {
  right: 0;
  bottom: 66.66%;
}
.tile-wrapper[data-tile-aspect-reduced="1x4"] .link-meta-container,
.tile-wrapper[data-tile-aspect-reduced="1x5"] .link-meta-container {
  left: 0;
  top: 33.33%;
}
.tile-wrapper[data-tile-aspect-reduced="2x3"] .link-image-container,
.tile-wrapper[data-tile-aspect-reduced="2x5"] .link-image-container,
.tile-wrapper[data-tile-aspect-reduced="3x4"] .link-image-container,
.tile-wrapper[data-tile-aspect-reduced="3x5"] .link-image-container,
.tile-wrapper[data-tile-aspect-reduced="4x5"] .link-image-container {
  right: 0;
  bottom: 60%;
}
.tile-wrapper[data-tile-aspect-reduced="2x3"] .link-meta-container,
.tile-wrapper[data-tile-aspect-reduced="2x5"] .link-meta-container,
.tile-wrapper[data-tile-aspect-reduced="3x4"] .link-meta-container,
.tile-wrapper[data-tile-aspect-reduced="3x5"] .link-meta-container,
.tile-wrapper[data-tile-aspect-reduced="4x5"] .link-meta-container {
  left: 0;
  top: 40%;
}
.tile-wrapper[data-tile-aspect-reduced="2x1"] .link-image-container {
  right: 50%;
}
.tile-wrapper[data-tile-aspect-reduced="2x1"] .link-meta-container {
  left: 50%;
}
.tile-wrapper[data-tile-aspect-reduced="3x1"] .link-image-container {
  right: 60%;
}
.tile-wrapper[data-tile-aspect-reduced="3x1"] .link-meta-container {
  left: 40%;
}
.tile-wrapper[data-tile-aspect-reduced="4x1"] .link-image-container,
.tile-wrapper[data-tile-aspect-reduced="5x1"] .link-image-container {
  right: 66.6%;
}
.tile-wrapper[data-tile-aspect-reduced="4x1"] .link-meta-container,
.tile-wrapper[data-tile-aspect-reduced="5x1"] .link-meta-container {
  left: 33.3%;
}
.tile-wrapper[data-tile-aspect-reduced="3x2"] .link-image-container,
.tile-wrapper[data-tile-aspect-reduced="5x2"] .link-image-container,
.tile-wrapper[data-tile-aspect-reduced="4x3"] .link-image-container,
.tile-wrapper[data-tile-aspect-reduced="5x3"] .link-image-container,
.tile-wrapper[data-tile-aspect-reduced="5x4"] .link-image-container {
  right: 60%;
}
.tile-wrapper[data-tile-aspect-reduced="3x2"] .link-meta-container,
.tile-wrapper[data-tile-aspect-reduced="5x2"] .link-meta-container,
.tile-wrapper[data-tile-aspect-reduced="4x3"] .link-meta-container,
.tile-wrapper[data-tile-aspect-reduced="5x3"] .link-meta-container,
.tile-wrapper[data-tile-aspect-reduced="5x4"] .link-meta-container {
  left: 40%;
}
.tile-wrapper .type-link .link-image-container,
.tile-wrapper[should-maintain-stack-order="true"] .link-image-container {
  width: 100%;
  bottom: 50%;
}
.tile-wrapper .type-link .link-meta-container,
.tile-wrapper[should-maintain-stack-order="true"] .link-meta-container {
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  top: 50%;
}
.tile-wrapper.type-text .tile,
.tile-wrapper.type-form .tile {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.tile-wrapper[vertical_align] .tile:before {
  content: '';
  display: inline-block;
  width: 0;
  height: 99.8%;
  vertical-align: middle;
}
.tile-wrapper[vertical_align] .tile-text {
  display: inline-block !important;
  width: 100%;
  box-sizing: border-box;
}
.tile-action-hover-container {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 20;
}
.tile-action-hover-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  opacity: 0.95;
}
.tile-action-hover-contents {
  position: absolute;
  top: 47%;
  margin-top: -0.5em;
  font-size: 3em;
  text-align: center;
  width: 100%;
  left: 0;
  z-index: 10;
}
.tile-action-hover-text {
  margin-top: 1.05em;
  font-size: 0.28em;
}
.tile-action-hover-icon-wrapper {
  position: relative;
  height: 1em;
}
.tile-action-hover-icon-wrapper .zfe-icon-circle {
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  margin-left: -0.52em;
  font-size: 1.2em;
  margin-top: -0.08em;
}
.tile-action-internal-link .zfe-icon-circle {
  margin-left: -0.51em;
  margin-top: -0.07em;
}
.tile-action-pdf .zfe-icon-circle,
.tile-action-ppt .zfe-icon-circle {
  margin-left: -0.51em;
  margin-top: -0.08em;
}
.tile-action-photo .zfe-icon-circle {
  margin-left: -0.5em;
  margin-top: -0.08em;
}
.tile-action-link .zfe-icon-circle {
  margin-left: -0.5em;
}
.video-play-button {
  display: block;
  left: 50%;
  margin-left: -0.5em;
  margin-top: -0.5em;
  position: absolute;
  top: 47%;
  width: 1em;
  height: 1em;
  z-index: 6;
  font-size: 3em;
  overflow: hidden;
}
.video-play-button .zfe-icon-play {
  text-shadow: 0px 0px 14px rgba(0, 0, 0, 0.3);
}
.tile-wrapper[action_type="video_inline"] .tile:before {
  display: none;
}
.tile-wrapper:hover .video-play-button {
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.tile-action-indicator-container {
  display: none;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  height: 2em;
  width: 2em;
  box-sizing: border-box;
  overflow: hidden;
}
.tile-action-indicator-container .tile-action-icon-wrapper {
  font-size: 2em;
}
.indicator-type-other {
  font-family: "Arial Black", Gadget, sans-serif;
  font-size: 0.7em;
  font-weight: bold;
  height: auto;
  padding: 10px 8px;
  text-transform: uppercase;
  width: auto;
}
.tile-image-wrapper,
.tile-iframe-wrapper {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
}
.tile-caption {
  bottom: -1px;
  font-size: 0.8em;
  line-height: 1.3em;
  left: 0;
  padding: 0.6em 0.6em 0.75em 0.6em;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 10;
}
.tile-image {
  width: 100%;
}
.tile-image.is-cropped {
  height: 100%;
}
.tile-iframe {
  width: 100%;
  height: 100%;
}
.tile-text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.5em;
  line-height: 1.3em;
  padding: 0.8em 0.8em 0.9em 0.8em;
  text-align: center;
}
/* PDF ICON */
.tile-wrapper[pdf_icon="true"] .tile-image-wrapper {
  top: auto;
  right: auto;
  bottom: 45%;
  left: 50%;
  width: 4em;
  margin-left: -2em;
}
.tile-wrapper.type-ppt[pdf_icon="true"] .tile-image-wrapper {
  top: auto;
  right: auto;
  bottom: 45%;
  left: 50%;
  width: 8em;
  margin-left: -4em;
}
.tile-wrapper[pdf_icon="true"] .tile-caption {
  background: transparent;
  top: 57%;
  right: 10%;
  left: 10%;
  font-size: 1.5em;
  padding: 0.2em;
  bottom: 0;
  word-wrap: break-word;
}
.grid-mobile-viewer .tile-wrapper.type-link .link-image-container {
  right: 0;
  bottom: 50%;
}
.grid-mobile-viewer .tile-wrapper.type-link .link-meta-container {
  left: 0;
  top: 50%;
  padding: 0.7em 1em 1em 1em;
}
