.section-type-presentation .presentation-wrapper {
  height: 500px;
}
.presentation-section-mobile-wrapper {
  position: relative;
  height: 100%;
}
.presentation-section-mobile-wrapper .tile-wrapper[action_type="overlay"] {
  cursor: default;
}
.presentation-section-mobile-wrapper .tile-wrapper {
  height: 100%;
}
.presentation-section-mobile-wrapper .tile-wrapper .tile {
  height: 100%;
}
.is-mobile-layout .section-type-presentation .presentation-wrapper {
  height: 255px;
}
.is-mobile-layout .section-type-presentation .presentation-wrapper .presentation-left {
  left: 10px;
}
.is-mobile-layout .section-type-presentation .presentation-wrapper .presentation-right {
  right: 10px;
}
.is-mobile-layout .section-type-presentation .presentation-wrapper .progress-bar {
  right: 10px;
}
.is-mobile-layout .section-type-presentation .presentation-wrapper .fullscreen-btn {
  display: none;
}
