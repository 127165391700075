.scroll-to-top-container {
  position: fixed;
  bottom: 20px;
  z-index: 10;
}
.scroll-to-top-container.desktop .scroll-to-top {
  margin-right: 70px;
}
.scroll-to-top-container.mobile .scroll-to-top {
  margin-right: 20px;
}
.scroll-to-top-container .scroll-to-top {
  border-radius: 25px;
  padding: 10px;
  cursor: pointer;
}
.scroll-to-top-container .scroll-to-top .scroll-to-top-text {
  margin-bottom: 3px;
}
.scroll-to-top-container .scroll-to-top .scroll-to-top-icon i {
  margin-right: 5px;
  margin-left: -1px;
  margin-top: -3px;
}
