.zf-select {
  /* Override some weird styling applied by react-select*/
}
.zf-select .zf-select__control {
  border-radius: 2px;
}
.zf-select .zf-select__control.zf-select__control--is-focused {
  border: 1px solid #2F6EC0;
  box-shadow: none;
}
.zf-select .zf-select__control .zf-select__indicator-separator {
  visibility: hidden;
}
.zf-select .zf-select-dark__control {
  border: 1px solid #161d25;
  background-color: #394050;
}
.zf-select .zf-select-dark__control:hover {
  background-color: #485165;
}
.zf-select .zf-select-dark__placeholder {
  color: white;
}
.zf-select .zf-select-dark__input input:focus {
  outline: 0;
  box-shadow: none;
}
.zf-select .zf-select-dark__input input {
  color: white !important;
  margin-bottom: 0;
}
/* The select menu is created in the body element */
body * .zf-select-dark__multi-value {
  background-color: #5F7299;
}
body * .zf-select-dark__multi-value__label {
  color: white;
}
body * .zf-select-dark__menu {
  background-color: #394050;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
}
body * .zf-select-dark__value-container,
body * .zf-select__indicators {
  cursor: pointer;
  font-size: 12px;
  color: white;
}
body * .zf-select-dark__value-container {
  color: white;
}
body * .zf-select-dark__placeholder:hover,
body * .zf-select-dark__value:hover,
body * .zf-select-dark__menu-list:hover {
  background-color: #485165;
}
body * .zf-select-dark__single-value {
  color: white;
}
body * .zf-select-dark__menu-list {
  border: 1px solid #161d25;
}
body * .zf-select-dark__option {
  padding: 6px 0 6px 10px;
  color: white;
  background-color: #394050;
  font-size: 12px;
  cursor: pointer;
}
body * .zf-select-dark__option:hover {
  background-color: #485165;
}
