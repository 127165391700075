.grid-lines {
  margin: 0 auto;
  z-index: -1;
}
.grid-lines .grid-line-wrapper {
  position: absolute;
  outline: 1px solid #F7F8F9;
}
.grid-lines .grid-line-wrapper .grid-line {
  height: 100%;
  box-sizing: border-box;
}
.grid-lines .grid-line-wrapper .grid-line .grid-line-fill {
  height: 100%;
  background-color: rgba(255, 107, 107, 0.2);
}
