.link-editor {
  margin: 15px;
}
.link-editor label {
  font-size: 12px;
}
.link-editor h3 {
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 13px;
}
.link-editor .cancel-link {
  text-align: center;
  margin-top: 10px;
  display: inline-block;
  width: 90%;
  color: white;
  font-size: 0.85em;
}
.link-editor .deleter-container {
  width: 100%;
  margin-top: 20px;
  text-align: center;
  color: red;
}
.link-editor .deleter-container .page-editor-sidebar-divider {
  margin-bottom: 20px;
}
.link-editor .deleter-container a.basic-link {
  color: #ff1919;
  font-size: 12px;
}
.link-editor .deleter-container i {
  text-decoration: none;
}
