.accordion-wrapper {
  padding: 40px 0;
}
.accordion-wrapper .section-accordion .accordion-item {
  margin: 2px 0;
  border: 1px solid #BBB;
}
.accordion-wrapper .section-accordion .accordion-item .left {
  padding: 30px;
  padding-right: 0;
  flex-grow: 1;
  min-height: 22px;
  text-align: left;
}
.accordion-wrapper .section-accordion .accordion-item .right {
  flex-grow: 0;
}
.accordion-wrapper .section-accordion .accordion-item .label {
  cursor: pointer;
  display: flex;
  background-color: #FFF;
}
.accordion-wrapper .section-accordion .accordion-item .label .accordion-toggle i {
  color: #BBB;
}
.accordion-wrapper .section-accordion .accordion-item .label:hover .accordion-toggle i {
  color: #575F73;
}
.accordion-wrapper .section-accordion .accordion-item .label .accordion-toggle {
  margin-top: 30px;
  width: 16px;
  padding-left: 30px;
  padding-right: 30px;
}
.accordion-wrapper .section-accordion .accordion-item .content {
  display: flex;
  background-color: #FFF;
  border-top: 1px solid #EEE;
  padding-left: 20px;
}
.accordion-wrapper .section-accordion .accordion-item .content .right .filler {
  width: 16px;
  padding-left: 30px;
  padding-right: 30px;
}
