.add-media-modal {
  width: 680px;
  margin-left: -340px;
}
.add-media-modal.size1 {
  width: 205px;
  margin-left: -102.5px;
}
.add-media-modal.size2 {
  width: 365px;
  margin-left: -182.5px;
}
.add-media-modal.size3 {
  width: 525px;
  margin-left: -262.5px;
}
.add-media-modal.size4 {
  width: 695px;
  margin-left: -347.5px;
}
.add-media-modal.size5,
.add-media-modal.size6,
.add-media-modal.size7 {
  width: 850px;
  margin-left: -425px;
}
.add-media-modal.size5 .add-media-choice:nth-child(1),
.add-media-modal.size5 .add-media-choice:nth-child(2) {
  width: 392px;
}
.add-media-modal.size5 .add-media-choice:nth-child(3),
.add-media-modal.size5 .add-media-choice:nth-child(4),
.add-media-modal.size5 .add-media-choice:nth-child(5) {
  width: 260px;
}
.add-media-modal.size6 .add-media-choice:nth-child(1),
.add-media-modal.size6 .add-media-choice:nth-child(2) {
  width: 395px;
}
.add-media-modal.size6 .add-media-choice:nth-child(3),
.add-media-modal.size6 .add-media-choice:nth-child(4),
.add-media-modal.size6 .add-media-choice:nth-child(5),
.add-media-modal.size6 .add-media-choice:nth-child(6) {
  width: 192px;
}
.add-media-modal.size7 .add-media-choice:nth-child(1),
.add-media-modal.size7 .add-media-choice:nth-child(2) {
  width: 395px;
}
.add-media-modal.size7 .add-media-choice:nth-child(3),
.add-media-modal.size7 .add-media-choice:nth-child(4),
.add-media-modal.size7 .add-media-choice:nth-child(5),
.add-media-modal.size7 .add-media-choice:nth-child(6),
.add-media-modal.size7 .add-media-choice:nth-child(7) {
  width: 152px;
}
.add-media-modal .modal-header {
  background-color: #FFF;
  border-bottom: 1px solid #AAA !important;
  border-radius: 6px 6px 0px 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 6px 6px 0px 0px;
  padding: 7px 12px 7px 20px;
}
.add-media-modal .modal-header h1 {
  margin: 0px;
  font-size: 18px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  color: #6F6F6F;
  font-weight: 500;
}
.add-media-modal .modal-header .close {
  margin: 3px 3px 0 0;
}
.add-media-modal .modal-body {
  border-radius: inherit;
}
.add-media-modal .add-media-choices-wrapper {
  margin: 15px auto 20px auto;
  text-align: center;
}
.add-media-modal .add-media-choice {
  background-color: #F4F4F4;
  box-sizing: border-box;
  color: #555;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  height: 145px;
  line-height: 17px;
  margin: 4px 2px;
  padding: 13px 15px 15px 15px;
  text-align: center;
  text-rendering: optimizeLegibility;
  width: 150px;
  vertical-align: top;
}
.add-media-modal .add-media-choice:hover {
  background-color: #EAEAEA;
}
.add-media-modal .add-media-choice .add-file,
.add-media-modal .add-media-choice .add-text,
.add-media-modal .add-media-choice .add-media-lib,
.add-media-modal .add-media-choice .add-link,
.add-media-modal .add-media-choice .add-recording,
.add-media-modal .add-media-choice .add-youtube,
.add-media-modal .add-media-choice .add-form,
.add-media-modal .add-media-choice .add-presentation,
.add-media-modal .add-media-choice .add-html-package {
  display: block;
  margin: 15px auto 14px auto;
  width: 64px;
}
.add-media-modal .add-media-choice .add-html-package,
.add-media-modal .add-media-choice .add-presentation {
  color: #4dcffa;
  font-size: 48px;
  display: inline-block;
  margin: 35px 0px 26px -20px;
}
