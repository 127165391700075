.sidebar-breadcrumb-navigation {
  padding: 12px 15px;
  color: #A3A5A8;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
}
.sidebar-breadcrumb-navigation .crumb {
  cursor: pointer;
}
.sidebar-breadcrumb-navigation .crumb:hover {
  color: white;
}
.sidebar-breadcrumb-navigation .crumb.is-last-crumb {
  color: white;
  cursor: default;
}
.sidebar-breadcrumb-navigation .crumb-sep {
  padding: 0 3px;
}
