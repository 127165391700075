.center-vertical {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.center-horizontal {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.center-absolutely {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateX(-50%) translateX(-50%);
  -o-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}
.modal-backdrop {
  text-align: center;
}
.base-modal {
  font-size: 14px;
  color: #111;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  display: inline-block;
  text-align: left;
  margin-top: 10vh;
  background: #fff;
  padding: 36px 40px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 20px rgba(0, 0, 0, 0.2);
}
.base-modal.simple-modal {
  min-width: 600px;
  max-width: 1000px;
}
.base-modal .section + .section {
  margin: 35px 0 0 0;
}
.base-modal .section .section-header {
  color: #BBB;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 22px;
}
.base-modal .section .section-row {
  *zoom: 1;
}
.base-modal .section .section-row:before,
.base-modal .section .section-row:after {
  display: table;
  content: "";
  line-height: 0;
}
.base-modal .section .section-row:after {
  clear: both;
}
.base-modal .section .section-row.section-row_margin-bottom {
  margin-bottom: 20px;
}
.base-modal .section .section-radio .field-name-container {
  margin-bottom: 12px;
}
.base-modal.shaking-modal {
  animation: shakemodal 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shakemodal {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
