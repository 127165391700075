.full-text-search-modal-background {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10000000;
  background-color: rgba(0, 0, 0, 0.6);
}
.full-text-search-display {
  position: absolute;
  width: 500px;
  left: 50%;
  top: 40%;
  margin-left: -250px;
  margin-top: -150px;
  z-index: 10000000;
  font-family: sans-serif;
  font-weight: 400;
  font-size: 15px;
}
.full-text-search-display .full-text-search-select__input {
  color: #141414;
  cursor: text;
  font-weight: 400;
  font-family: sans-serif;
}
@media (max-width: 640px) {
  .full-text-search-display {
    left: 0;
    right: 0;
    top: 0;
    width: auto;
    margin-left: 0;
    margin-top: 0;
  }
}
.full-text-search-display h2 {
  color: #141414;
}
.full-text-search-display .search-result-item {
  position: relative;
  padding: 5px;
}
.full-text-search-display .search-result-item .result-item-icon-box {
  position: absolute;
  left: 10px;
  top: 15px;
  font-size: 1.4em;
  color: #686868;
}
.full-text-search-display .search-result-item .result-item-content-box {
  margin-left: 50px;
}
.full-text-search-display .search-result-item .item-type-description {
  font-style: italic;
  font-size: 10px;
  color: #686868;
  padding: 5px 0 2px 5px;
}
.full-text-search-display .search-result-match-item,
.full-text-search-display search-result-match-item-result {
  max-height: 3.1em;
  overflow-y: hidden;
  position: relative;
  padding: 5px;
}
.full-text-search-display .search-result-match-item .field-name,
.full-text-search-display search-result-match-item-result .field-name {
  margin-right: 5px;
  color: #686868;
}
.full-text-search-display .search-result-match-item .field-value .highlight,
.full-text-search-display search-result-match-item-result .field-value .highlight {
  background-color: #7CB6FF;
}
.full-text-search-display .zfe-icon-search {
  margin-left: 5px;
}
.full-text-search-display .full-text-search-select__control {
  background-color: #fff;
  color: #141414;
  border: none;
  box-shadow: none;
  border-radius: 0;
  font-size: 22px;
  padding-left: 5px;
  border-radius: 2px 2px 0 0;
}
.full-text-search-display .full-text-search-select__control--is-focused {
  border: none;
}
.full-text-search-display .full-text-search-select__dropdown-indicator {
  display: none;
}
.full-text-search-display .full-text-search-select__indicator-separator {
  display: none;
}
.full-text-search-display .full-text-search-select__menu {
  background-color: #F0F0F0;
  border-radius: 0;
  margin-bottom: 0;
  margin-top: 0;
  border-radius: 0 0 2px 2px;
}
.full-text-search-display .full-text-search-select__menu-list {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 400px;
}
.full-text-search-display .full-text-search-select__option {
  color: #141414;
  border: 1px solid #F0F0F0;
  border-bottom: 1px solid #dcdcdc;
}
.full-text-search-display .full-text-search-select__option--is-focused {
  background-color: #e3e3e3;
  color: #141414;
  cursor: pointer;
  border: 1px solid #6eb6ff;
}
.full-text-search-display .full-text-search-select__placeholder {
  color: #686868;
}
.full-text-search-display .full-text-search-select__value-container {
  color: #141414;
  padding: 9px 4px 10px 4px;
}
.full-text-search-display .full-text-search-select__menu-notice {
  color: #141414;
  opacity: 0.5;
}
