.site-viewer-version-modal .simple-modal-with-header-body i.zfam-check-circle {
  color: #25B638;
  text-align: center;
  display: block;
  font-size: 50px;
  margin-bottom: 20px;
}
.site-viewer-version-modal .simple-modal-with-header-body .zf-listbox .theme-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.site-viewer-version-modal .simple-modal-with-header-body .zf-listbox .theme-option .theme-preview-thumbnail img {
  height: 50px;
}
.site-viewer-version-modal .simple-modal-with-header-body .zf-listbox .theme-option .theme-preview-thumbnail i {
  color: #999;
  text-align: center;
  height: 50px;
  width: 63px;
  display: inline-block;
  line-height: 50px !important;
  background-color: #cbcbcb;
}
.site-viewer-version-modal .simple-modal-with-header-body .zf-listbox .theme-option .theme-metadata {
  padding-left: 15px;
  width: 75%;
  box-sizing: border-box;
}
.site-viewer-version-modal .simple-modal-with-header-body .zf-listbox .theme-option .theme-metadata .theme-title {
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
}
.site-viewer-version-modal .simple-modal-with-header-body .zf-listbox .theme-option .theme-metadata .theme-description {
  font-size: 9px;
  font-weight: normal;
  line-height: 11px;
}
.site-viewer-version-modal .simple-modal-with-header-body .zf-listbox .theme-option .theme-metadata .theme-status {
  display: flex;
  align-items: center;
  margin-top: 2px;
}
.site-viewer-version-modal .simple-modal-with-header-body .zf-listbox .theme-option .theme-metadata .theme-status span {
  display: flex;
  align-items: center;
  font-size: 9px;
  font-weight: normal;
  line-height: 11px;
  margin-right: 16px;
}
.site-viewer-version-modal .simple-modal-with-header-body .zf-listbox .theme-option .theme-metadata .theme-status i {
  margin-right: 4px;
}
.site-viewer-version-modal .simple-modal-with-header-body .zf-listbox .theme-option .theme-metadata .theme-status .latest-version {
  color: #2ADD62;
}
.site-viewer-version-modal .simple-modal-with-header-body .zf-listbox .theme-option .theme-metadata .theme-status .is-compatible-status {
  color: #134FEA;
}
.site-viewer-version-modal .simple-modal-with-header-body .zf-listbox .theme-option .theme-pages-in-use {
  font-size: 9px;
  font-weight: normal;
  line-height: 11px;
  text-align: center;
  width: 15%;
}
.site-viewer-version-modal .simple-modal-with-header-body .zf-listbox [data-current-nav] .latest-version,
.site-viewer-version-modal .simple-modal-with-header-body .zf-listbox [data-current-selected] .latest-version,
.site-viewer-version-modal .simple-modal-with-header-body .zf-listbox [data-current-nav] .upgrade-available,
.site-viewer-version-modal .simple-modal-with-header-body .zf-listbox [data-current-selected] .upgrade-available,
.site-viewer-version-modal .simple-modal-with-header-body .zf-listbox [data-current-nav] .is-compatible-status,
.site-viewer-version-modal .simple-modal-with-header-body .zf-listbox [data-current-selected] .is-compatible-status {
  color: #FFFFFF !important;
}
.site-viewer-version-modal .simple-modal-with-header-body .site-viewer-version-style-preset-mapping-view .map-style-preset-pane-wrapper {
  margin-bottom: 20px;
}
.site-viewer-version-modal .simple-modal-with-header-body .site-viewer-version-style-preset-mapping-view h2 {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 5px;
}
.site-viewer-version-modal .simple-modal-with-header-body .site-viewer-version-style-preset-mapping-view h4 {
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #000000;
}
.site-viewer-version-modal .simple-modal-with-header-body .site-viewer-version-style-preset-mapping-view .style-preset-wrapper {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  background-color: transparent;
  border: 1px solid #989898;
  border-top: none;
}
.site-viewer-version-modal .simple-modal-with-header-body .site-viewer-version-style-preset-mapping-view .style-preset-wrapper:first-child {
  border-top: 1px solid #989898;
}
.site-viewer-version-modal .simple-modal-with-header-body .site-viewer-version-style-preset-mapping-view .center-pane .style-preset-mapping-icon {
  color: #000000;
}
