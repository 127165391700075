.link-creator {
  width: 520px;
  *zoom: 1;
  font-size: 14px;
  color: #111;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.link-creator:before,
.link-creator:after {
  display: table;
  content: "";
  line-height: 0;
}
.link-creator:after {
  clear: both;
}
.link-creator * {
  box-sizing: border-box;
}
.link-creator .zf-tooltip-icon-box {
  margin-left: 7px;
  color: #aaa;
}
.link-creator .page-permissions-display {
  border-bottom: 1px solid #ddd;
}
.link-creator .page-permissions-display .page-permissions-header {
  margin-bottom: 12px;
}
.link-creator .page-permissions-display .access-item {
  padding: 12px 0px;
}
.link-creator .domain-container {
  display: inline-block;
}
.link-creator .path-container {
  display: inline-block;
}
.link-creator .buttons-container {
  position: relative;
  margin-top: 30px;
  text-align: right;
  float: right;
}
.link-creator .buttons-container button.btn-basic.btn-basic-secondary {
  margin-right: 10px;
}
.link-creator .link-settings {
  background-color: white;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 88px -40px 0px;
  padding: 35px 40px 15px;
}
.link-creator .share-link-help-text {
  font-size: 12px;
  margin-bottom: 10px;
}
.link-creator .share-link-help-text .link {
  color: #60a5fc;
  font-weight: 700;
  cursor: pointer;
}
.link-creator .radio-container {
  display: inline-block;
  margin-top: 10px;
}
.link-creator .radio-container + .radio-container {
  margin-left: 35px;
}
.link-creator .radio-container label {
  margin-bottom: 0;
}
.link-creator .radio-container input[type="radio"] {
  margin: 0px 12px 3px 0px;
}
.link-creator label span {
  cursor: pointer;
}
