.vjs-youtube.vjs-waiting .vjs-big-play-button {
  display: none !important;
}
.vjs-youtube-mobile .vjs-big-play-button {
  background: #000;
  display: flex !important;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.15);
  height: 1.5em;
  width: 1.5em;
  margin-left: -0.8em;
}
.vjs-youtube-mobile.vjs-has-started .vjs-big-play-button {
  display: none !important;
}
.vjs-control-bar .vjs-menu-button.vjs-menu-button-popup.vjs-button {
  z-index: 1;
  position: relative;
}
