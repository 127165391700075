.link-path-editor .combined-input-box {
  background-color: #FFF;
  border: 1px solid #ddd;
  box-shadow: none;
  margin-bottom: 0px;
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 1em;
  width: 100%;
}
.link-path-editor .combined-input-box.focused {
  border: 1px solid #60a5fc;
}
.link-path-editor .combined-input-box.error,
.link-path-editor .combined-input-box.focused.error {
  border: 1px solid red;
}
.link-path-editor .combined-input-box[disabled] {
  background-color: #EEE;
}
.link-path-editor .domain-container {
  height: 38px;
  max-width: 50%;
  padding-right: 8px;
  padding-left: 2px;
  border-radius: 4px 0 0 4px;
  overflow: hidden;
  background-color: #EEE;
  box-sizing: border-box;
}
.link-path-editor .domain-container.select-domain-container {
  overflow: visible;
  padding-left: 0;
  padding-right: 0;
  width: 50%;
}
.link-path-editor .domain-container.select-domain-container .zf-select__value-container {
  padding: 0 8px;
}
.link-path-editor .domain-container.select-domain-container .zf-select__input input {
  width: 0 !important;
}
.link-path-editor .domain-container.select-domain-container .zf-select__control {
  border: none;
  border-right: 1px solid #ddd;
  background-color: #EEE;
  height: 100%;
}
.link-path-editor .domain-container.select-domain-container .zf-select__single-value {
  padding: 0 8px;
}
.link-path-editor .domain-container.select-domain-container .zf-select__indicators {
  height: 100%;
}
.link-path-editor .domain-container span {
  display: block;
  padding: 0 10px;
  height: 40px;
  line-height: 38px;
  white-space: pre;
}
.link-path-editor .domain-container select {
  height: 40px;
  max-width: 100%;
  border: none;
  font-size: 1em;
  background: none;
  padding-left: 7px;
  cursor: pointer;
  outline: none;
}
.link-path-editor .domain-container select:focus {
  outline: none;
}
.link-path-editor .path-container {
  width: 50%;
  box-sizing: border-box;
}
.link-path-editor .path-container input[type=text] {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #111;
  background-color: #FFF;
  box-shadow: none;
  margin-bottom: 0px;
  box-sizing: border-box;
  height: 38px;
  display: inline-block;
  width: 100%;
  padding: 8px 10px;
  margin-left: 0px;
  background: none;
  border: none;
  border-radius: 0px 4px 4px 0px;
}
.link-path-editor .path-container input[type=text]:focus {
  border: none;
  outline: none;
}
