.map-style-preset-pane-wrapper {
  display: flex;
  justify-content: space-between;
}
.map-style-preset-pane-wrapper .ordered-style-presets .style-preset-wrapper {
  background-color: #444c5f;
  border: 1px solid #161d25;
  border-top: none;
  padding: 10px;
  height: 20px;
  font-size: 13px;
}
.map-style-preset-pane-wrapper .ordered-style-presets .style-preset-wrapper.display-as-dimmed {
  opacity: 0.5;
}
.map-style-preset-pane-wrapper .ordered-style-presets .style-preset-wrapper:first-child {
  border-top: 1px solid #161d25;
}
.map-style-preset-pane-wrapper .left-pane {
  width: 45%;
}
.map-style-preset-pane-wrapper .center-pane {
  padding-top: 59px;
  width: 10%;
}
.map-style-preset-pane-wrapper .center-pane .style-preset-mapping-icon {
  height: 20px;
  padding: 10px;
  text-align: center;
  color: #A3A5A8;
}
.map-style-preset-pane-wrapper .center-pane .style-preset-mapping-icon.display-as-dimmed {
  opacity: 0.5;
}
.map-style-preset-pane-wrapper .right-pane {
  width: 45%;
}
.map-style-preset-pane-wrapper .right-pane .field-drag-handle {
  margin-right: 10px !important;
}
.map-style-preset-pane-wrapper h2 {
  color: white;
  font-size: 18px;
  margin: 0;
  line-height: 28px;
  margin-bottom: 10px;
}
.map-style-preset-pane-wrapper h4 {
  color: #A3A5A8;
  font-size: 12px;
  text-transform: uppercase;
  margin: 0;
}
.sortable-helper-style-presets-dnj49h2s {
  opacity: 0.5;
  z-index: 10000;
  background-color: #444c5f;
  border: 1px solid #161d25;
  color: white;
  border-top: none;
  padding: 10px;
  height: 20px;
}
