.tipsy { font-size: 13px; font-family: 'Open Sans', sans-serif; position: absolute; padding: 5px; z-index: 100000; }
  .tipsy p{ margin-bottom: 0px; }
  .tipsy-inner { background-color: #fff; color: #000; max-width: 300px; padding: 11px 13px; text-align: center;}

  /* Rounded corners */
  .tipsy-inner { border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px; }
  
  /* Uncomment for shadow */
  .tipsy-inner { box-shadow: 0 0 2px #656565; -webkit-box-shadow: 0 0 2px #656565; -moz-box-shadow: 0 0 2px #656565; }
  
  .tipsy-arrow { position: absolute; width: 0; height: 0; line-height: 0; border: 10px dashed #000;  }
  
  /* Rules to colour arrows */
  .tipsy-arrow-n { border-bottom-color: #fff; }
  .tipsy-arrow-s { border-top-color: #fff; }
  .tipsy-arrow-e { border-left-color: #fff; }
  .tipsy-arrow-w { border-right-color: #fff; }
  
	.tipsy-n .tipsy-arrow { top: 0px; left: 50%; margin-left: -10px; border-bottom-style: solid; border-top: none; border-left-color: transparent; border-right-color: transparent; }
    .tipsy-nw .tipsy-arrow { top: 0; left: 10px; border-bottom-style: solid; border-top: none; border-left-color: transparent; border-right-color: transparent;}
    .tipsy-ne .tipsy-arrow { top: 0; right: 10px; border-bottom-style: solid; border-top: none;  border-left-color: transparent; border-right-color: transparent;}
  .tipsy-s .tipsy-arrow { bottom: 0; left: 50%; margin-left: -10px; border-top-style: solid; border-bottom: none;  border-left-color: transparent; border-right-color: transparent; }
    .tipsy-sw .tipsy-arrow { bottom: 0; left: 10px; border-top-style: solid; border-bottom: none;  border-left-color: transparent; border-right-color: transparent; }
    .tipsy-se .tipsy-arrow { bottom: 0; right: 10px; border-top-style: solid; border-bottom: none; border-left-color: transparent; border-right-color: transparent; }
  .tipsy-e .tipsy-arrow { right: 0; top: 50%; margin-top: -10px; border-left-style: solid; border-right: none; border-top-color: transparent; border-bottom-color: transparent; }
  .tipsy-w .tipsy-arrow { left: 0; top: 50%; margin-top: -10px; border-right-style: solid; border-left: none; border-top-color: transparent; border-bottom-color: transparent; }
