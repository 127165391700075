.confirm-upload-or-choose-media-modal .title-container i {
  color: #9bb1cc;
  margin-right: 5px;
}
.confirm-upload-or-choose-media-modal .subtitle-container {
  margin-top: 5px !important;
  font-size: 12px;
}
.confirm-upload-or-choose-media-modal .simple-modal-with-header-body {
  padding: 0 !important;
  text-align: center;
  border-top: 1px solid #BBC8D5;
}
.confirm-upload-or-choose-media-modal .simple-modal-with-header-body .zfam-arrow-right {
  color: #4282D5;
}
.confirm-upload-or-choose-media-modal .simple-modal-with-header-body a {
  color: #4282D5;
}
.confirm-upload-or-choose-media-modal .simple-modal-with-header-body button.modal-option {
  border: none;
  color: #4282D5;
  font-size: 14px;
  line-height: 1.3;
  height: 120px;
  font-weight: 400;
  padding: 0 50px;
  width: 50%;
}
.confirm-upload-or-choose-media-modal .simple-modal-with-header-body button.modal-option i {
  font-size: 24px;
  display: block;
}
.confirm-upload-or-choose-media-modal .simple-modal-with-header-body button.modal-option i.zfam-cloud-upload {
  margin-top: -18px;
  font-size: 28px;
}
.confirm-upload-or-choose-media-modal .simple-modal-with-header-body button.modal-option.modal-option-left {
  border-right: 1px solid #BBC8D5;
}
