.map-style-presets-modal {
  border: 1px solid #161d25;
  color: white;
  width: 800px;
}
.map-style-presets-modal.simple-modal-with-header .simple-modal-header {
  background-color: #444c5f;
  border-bottom: 1px solid #161d25;
}
.map-style-presets-modal.simple-modal-with-header .simple-modal-header .title-container {
  font-size: 18px;
}
.map-style-presets-modal.simple-modal-with-header .simple-modal-header .title-container i {
  color: #A3A5A8;
  font-size: 14px;
}
.map-style-presets-modal.simple-modal-with-header .simple-modal-header .subtitle-container {
  color: white;
  font-size: 14px;
  margin-top: 5px;
}
.map-style-presets-modal.simple-modal-with-header .simple-modal-with-header-body {
  background-color: #394050;
}
.map-style-presets-modal.simple-modal-with-header .simple-modal-with-header-body .map-style-preset-actions {
  margin-top: 30px;
  text-align: center;
  font-size: 18px;
  width: 100%;
}
.map-style-presets-modal.simple-modal-with-header .simple-modal-with-header-body .map-style-preset-actions .btn-basic {
  margin-bottom: 10px;
}
.map-style-presets-modal.simple-modal-with-header .simple-modal-with-header-body .map-style-preset-actions .basic-link {
  color: white;
  font-size: 14px;
}
