#menu-item-subpage .scroll-indicator-color-container .scroll-indicator-color-selector .zf-radio-button-simple-label {
  display: inline-block;
  margin-right: 15px;
}
#menu-item-subpage .slug-input-container {
  position: relative;
  display: flex;
}
#menu-item-subpage .slug-input-container .slug-input-prefix {
  position: absolute;
  left: 8px;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  font-weight: bold;
  color: #A3A5A8;
}
#menu-item-subpage .slug-input-container .subpage-slug-input {
  padding-left: 16px;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
#menu-item-subpage .slug-input-container .subpage-slug-button {
  border-color: #111318;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rc-tooltip.zf-tipsy.subpage-slug-tip .rc-tooltip-inner {
  max-width: 1000px;
}
