.mobile-social-links-wrapper {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 12em;
  height: 100%;
  line-height: 43px;
  vertical-align: middle;
}
.mobile-social-links-wrapper .mobile-social-links {
  float: right;
  box-sizing: border-box;
  display: block;
}
.mobile-social-links-wrapper .mobile-social-icon-wrapper {
  display: block;
  float: right;
  margin-left: 5px;
  z-index: 10;
  position: relative;
}
.mobile-social-links-wrapper .mobile-social-icon-wrapper a.social-icon-link {
  color: #999;
  text-decoration: none;
  font-size: 22px;
  display: block;
}
.mobile-social-links-wrapper .mobile-social-icon-wrapper a.social-icon-link .zfe-icon {
  vertical-align: middle;
}
