.modern-header-social-links {
  display: flex;
  align-items: center;
  min-height: 30px;
}
.modern-header-social-links.force-right {
  margin-left: auto;
}
.modern-header-social-links .header-social-icon-wrapper {
  padding: 0 8px;
}
.modern-header-social-links .header-social-icon-wrapper:hover,
.modern-header-social-links .header-social-icon-wrapper:visited,
.modern-header-social-links .header-social-icon-wrapper:active {
  text-decoration: none;
  color: #3e3e41;
}
.modern-header-social-links .header-social-icon-wrapper a.social-icon-link {
  text-decoration: none;
  font-size: 1em;
  display: block;
}
