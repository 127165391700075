.vanilla-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-selection[id^=paragraphFormat] {
  width: 40px;
}
.vanilla-theme.fr-popup .fr-command.fr-btn.fr-dropdown[id^="linkList"] {
  display: none;
}
.vanilla-theme.fr-popup .fr-link-insert-layer.fr-layer {
  width: 220px;
}
.vanilla-theme.fr-toolbar .fr-command.fr-btn.fr-dropdown,
.vanilla-theme.fr-popup .fr-command.fr-btn.fr-dropdown {
  width: 38px;
}
.vanilla-theme.fr-popup .fr-input-line input + label,
.vanilla-theme.fr-popup .fr-input-line textarea + label {
  line-height: inherit;
}
.vanilla-theme.fr-popup .fr-checkbox-line span.fr-checkbox + label {
  display: inline;
}
.vanilla-theme.fr-toolbar .fr-command.fr-btn,
.vanilla-theme.fr-popup .fr-command.fr-btn {
  width: 28px;
  height: 34px;
  margin: 0;
}
.vanilla-theme.fr-toolbar .fr-command.fr-btn i,
.vanilla-theme.fr-popup .fr-command.fr-btn i {
  margin: 1px 0 0 6px;
}
.vanilla-theme .fr-popup .fr-table-colors {
  padding: 0;
}
