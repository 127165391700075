.toggle-section {
  padding: 0 0 22px 0;
}
.toggle-section + .toggle-section {
  border-top: 1px solid #ddd;
  padding: 22px 0 22px 0;
}
.toggle-section .field-description-container {
  width: calc(100% - 50px);
  display: inline-block;
}
.toggle-section .field-description-container .zf-tooltip-icon-box {
  display: inline-block;
  margin-left: 12px;
  color: #aaa;
}
.toggle-section .field-description-container .field-explanation {
  margin-top: 2px;
  color: #888;
  font-weight: 400;
}
.toggle-section .tooltip2 {
  margin-left: 12px;
  color: #888;
}
.toggle-section .toggler-container {
  display: inline-block;
  float: right;
}
