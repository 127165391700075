.media-library-info-panel {
  background-color: #EEE;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  overflow: auto;
  border-left: 1px solid #AAA;
}
.media-library-info-panel .preview-img {
  background-color: #222;
  padding: 32px;
  text-align: center;
}
.media-library-info-panel .preview-img .preview-wrapper,
.media-library-info-panel .preview-img .folder-preview-wrapper {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.5);
  height: 180px;
  margin: 0 auto;
  position: relative;
  width: 180px;
}
.media-library-info-panel .preview-img .preview-wrapper.clickable {
  cursor: pointer;
}
.media-library-info-panel .preview-img .preview-wrapper.clickable:hover .expand-preview {
  opacity: 1;
}
.media-library-info-panel .preview-img .preview-wrapper.clickable:hover .expand-preview .fa {
  color: #FFF;
}
.media-library-info-panel .preview-img .folder-preview-wrapper {
  box-shadow: none;
  height: 140px;
}
.media-library-info-panel .preview-img .folder-preview-wrapper .folder-preview {
  padding-top: 10px;
  width: 150px;
}
.media-library-info-panel .preview-img .expand-preview {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #000;
  box-sizing: border-box;
  float: right;
  height: 40px;
  opacity: 0.8;
  padding: 10px;
  text-align: center;
  width: 40px;
}
.media-library-info-panel .preview-img .expand-preview.playable {
  top: 50%;
  left: 50%;
  bottom: auto;
  right: auto;
  margin-left: -25px;
  margin-top: -25px;
  height: 50px;
  width: 50px;
}
.media-library-info-panel .preview-img .expand-preview.playable .fa {
  font-size: 30px;
}
.media-library-info-panel .preview-img .expand-preview .fa {
  color: #DDD;
  font-size: 19px;
}
.media-library-info-panel .media-home-wrapper {
  color: #333;
  font-size: 13px;
  padding: 55px 40px;
  text-align: center;
}
.media-library-info-panel .media-home-wrapper .media-placeholder-icon {
  margin-bottom: 40px;
}
.media-library-info-panel .media-home-wrapper .media-protip {
  color: #666;
  font-size: 12px;
  margin-top: 20px;
}
.media-library-info-panel .asset-info-area .asset-title {
  border-bottom: 1px solid #AAA;
  box-sizing: border-box;
  color: #222;
  margin: 0 auto;
  padding: 15px;
}
.media-library-info-panel .asset-info-area .asset-title .asset-title-text {
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.media-library-info-panel .asset-info-area .asset-title .input-container {
  margin-bottom: 0;
}
.media-library-info-panel .asset-info-area .asset-title .input-container > button {
  margin-right: 3px;
  margin-top: 2px;
  margin-bottom: 5px;
}
.media-library-info-panel .asset-info-area .asset-title .form-elements-container input[type=text] {
  border-radius: 1px;
  box-sizing: border-box;
  padding: 3px;
  padding: 5px;
  width: 100%;
  font-size: 14px;
  height: 28px;
}
.media-library-info-panel .asset-info-area .asset-title .form-elements-container > button {
  margin-left: 2px;
}
.media-library-info-panel .asset-info-area .asset-title .action-rename-item {
  color: #888;
  display: inline-block;
  font-size: 14px;
  margin-left: 12px;
}
.media-library-info-panel .asset-info-area .asset-title .action-rename-item:hover {
  color: #00A9D5;
}
.media-library-info-panel .asset-info-area .asset-details {
  padding: 20px 15px 15px 15px;
}
.media-library-info-panel .asset-info-area .default-title {
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  margin-right: 6px;
  margin-top: 12px;
}
.media-library-info-panel .asset-info-area .default-title input {
  margin-top: 3px;
  margin-right: 5px;
  vertical-align: top;
  -webkit-transform: none;
}
.media-library-info-panel .info-action-button {
  cursor: pointer;
  display: inline-block;
}
.media-library-info-panel .asset-count-wrapper {
  color: #555;
  font-size: 13px;
  font-weight: bold;
  padding: 35px 0 15px 0;
  text-align: center;
}
.media-library-info-panel .asset-count-wrapper .asset-count {
  background: #666;
  border-radius: 40px;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
  box-sizing: border-box;
  color: #DDD;
  display: block;
  font-size: 24px;
  font-weight: 300;
  height: 80px;
  margin: 0 auto 12px auto;
  padding: 29px 0 0 0;
  text-align: center;
  width: 80px;
}
.media-library-info-panel .move-asset,
.media-library-info-panel .delete-asset {
  background-color: #F9F9F9;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 18px;
  margin-right: 2px;
  padding: 6px 12px;
  text-align: center;
  width: 39%;
}
.media-library-info-panel .move-asset .fa,
.media-library-info-panel .delete-asset .fa {
  color: #888;
  font-size: 14px;
  margin-right: 6px;
}
.media-library-info-panel .move-asset:hover,
.media-library-info-panel .delete-asset:hover {
  color: #00A9D5;
}
.media-library-info-panel .move-asset:hover .fa,
.media-library-info-panel .delete-asset:hover .fa {
  color: #00A9D5;
}
.media-library-info-panel .move-asset:active,
.media-library-info-panel .delete-asset:active {
  background-color: #FDFDFD;
}
.media-library-info-panel .delete-asset:hover {
  color: red;
}
.media-library-info-panel .delete-asset:hover .fa {
  color: red;
}
.media-library-info-panel .asset-default-title,
.media-library-info-panel .asset-default-description,
.media-library-info-panel .asset-default-alt-text {
  font-size: 12px;
  line-height: 18px;
  padding-bottom: 3px;
}
.media-library-info-panel .asset-default-title .input-container,
.media-library-info-panel .asset-default-description .input-container,
.media-library-info-panel .asset-default-alt-text .input-container {
  margin-bottom: 0px;
}
.media-library-info-panel .asset-default-title .asset-category-title,
.media-library-info-panel .asset-default-description .asset-category-title,
.media-library-info-panel .asset-default-alt-text .asset-category-title {
  font-weight: bold;
  margin-right: 10px;
}
.media-library-info-panel .asset-default-title .action-edit-title,
.media-library-info-panel .asset-default-description .action-edit-title,
.media-library-info-panel .asset-default-alt-text .action-edit-title,
.media-library-info-panel .asset-default-title .edit-description,
.media-library-info-panel .asset-default-description .edit-description,
.media-library-info-panel .asset-default-alt-text .edit-description {
  color: #888;
  display: inline-block;
  font-size: 14px;
  margin-left: 12px;
}
.media-library-info-panel .asset-default-title .action-edit-title:hover,
.media-library-info-panel .asset-default-description .action-edit-title:hover,
.media-library-info-panel .asset-default-alt-text .action-edit-title:hover,
.media-library-info-panel .asset-default-title .edit-description:hover,
.media-library-info-panel .asset-default-description .edit-description:hover,
.media-library-info-panel .asset-default-alt-text .edit-description:hover {
  color: #00A9D5;
}
.media-library-info-panel .asset-default-description {
  padding-bottom: 5px;
}
.media-library-info-panel .asset-default-description-text-area > div,
.media-library-info-panel .asset-default-alt-text-area > div {
  background-color: #F6F6F6;
  padding: 5px;
  border-radius: 2px;
  border: 1px solid #DDD;
  margin-top: 3px;
  margin-bottom: 3px;
}
.media-library-info-panel .add-asset-description {
  border: 1px solid #AAA;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  color: #666;
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 5px;
  padding-top: 7px;
  padding-bottom: 8px;
  text-align: center;
  width: 100%;
}
.media-library-info-panel .add-asset-description:hover {
  border: 1px solid #888;
  color: #444;
}
.media-library-info-panel .asset-date-added,
.media-library-info-panel .asset-date-modified,
.media-library-info-panel .asset-uploaded-by,
.media-library-info-panel .asset-from-prompt,
.media-library-info-panel .item-path {
  font-size: 12px;
  padding-bottom: 2px;
  text-align: right;
}
.media-library-info-panel .asset-date-added .asset-category-title,
.media-library-info-panel .asset-date-modified .asset-category-title,
.media-library-info-panel .asset-uploaded-by .asset-category-title,
.media-library-info-panel .asset-from-prompt .asset-category-title,
.media-library-info-panel .item-path .asset-category-title {
  font-weight: bold;
  float: left;
}
.media-library-info-panel .asset-date-added .prompt-content,
.media-library-info-panel .asset-date-modified .prompt-content,
.media-library-info-panel .asset-uploaded-by .prompt-content,
.media-library-info-panel .asset-from-prompt .prompt-content,
.media-library-info-panel .item-path .prompt-content {
  margin-left: 95px;
  text-align: left;
}
.media-library-info-panel .asset-date-added .uploaded-by-content,
.media-library-info-panel .asset-date-modified .uploaded-by-content,
.media-library-info-panel .asset-uploaded-by .uploaded-by-content,
.media-library-info-panel .asset-from-prompt .uploaded-by-content,
.media-library-info-panel .item-path .uploaded-by-content {
  margin-left: 85px;
}
.media-library-info-panel .item-path {
  padding-bottom: 15px;
}
.media-library-info-panel .item-path .fa {
  color: #999;
  float: left;
  font-size: 14px;
  margin-left: 2px;
  margin-right: 6px;
  padding-top: 2px;
}
.media-library-info-panel .item-path .path-fragment {
  cursor: pointer;
}
.media-library-info-panel .item-path .path-fragment:hover {
  text-decoration: underline;
}
.media-library-info-panel .cc-wrapper {
  font-size: 11px;
  margin-top: 10px;
}
.media-library-info-panel .cc-wrapper .fa {
  font-size: 13px;
  margin-right: 3px;
}
.media-library-info-panel .cc-wrapper.cc-added {
  color: #0572C5;
}
.media-library-info-panel .cc-wrapper .cc-buttons {
  background: #FFF;
  border-radius: 2px;
  border-bottom: none;
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-size: 10px;
  margin-left: 5px;
  margin-top: -1px;
  margin-right: -4px;
  padding: 1px 10px;
  vertical-align: top;
}
.media-library-info-panel .cc-wrapper .cc-buttons:hover {
  color: #333;
}
.media-library-info-panel .cc-wrapper .cc-buttons .cc-input {
  height: 25px;
  width: 100%;
}
.media-library-info-panel .update-pdf,
.media-library-info-panel .update-ppt,
.media-library-info-panel .update-other,
.media-library-info-panel .clip-video,
.media-library-info-panel .download-link,
.media-library-info-panel .choose-thumbnail,
.media-library-info-panel .video-subtitles {
  background: none;
  border-bottom: none;
  color: #666;
  cursor: pointer;
  display: inline-block;
  font-size: 11px;
  margin-top: 20px;
  margin-right: 18px;
  padding-left: 0;
}
.media-library-info-panel .update-pdf .fa,
.media-library-info-panel .update-ppt .fa,
.media-library-info-panel .update-other .fa,
.media-library-info-panel .clip-video .fa,
.media-library-info-panel .download-link .fa,
.media-library-info-panel .choose-thumbnail .fa,
.media-library-info-panel .video-subtitles .fa {
  font-size: 13px;
  margin-right: 3px;
}
.media-library-info-panel .update-pdf input,
.media-library-info-panel .update-ppt input,
.media-library-info-panel .update-other input,
.media-library-info-panel .clip-video input,
.media-library-info-panel .download-link input,
.media-library-info-panel .choose-thumbnail input,
.media-library-info-panel .video-subtitles input {
  cursor: pointer;
  width: 100%;
}
.media-library-info-panel .update-pdf:hover,
.media-library-info-panel .update-ppt:hover,
.media-library-info-panel .update-other:hover,
.media-library-info-panel .clip-video:hover,
.media-library-info-panel .download-link:hover,
.media-library-info-panel .choose-thumbnail:hover,
.media-library-info-panel .video-subtitles:hover {
  background: none;
  color: #0572C5;
  text-decoration: none;
}
.media-library-info-panel .update-pdf,
.media-library-info-panel .update-ppt,
.media-library-info-panel .update-other {
  display: block;
  margin: 0 auto;
  margin-top: 12px;
  text-align: center;
  width: 160px;
  float: right;
}
.media-library-info-panel .update-pdf .fa,
.media-library-info-panel .update-ppt .fa,
.media-library-info-panel .update-other .fa {
  font-size: 14px;
  margin-right: 6px;
}
.media-library-info-panel .download-link {
  margin-right: 0;
  margin-top: 10px;
  display: block;
}
.media-library-info-panel .video-subtitles {
  margin-top: 0;
  margin-right: 0;
  padding-right: 5px;
  padding-top: 0;
}
